<template>
  <div
    v-expose="{
      id: '1-6-4-85',
      data: {
        ...extAnalysisInfo,
        ...exposeConfig,
      }
    }"
    class="category-rec"
  >
    <div class="category-rec__wrap">
      <div 
        class="category-rec__title" 
        @click="toList()"
      >
        <div class="category-rec__title-txt">
          {{ language.SHEIN_KEY_PWA_30121 }}
        </div>
        <Icon
          name="sui_icon_more_right_16px_2"
          color="#999"
          size="16px"
          :is-rotate="GB_cssRight"
        />
      </div>
      <div class="category-rec__list">
        <ul>
          <li 
            v-for="(item, index) in categoryRecommendList" 
            :key="item.cat_id"
            @click="toList(item, index)"
          >
            <div class="category-rec__list-imgbox">
              <img
                :data-src="transformImg({ img: item.main_image?.medium_image })"
                :src="LAZY_IMG"
                data-design-width="30"
                class="lazy lazyload"
                :data-image-url="item.main_image?.medium_image"
              />
            </div>
            <div class="category-rec__list-name">
              {{ item.cat_name }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import localMixins from '../mixins/index'
import { mapActions, mapState, mapGetters } from 'vuex'
const { LAZY_IMG, GB_cssRight } = gbCommonInfo
export default {
  name: 'CategoryRecommend',
  components: { Icon },
  mixins: [localMixins],
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => {}
    },
    // 跳转商品列表时带上adp参数
    setAdpParams: {
      type: Boolean,
    },
  },
  data() {
    return {
      LAZY_IMG,
      GB_cssRight,
    }
  },
  computed: {
    ...mapGetters('newProductDetail/ShippingEnter', ['storeInfo']),
    ...mapState('newProductDetail/Signboard', ['storeCategoryRecommend']),
    ...mapGetters('newProductDetail/common', [ 
      'detail',
      'localStoreInfo', 
      'storeRecommendInfo',
    ]),
    categoryRecommendList() {
      return this.storeCategoryRecommend.slice(0, 10)
    },
    // 额外埋点信息
    extAnalysisInfo() {
      const { store_code } = this.detail
      const { storeBusinessType } = this.storeInfo 
      const store_tp = storeBusinessType == 2 ? 'brand' : 'other'
      
      return {
        store_tp,
        store_code,
        src_module: 'DetailStoreCate',
      }
    },
    exposeConfig() {
      const { id, name } = this.data
      const src_identifier = [
        `on=store`,
        `cn=${name}`,
        `hz=0`,
        `ps=0`,
        `jc=${`thirdPartyStoreHome_${id}`
        }`
      ].join('`')
      return { src_identifier }
    },
    recommendAdp() {
      return this.storeRecommendInfo?.recommendAdp || ''
    },
  },

  mounted() {
    // this.getStoreCategoriesSale()
  },
  methods: {
    ...mapActions('newProductDetail/Signboard', ['getStoreCategoriesSale']),
    toList({ cur_cat_id = '', goods_id = '' } = {}, index) {
      const { type, id, link, name } = this.data
      const { shopRank, analysis, extAnalysisInfo, detail } = this

      if (!link) return
      let src_identifier = ''
      if(cur_cat_id) {
        src_identifier = [
        `on=store`,
        `cn=${name}`,
        `hz=0`,
        `ps=${index + 1}`,
        `jc=${`thirdPartyStoreHome_${id}`
        }`].join('`')
        daEventCenter.triggerNotice({
          daId: '1-6-4-87',
          extraData: {
            ...extAnalysisInfo,
            category_id: cur_cat_id,
            src_identifier,
          }
        })
      } else {
        src_identifier = [
        `on=store`,
        `cn=${name}`,
        `hz=0`,
        `ps=0`,
        `jc=${`thirdPartyStoreHome_${id}`
        }`].join('`')
        daEventCenter.triggerNotice({
          daId: '1-6-4-86',
          extraData: {
            ...extAnalysisInfo,
            src_identifier,
          }
        })
      }

      this.boardMoreHandle({
        tabId: 'items',
        type,
        id,
        link,
        shopRank,
        goods_id: detail.goods_id,
        cat_id: detail.cat_id,
        adp: goods_id,
        analysis: { src_identifier, src_module: analysis?.src_module },
        cur_cat_id,
        isStoreTabSticky: 1,
      })
    },
  }
}
</script>

<style lang="less">
.category-rec {
  padding: 0 0.32rem 0.32rem;
  &__wrap {
    padding: 0.16rem 0.21rem 0.21rem;
    border-radius: 0.16rem;
    background: #f6f6f6;
  }
  &__title {
    display: flex;
    justify-content: space-between;
  }
  &__title-txt {
    color: var(---sui_color_gray_dark1, #222);
    font-family: 'SF Pro';
    font-size: 0.29333rem;
    font-weight: 510;
    line-height: 0.42667rem; /* 145.455% */
  }
  &__list {
    margin-top: 0.21rem;
    overflow-x: scroll;
    /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
    -ms-overflow-style: none;
    /*火狐下隐藏滚动条*/
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    /*Chrome下隐藏滚动条，溢出可以透明滚动*/
    &::-webkit-scrollbar {
      width: 0;
      display: none; /* Chrome Safari */
    }
    ul {
      display: flex;
    }
    li {
      padding-right: 0.21rem;
      margin-right: 0.32rem;
      display: flex;
      align-items: center;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 0.01333rem;
        height: 0.53333rem;
        background: #ccc;
      }
      &:last-child::after {
        display: none;
      }
    }
  }
  &__list-imgbox {
    border-radius: 0.05333rem;
    border: 0.5px solid #ddd;
    width: 0.8rem;
    height: 0.8rem;
    overflow: hidden;
    flex-shrink: 0;
    img {
      width: 100%;
    }
  }
  &__list-name {
    max-width: 1.33333rem;
    color: var(---sui_color_gray_dark2, #666);
    font-family: 'system-ui';
    font-size: 0.29333rem;
    font-weight: 400;
    line-height: normal;
    padding-left: 0.16rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
