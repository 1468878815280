var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"itemContent",staticClass:"review-item__inout-content review-inner-box",style:({
    'max-height': _vm.maxHeight ? `${_vm.maxHeight}px` : 'none',
    'min-height': _vm.minHeight,
    opacity: _vm.inHeightCalcState ? '0' : '',
  }),attrs:{"tabindex":"1","role":"text","aria-label":_vm.commentContent.join(' ')}},[_c('p',{ref:"itemContentSpan",class:{
      'clamp4': _vm.isSlideStyle,
      'no-reverse': !['ar', 'he'].includes(_vm.comment.language_flag) 
    },attrs:{"aria-hidden":"true"}},_vm._l((_vm.commentContent),function(item,wdIndex){return _c('span',{key:wdIndex,ref:"itemWord",refInFor:true,attrs:{"type":"text"},domProps:{"innerHTML":_vm._s(item + ' ')}})}),0),_vm._v(" "),(_vm.commentTagsLength > 0)?_c('ul',{ref:"commentTagBox",staticClass:"comment-tag-box"},_vm._l((_vm.commentTags),function(tag,key){return _c('li',{key:tag.id + key,staticStyle:{"direction":"ltr"}},[_c('span',{staticClass:"tag-name"},[(_vm.subtitleShowSmallpic(key))?_c('i',{staticClass:"suiiconfont sui_icon_photos_16px_2"}):_vm._e(),_vm._v(_vm._s(tag.name))]),_vm._v(": "),_c('span',{staticClass:"tag-content"},[_vm._v(_vm._s(tag.content))])])}),0):_vm._e(),_vm._v(" "),(_vm.onlyVisibility && !_vm.isSlideStyle)?_c('div',{staticClass:"comment-more-btn"},[_c('span',{staticClass:"film"}),_vm._v(" "),_c('span',{attrs:{"type":"text"}},[_vm._v("...")]),_vm._v(" "),_c('span',{staticClass:"item-see-more",attrs:{"type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.showAll.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_18777))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }