<template>
  <div
    v-if="firstLabelItem"
    :class="{
      'buybox-itemlabel': true,
      'green-type': firstLabelItem.type === 1,
      'new-buybox-itemlabel': isNewStyle,
      'new-buybox-qsitemlabel': isNewStyle && firstLabelItem.type === 1,
    }"
  >
    <div
      :class="{
        'new-style-iconbox': isNewStyle,
        'new-style-iconboxqs': isNewStyle && firstLabelItem.type === 1
      }"
    >
      <Icon
        v-if="isNewStyle && firstLabelItem.type === 1 "
        name="sui_icon_quickship_flat_12px"
        size="12px"
        style="margin-right: 2px"
      />
      <div
        :class="{
          'label-text': true,
          'new-label-text': isNewStyle,
          'new-label-qstext': isNewStyle && firstLabelItem.type === 1
        }"
      >
        {{ firstLabelItem.text }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BuyBoxIndexLabel',
}
</script>
<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  buyBoxFloorQuickshipTitle: {
    type: String,
    default: ''
  },
  isNewStyle: {
    type: Boolean,
    default: false
  }
})
const firstLabelItem = computed(() => {
  const res = props.item?._formatInfo?.labelList?.[0]
  if (res?.type === 1) {
    return {
      text: props.buyBoxFloorQuickshipTitle || res?.text,
      type: 1
    }
  }
  return res
})

</script>
<style lang="less" scoped>
.buybox-itemlabel {
  border-radius: 0.05333rem;
  background: #FFF8EB;
  color: #CC6E00;
  display: inline-block;
  padding: 0 3px;
  max-width: 96px;
  font-size: 10px;
  font-weight: 400;
  .text-overflow();

  &.green-type {
    background: #ECFCF3;
    color: #198055;
  }
  &.new-buybox-itemlabel {
    border: 0.5px solid rgba(204, 110, 0, 0.20);
  }
  &.new-buybox-qsitemlabel {
    border: 0.5px solid rgba(25, 128, 85, 0.20);
  }
  .new-style-iconbox {
    display: flex; 
    padding: 1px 0;
    align-items: center;
  }
  .new-style-iconboxqs {
    display: flex; 
    padding: 1px 0;
    align-items: center;
  }
  .label-text {
    font-size: 10px;
    line-height: 10px;
    font-weight: 400;
    .text-overflow();
  }
  .new-label-text {
    color: #CC6E00;
    font-weight: 400;
    font-style: normal;
  }
  .new-label-qstext {
    color: #198055;
    font-style: italic;
    font-weight: 590;
  }
}
</style>
