<template>
  <div :class="[position === 'inner' ? 'reviews-item-user' : '']">
    <div class="review-item__info">
      <div class="review-item__info-item">
        <h4 aria-hidden="true">
          {{ userName }}
        </h4>
        <div
          v-ada="{ level: 1, pos: 0 }"
          :aria-label="`${language.SHEIN_KEY_PWA_15332}${commentRank}`"
          class="star-html"
          v-html="GB_SHEIN_counting_star_suiicon(commentRank, 15, 2)"
        ></div>

        <!-- free tips -->
        <span
          v-if="comment.reportId"
          class="report"
          :class="{ 'clamp1': isSlideStyle }"
          aria-hidden="true"
        >
          {{ language.SHEIN_KEY_PWA_15207 }}
        </span>
      </div>
      <div
        class="review-item__info-time"
        :aria-label="comment.comment_time"
        role="text"
      >
        {{ formatTime(comment) }}
      </div>
    </div>

    <div class="review-item__line-box">
      <!-- goods attr -->
      <div class="review-item__attr">
        <template v-if="comment.goods_attr">
          <span
            :class="`comment-size${showGoodsId ? '-wrap' : ''}`"
            tabindex="1"
            role="text"
            :aria-label="comment.goods_attr"
          >
            {{ comment.goods_attr }}
          </span>
        </template>
        <template v-else>
          <span
            :class="`comment-size${showGoodsId ? '-wrap' : ''}`"
            tabindex="1"
            role="text"
            :aria-label="calOldGoodsAttr(comment, this)"
          >
            {{ calOldGoodsAttr(comment, this) }}
          </span>
        </template>
        <div
          v-if="showGoodsId"
          class="comment-goodsId"
          :class="{ 'check-skc-hook': comment.is_check_skc }"
          @click="clickGoodsId(comment)"
        >
          {{ language.SHEIN_KEY_PWA_22810 }}
          <i class="suiiconfont sui_icon_more_right2_16px"></i>
        </div>
      </div>

      <!-- local tag -->
      <div
        v-if="localTagShow"
        v-expose="{ id: position === 'outer' ? '1-6-7-58' : '1-6-2-58' }"
        class="review-item__local-tag"
      >
        <i class="suiiconfont sui_icon_location_15px location_12px"></i>
        <span>{{ language.SHEIN_KEY_PWA_22717 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { calOldGoodsAttr } from 'public/src/pages/goods_detail/utils/common'
import { expose } from 'public/src/pages/common/analysis/directive.js'
const { SiteUID } = gbCommonInfo
export default {
  name: 'CommentBase',
  directives: { expose },
  inject: ['COMMENT_INSTANCE'],
  props: {
    // 单条评论是否需要展示本地标记
    markSingleLocalComment: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSlideStyle() {
      return this.COMMENT_INSTANCE.isSlideStyle
    },
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    language() {
      return this.COMMENT_INSTANCE.language
    },
    position() {
      return this.COMMENT_INSTANCE.position
    },
    showGoodsId() {
      return this.COMMENT_INSTANCE.showGoodsId
    },
    index() {
      return this.COMMENT_INSTANCE.index
    },
    commentDateDisplayNew() {
      return this.COMMENT_INSTANCE.commentDateDisplayNew
    },
    commentRank() {
      const { comment = {} } = this
      const { comment_rank = 0 } = comment
      return Math.min(comment_rank, 5)
    },
    localTagShow() {
      const { markSingleLocalComment, comment } = this
      if (!markSingleLocalComment) {
        return false
      }
      return comment.local_flag === 1
    },
    /**
     * 用户名加密
     * @param {*} txt
     * @returns
     */
    userName() {
      const { comment } = this
      const { user_name, username, isOutStoreReview } = comment
      if (isOutStoreReview) {
        return username
      }
      let txt = user_name || username || ''
      if (txt.indexOf('*') === 1 && txt.lastIndexOf('*') === txt.length - 2) {
        txt = txt.split('*')
        txt = `${txt[0]}***${txt[txt.length - 1]}`
      }
      return txt
    }
  },
  methods: {
    /**
     * JP time 格式处理
     * @param {*} timeStr
     * @returns
     */
    formatTime(comment) {
      if(this.commentDateDisplayNew && comment.commentTimeNewStyle) return comment.commentTimeNewStyle
      const timeStr = comment.comment_time
      if (SiteUID.includes('jp')) {
        return timeStr.replace(/-/g, '/')
      }
      return timeStr?.split(' ')?.[0]
    },
    /**
     * click callback
     */
    clickGoodsId({ goods_id, jump_goods_id, is_check_skc }) {
      const { COMMENT_INSTANCE, index } = this
      COMMENT_INSTANCE.EVENT_CENTER('clickGoodsId', {
        goods_id: jump_goods_id || goods_id,
        index,
        is_check_skc
      })
    },
    calOldGoodsAttr,
    GB_SHEIN_counting_star_suiicon: typeof window !== 'undefined' ? window.GB_SHEIN_counting_star_suiicon : () => {}
  }
}
</script>
<style lang="less">
.review-item__info-item {
  .star-html {
    transform: perspective(400px);
  }
  .clamp1 {
    max-width: 2.213rem;
    text-overflow: ellipsis;
    overflow: hidden;
     /* stylelint-disable-next-line */
    display: block !important;
    white-space: nowrap;
  }
}
</style>
