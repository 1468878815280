var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.firstLabelItem)?_c('div',{class:{
    'buybox-itemlabel': true,
    'green-type': _setup.firstLabelItem.type === 1,
    'new-buybox-itemlabel': _vm.isNewStyle,
    'new-buybox-qsitemlabel': _vm.isNewStyle && _setup.firstLabelItem.type === 1,
  }},[_c('div',{class:{
      'new-style-iconbox': _vm.isNewStyle,
      'new-style-iconboxqs': _vm.isNewStyle && _setup.firstLabelItem.type === 1
    }},[(_vm.isNewStyle && _setup.firstLabelItem.type === 1 )?_c('Icon',{staticStyle:{"margin-right":"2px"},attrs:{"name":"sui_icon_quickship_flat_12px","size":"12px"}}):_vm._e(),_vm._v(" "),_c('div',{class:{
        'label-text': true,
        'new-label-text': _vm.isNewStyle,
        'new-label-qstext': _vm.isNewStyle && _setup.firstLabelItem.type === 1
      }},[_vm._v("\n      "+_vm._s(_setup.firstLabelItem.text)+"\n    ")])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }