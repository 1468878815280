<template>
  <SwiperSlide
    class="store__atmosphere"
    :item-length="atmosphereData.length"
    observer-length
    :autoplay="atmosphereData.length > 1 ? 2000 : 0"
    :transition-duration="1000"
    loop
    vertical
    :touchable="false"
    @activeIndexChange="activeIndexChange"
  >
    <SwiperSlideItem
      v-for="(item, index) in atmosphereData"
      :key="index"
      :index="index"
      class="store__atmosphere-item"
      :class="{
        'store__atmosphere-itemActive': index === activeIndex,
        'store__atmosphere-scroll': position === 'showscroll'
      }"
    >
      <template v-if="item.isStoreFollow">
        <slot name="followInfo"></slot>
      </template>
      <template v-else>
        <img
          src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/0e/172258728695cce69897541b23a1ed7cc0d69f8fdb.png"
          class="store__atmosphere-dot"
        />
        <img
          src="https://img.ltwebstatic.com/images3_ccc/2024/08/02/35/172258730293a70b12875b95870901d1fe46e62169.png"
          class="store__atmosphere-profile"
        />
        <span 
          class="store__atmosphere-text" 
          v-html="handleItemText(item)"
        ></span>
      </template>
    </SwiperSlideItem>
  </SwiperSlide>
</template>
  
<script>
/**
   * @component 店铺人气氛围信息滚动展示框
   */
import { mapGetters } from 'vuex'
import SwiperSlide  from 'public/src/pages/components/swiperSlide/SwiperSlide.vue'
import SwiperSlideItem from 'public/src/pages/components/swiperSlide/SwiperSlideItem.vue'
import { template } from '@shein/common-function'


export default {
  name: 'StoreAtmosphereSwiper',
  components: {
    SwiperSlide,
    SwiperSlideItem,
  },
  props: {
    hasFollowInfo: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      activeIndex: 0,
    }
  },
  computed: {
    ...mapGetters('productDetail/DetailSignBoard', [
      'store_atmosphere_info',
    ]),
    atmosphereData() {
      let data = this.store_atmosphere_info
      if(this.hasFollowInfo){
        let result = []
        let n = data[0]?.popTypeLength || 0
        for (let i = 0; i < data.length; i++) {
          // 每隔n项插入店铺评分信息
          if (i % n === 0) {
            result.push({ isStoreFollow: true })
          }
          result.push(data[i])
        }
        return result
      }
      return data
    },
    handleItemText() {
      return (item) => {
        const p1 = item?.nameTip ? `<span>${item?.nameTip}</span>` : ''
        const p2 = item?.timeTip ? `<span>${item?.timeTip}</span>` : ''
        let text = template(p1, p2, item.multiLanguage)
        return text
      }
    },
  },
  methods: {
    activeIndexChange(index) {
      this.activeIndex = index
    },
  },
}
</script>
  
<style lang="less">
.store {
  &__atmosphere {
    height: .37333rem;
    overflow: hidden;
    position: relative;
    font-size: .293333rem;
  }

  &__atmosphere-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 1s linear 0s;
    &.store__atmosphere-scroll{
      justify-content: flex-start;
    }
  }

  &__atmosphere-itemActive {
    opacity: 1;
  }

  &__atmosphere-dot{
    width: 0.21333rem;
    height: 0.21333rem;
    flex-shrink: 0;
  }
  &__atmosphere-profile{
    width: 0.37333rem;
    height: 0.37333rem;
    flex-shrink: 0;
    margin: 0 0.11rem;

  }
  &__atmosphere-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 11px;
    font-style: normal;
    color: #000;
    & > span {
      color: #767676;
    }
  }

}
</style>
  
