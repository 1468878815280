<template>
  <div
    ref="itemContent"
    class="review-item__inout-content review-inner-box"
    tabindex="1"
    role="text"
    :aria-label="commentContent.join(' ')"
    :style="{
      'max-height': maxHeight ? `${maxHeight}px` : 'none',
      'min-height': minHeight,
      opacity: inHeightCalcState ? '0' : '',
    }"
  >
    <p
      ref="itemContentSpan"
      :class="{
        'clamp4': isSlideStyle,
        'no-reverse': !['ar', 'he'].includes(comment.language_flag) 
      }"
      aria-hidden="true"
    >
      <span
        v-for="(item, wdIndex) in commentContent"
        ref="itemWord"
        :key="wdIndex"
        type="text"
        v-html="item + ' '"
      ></span>
    </p>

    <!-- 评论引导标签 -->
    <ul
      v-if="commentTagsLength > 0"
      ref="commentTagBox"
      class="comment-tag-box"
    >
      <li
        v-for="(tag, key) in commentTags"
        :key="tag.id + key"
        style="direction: ltr"
      >
        <span
          class="tag-name"
        >
          <i 
            v-if="subtitleShowSmallpic(key)" 
            class="suiiconfont sui_icon_photos_16px_2"
          ></i>{{ tag.name }}</span>: <span
          class="tag-content"
        >{{ tag.content }}</span>
      </li>
    </ul>

    <!-- ...More -->
    <div
      v-if="onlyVisibility && !isSlideStyle"
      class="comment-more-btn"
    >
      <span class="film"></span>
      <span type="text">...</span>
      <span
        type="text"
        class="item-see-more"
        @click.stop="showAll"
      >{{ language.SHEIN_KEY_PWA_18777 }}</span>
    </div>
  </div>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive.js'

const { lang } = gbCommonInfo
const calcId = 'calc...'

export default {
  name: 'CommentContent',
  inject: ['COMMENT_INSTANCE', 'PAGE_FROM', 'RELATIVE_SPU'],
  directives: { expose },
  props: {
    position: {
      type: String,
      default: 'outer'
    },
    reviewPhotoType: {
      type: String,
      default: 'None'
    },
    reviewText: {
      type: String,
      default: 'None'
    }
  },
  data() {
    return {
      maxHeight: 0,
      onlyVisibility: false,
      beenShowMore: false,
      charReverse: false,
      inHeightCalcState: false,
    }
  },
  computed: {
    minHeight() {
      if(this.isSlideStyle) {
        return this.maxHeight ? `${this.maxHeight}px` : '1.75rem'
      }
      return 'auto'
    },
    // 折叠评论行数
    commentLimitLines() {
      const { position } = this
      if (position === 'outer') {
        if (['SimpleVersion1', 'SimpleVersion2'].includes(this.reviewText)) {
          return 2
        }
        return 4
      }
      return 10
    },
    commentImages() {
      // 是否展示水印图片
      const { showCommentWatermark = false } =
        this.COMMENT_INSTANCE?.watermarkConfig || {}
      return (
        this.comment?.comment_image?.filter(_ => {
          if (
            showCommentWatermark &&
            _?.member_image_middle &&
            _.member_image_middle?.indexOf('_wk_shein') === -1 &&
            _.member_image_middle?.indexOf('images3') > -1
          ) {
            const suffix = _?.member_image_middle?.split('.')?.pop() || ''
            _.member_image_middle = suffix
              ? _?.member_image_middle.replace(
                  `.${suffix}`,
                  `_wk_shein.${suffix}`
              )
              : _?.member_image_middle
          }
          return _
        }) || []
      )
    },
    isSlideStyle() {
      return this.COMMENT_INSTANCE.isSlideStyle
    },
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    language() {
      return this.COMMENT_INSTANCE.language
    },
    hideMoreText() {
      return this.COMMENT_INSTANCE.hideMoreText
    },
    commentContent() {
      if (this.inHeightCalcState) {
        return [calcId]
      }

      const res = !this.comment?.showOrigin && this.translateText
        ? this.translateText.split(' ') 
        : this.comment.content.split(' ')
      if(res[0] !== '' && this.showSmallpic) {
        res[0] = '<i class="suiiconfont sui_icon_photos_16px_2"></i>' + res[0]
      }

      return res 
    },
    // 评论引导标签
    commentTags() {
      const { inHeightCalcState, comment, } = this
      if (inHeightCalcState) {
        return [{ name: calcId, content: calcId, id: calcId }]
      }

      if (!comment) {
        return []
      }
      const { content_tag } = comment
      if (!Array.isArray(content_tag)) {
        return []
      }
      return content_tag.filter(tag => tag && tag.name && tag.content)
    },
    commentTagsLength() {
      const { commentTags } = this
      return commentTags.length || 0
    },
    translateText() {
      return this.comment.translateText || ''
    },
    showSmallpic(){
      return this.position === 'outer' && this.reviewPhotoType === 'Smallicon' && this.commentImages.length > 0
    }
  },
  watch: {
    comment: {
      handler() {
        const { beenShowMore } = this
        // 曾点击过展开按钮，则不再折叠
        if (beenShowMore) {
          return
        }
        this.maxHeight = 0
        this.onlyVisibility = false
        this.inHeightCalcState = true
        this.$nextTick(() => {
          this.frame = this.frame + 1
          this.getEleDisplayHeight()
        })
      },
      immediate: true
    },
  },
  mounted() {
    this.inHeightCalcState = true
    this.$nextTick(() => {
      this.getEleDisplayHeight()
    })
    this.charReverse = ['ar', 'he'].includes(lang)
  },
  methods: {
    showAll() {
      const {
        COMMENT_INSTANCE,
        onlyVisibility,
        maxHeight,
      } = this
      COMMENT_INSTANCE.EVENT_CENTER(
        'contentShowAll',
        {
          status: maxHeight && onlyVisibility
        }
      )
      this.onlyVisibility = false
      this.maxHeight = 0
      this.beenShowMore = true
    },
    subtitleShowSmallpic(key){
      return key === 0 && this.showSmallpic  && !this.commentContent[0]?.includes('sui_icon_photos_16px_2')
    },
    // 获取元素行高
    getDomLineHeight(el) {
      if (!el || (typeof window === 'undefined')) {
        return 0
      }

      try {
        const eleHeight = window.getComputedStyle(el).height
        const numEleHeight = Number(eleHeight.replace(/(px)?$/, ''))
        if (!isNaN(numEleHeight) && (numEleHeight > 0)) {
          return numEleHeight
        }

        return el.offsetHeight
      } catch (err) {
        //
      }

      return 0
    },
    // 通过展示单行文本，获取正文和标签的真实行高
    getEleDisplayHeight() {
      const { $refs = {}, getDomLineHeight } = this
      const { itemContentSpan, commentTagBox } = $refs
      // 计算当前评论正文所占行数
      // 评论正文每行高 fontSize * lineHeight = 14 * 1.2
      const contentLineHeight = getDomLineHeight(itemContentSpan) || 14 * 1.2
      // 计算当前评论引导标签所占行数
      // 评论标签每行高 fontSize * lineHeight = 14 * 1.2
      const tagLineHeight = getDomLineHeight(commentTagBox) || 14 * 1.2

      this.inHeightCalcState = false
      this.$nextTick(() => {
        this.wordHeightCalc(contentLineHeight, tagLineHeight)
      })
    },
    // 文字超出四行显示see more的判断和计算
    wordHeightCalc(contentLineHeight, tagLineHeight) {
      const { $refs = {}, commentLimitLines } = this
      const {
        itemContentSpan,
        commentTagBox,
      } = $refs


      const contentHeight = itemContentSpan ? itemContentSpan.offsetHeight : 0
      let contentLines = contentHeight / contentLineHeight
      if (!isNaN(contentLines)) {
        contentLines = contentLines.toFixed()
      } else {
        contentLines = 0
      }
      // 仅评论正文内容已经超高
      if (contentLines > commentLimitLines) {
        this.maxHeight = contentLineHeight * commentLimitLines
        this.onlyVisibility = true
        return
      }

      const restLines = commentLimitLines - contentLines
      const tagsHeight = commentTagBox ? commentTagBox.offsetHeight : 0
      let tagLines = tagsHeight / tagLineHeight
      if (!isNaN(tagLines)) {
        tagLines = tagLines.toFixed()
      } else {
        tagLines = 0
      }
      // 引导标签超高
      const needHideTagLines = tagLines - restLines
      if (needHideTagLines > 0) {
        this.maxHeight = contentHeight + tagLineHeight * restLines
        this.onlyVisibility = true
        return
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .review-inner-box {
    overflow-y: hidden;
    position: relative;
    .comment-more-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: #fff;

      span {
        display: inline-block;
      }
    }
    /deep/ .sui_icon_photos_16px_2{
      margin-right: 2px;
      font-size: 15px;
    }
    .clamp4 {
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }

  .comment-tag-box {
    line-height: normal;
    .tag-name {
      color: @sui_color_gray_dark2;
    }
  }

  .comment-more-btn .film {
    display: inline-block;
    position: absolute;
    right: 100%;
    width: 100px;
    height: 100%;
    background: linear-gradient(270deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
</style>
