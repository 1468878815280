<script lang="jsx">
import { mapGetters, mapState, mapMutations } from 'vuex'
import MultipleImageRowCard from 'public/src/pages/components/product/item_v3/MultipleImageRowCard'
import ProductCardPriceSimple from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/ProductCardPriceSimple.vue'
import useItemMixin from 'public/src/pages/components/product/item_v2/js/mixin.js'
import {
  getFormatComboBuyProductList,
  setComboBuyOutSideProductListSort,
  setComboBuyProductListAutoSelect,
  baseExposeData,
  setComboBuyProductListAutoSkuInfo,
  getComboBuyPopupProductListByGoods,
  setComboBuyProductListSoldStatus,
  setProductListSpuSoldOut,
  handlePriceHtml,
} from './utils'
import {
  getMainGoodsPrice,
  setCommonCartProductSkuInfo,
  handleExposeAddCart,
  checkCanAddToCart,
  getFormatCommonCartProductItem,
  setSyncItemCartInfo,
  getFormatBatchAddErrorResult,
  batchAddToCart,
  autoGetCouponAtBatchAdd,
  isAmountEmpty,
  setAllProductSelectEmpty,
  getNotEstimatedProductCardConfig,
} from '../CommonCart/utils'
import { getCombobuyMixins } from './mixins'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getImageRatio } from 'public/src/pages/goods_detail/utils/common.js'
import CommonCartSize from '../CommonCart/CommonCartSize.vue'
import { Toast } from '@shein/sui-mobile'
import { debounce, throttle } from '@shein/common-function'
import { useItemConfig, EItemConfigType } from 'public/src/pages/goods_detail/RecommendRelatives/ProductConfigManager/index'

const { GB_cssRight } = gbCommonInfo

export default {
  name: 'ComboBuyIndex',
  components: {
    MultipleImageRowCard,
    ProductCardPriceSimple,
    ComboBuyPopup: () =>
      import(
        /* webpackChunkName: "ComboBuyPopup" */ 'public/src/pages/goods_detail/RecommendRelatives/ComboBuy/ComboBuyPopup.vue'
      )
  },
  mixins: [useItemMixin, getCombobuyMixins()],
  setup() {
    const { itemConfig: cItemConfig } = useItemConfig({
      useNewAbt: false,
      name: 'ComboBuy',
      type: EItemConfigType.Pic3,
      mergeConfig: {
        showAddBagBtn: false,
        showAddBagBtnBottomRight: false,
        disableMainimgJump: true, // 点击禁止跳转
        // showEstimateTag: false, // 无到手价
        // showEstimatedPriceReachThreshold: false, // 无到手价
        // showEstimatedPriceOnSale: false, // 无到手价
        // showEstimatedPrice: false, // 无到手价
        // showABPriceOnSale: true, // 显示AB测试价格
        // showABPriceTag: true, // 显示AB测试价格
        // noNeedAnyEstimatedPriceTag: true, // 不显示到手价tag
      }
    })

    const { itemConfig: cSimplePriceConfig } = useItemConfig({
      name: 'ComboBuySimplePrice',
      type: EItemConfigType.sSimplePrice,
      mergeConfig: {
        hidePriceBottomLabel: true,
      }
    })
    return {
      cItemConfig,
      cSimplePriceConfig,
    }
  },
  data() {
    return {
      show: false,
      isDrawerLoaded: false,
      showDrawer: false,
      products: [],
      bundlePrice: {},
      loadingPrice: false,
      loading: false
    }
  },
  computed: {
    ...mapState('newProductDetail/common', [
      'comboBuyRecommend',
      'skuInfo'
    ]),
    ...mapGetters('newProductDetail/common', [
      'language',
      'productItemsLanguage',
      'detail',
      'getEstimatedInfo',
      'price',
      'sheinClubPromotionInfo',
      'isShowNewComboBuyStyleAbt',
      'isShowNewComboBuyScreenStyleAbt',
      'isShowNewComboBuyOrangeScreenStyleAbt',
      'isHideNewComboBuyScreenStyleSizeAbt',
      'goodsId',
      'isHitComplianceMode'
    ]),
    ...mapGetters('newProductDetail/PromotionEnter', ['curCouponList']),
    ...mapGetters('newProductDetail/Price', ['salePrice', 'discountInfo']),
    ...mapGetters('newProductDetail', ['isPaidUser']), // 新数据流
    // 商详价格，只选中主商品时使用
    cMainGoodsPrice() {
      const {
        salePrice = {},
        discountInfo = {},
        getEstimatedInfo = {},
        language,
        price = {},
        isPaidUser,
        sheinClubPromotionInfo
      } = this

      return (
        getMainGoodsPrice({
          salePrice,
          discountInfo,
          getEstimatedInfo,
          language,
          price,
          isPaidUser,
          sheinClubPromotionInfo
        }) || {}
      )
    }
  },
  watch: {
    /**
     * goodsId变化时，关闭弹窗
     * 弹窗内点击关联尺码会更新商详页
     */
    goodsId() {
      this.refreshData()
    },
    comboBuyRecommend: {
      handler(list = []) {
        this.isShowNewComboBuyStyleAbt
          ? this.initDataNewStyle(list)
          : this.initData(list)
      },
      immediate: true,
      deep: true
    },
    'skuInfo.sku_code': throttle({
      func() {
        return this.syncMainSkuCodeChange()
      },
      wait: 1000
    })
  },
  methods: {
    ...mapMutations('newProductDetail/common', ['updateLoading']),
    /**
     * 初始化推荐数据 __main__
     */
    initData(list = []) {
      const productsFormat = getFormatComboBuyProductList(list)
      /**
       * 排序
       */
      setComboBuyOutSideProductListSort(productsFormat)

      /**
       * 自动勾选项目
       */
      const productsSelect = setComboBuyProductListAutoSelect(productsFormat)
      this.products = productsSelect
      this.show = !!this.products.length

      if (!this.show) return

      // 初始化埋点
      this.$nextTick(() => {
        const renderItems = this.products
          .map((d, i) => this.$refs[`item_${i}`])
          .filter(d => d)
        if (!Array.isArray(renderItems) || !renderItems.length) {
          return
        }
        // 因价格等商品信息动态获取，故在数据获取后更新item的dom上的数据
        renderItems.forEach(goodsItem => {
          goodsItem.setElAttr()
        })
      })
    },
    initDataNewStyle(list = []) {

      this.products = []

      const { skuInfo } = this
      const productsFormat = getFormatComboBuyProductList(list)
      const productsSelect = setComboBuyProductListAutoSelect(productsFormat)
      
      this.show = !!productsSelect.length

      if (!this.show) return

      let _hasSetMainSku = false
      // 主商品选择了sku同步到弹窗
      const mainSkuCode = skuInfo?.sku_code
      if (mainSkuCode && productsFormat?.[0]?._cartInfo?.isMainGoods) {
        _hasSetMainSku = !!setCommonCartProductSkuInfo(
          mainSkuCode,
          productsFormat[0]
        )
      }

      // 自动勾选无库存单sku
      setComboBuyProductListAutoSkuInfo(productsSelect, _hasSetMainSku)
      this.products = productsSelect

      setProductListSpuSoldOut({
        list: this.products,
      })

      // 初始化埋点
      this.$nextTick(() => {
        const renderItems = this.products
          .map((d, i) => this.$refs[`item_${i}`])
          .filter(d => d)
        if (!Array.isArray(renderItems) || !renderItems.length) {
          return
        }
        // 因价格等商品信息动态获取，故在数据获取后更新item的dom上的数据
        renderItems.forEach(goodsItem => {
          goodsItem.setElAttr()
        })
      })
    },
    /**
     * 切skc要初始化数据
     */
    refreshData() {
      this.products = []
      this.show = false
    },
    async handleShowDrawer(fromButton, result_reason) {
      const { products, cSelectItems, isShowNewComboBuyStyleAbt } = this
      this.showDrawer = true

      if (fromButton) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-179',
          extraData: {
            quantity: cSelectItems.length,
            button_status: 'popup',
            result_reason
          }
        })
      } else {
        daEventCenter.triggerNotice({
          daId: '1-6-1-177'
        })
      }

      const _next = async () => {
        try {
          if (isShowNewComboBuyStyleAbt) {
            /**
             * 新样式需要双向同步
             */
            const _syncPopupSelectNewStyle = async data => {
              const { products: popupProducts } = data
              const { products } = this
              popupProducts.forEach((d, i) => {
                setSyncItemCartInfo(products[i], d)
              })
            }

            const onSyncOusideItemAsyncFn = async ({ item, index } = {}) => {
              if (!item) return
              const currItem = products[index]

              let _uptItem = null
              const _isUptSkcOrSku = item.goods_id !== currItem.goods_id || item._cartInfo.skuCode !== currItem._cartInfo.skuCode
              if (_isUptSkcOrSku) {
                const goods = await getComboBuyPopupProductListByGoods({
                  goods: [item],
                  itemConfig: this.cItemConfig,
                  rows: 3
                })
                _uptItem = getFormatCommonCartProductItem(goods[0])
                // 同步状态

                setSyncItemCartInfo(_uptItem, item)
                this.products.splice(index, 1, _uptItem)
              } else {
                setSyncItemCartInfo(currItem, item)
              }

              this.$nextTick(() => {
                // 切换更新埋点信息
                this.$refs[`item_${index}`]?.setElAttr()
              })
            }

            await this.$refs.ComboBuyPopupRef?.open({
              products,
              onClose: _syncPopupSelectNewStyle,
              onSubmit: _syncPopupSelectNewStyle,
              onSyncOusideItemAsyncFn
            })
          } else {
            /**
             * 同步弹窗内选中项
             */
            const _syncPopupSelect = ({ selectItems = [] }) => {
              const selectItemsGoodsid = selectItems.map(d => d.goods_id)

              products.forEach(item => {
                item._cartInfo.selectStatus = item._cartInfo.isSoldOut
                  ? false
                  : selectItemsGoodsid.includes(item.goods_id)
              })
            }
            await this.$refs.ComboBuyPopupRef?.open({
              products,
              onClose: _syncPopupSelect,
              onSubmit: _syncPopupSelect
            })
          }
        } catch (e) {
          /** empty */
        }
      }

      if (this.isDrawerLoaded) {
        await _next()
      } else {
        this.updateLoading(true)
        const timer = setTimeout(() => {
          this.updateLoading(false)
        }, 10000)
        this.$once('ComboBuyPopupRef:mounted', async () => {
          await _next()
          clearTimeout(timer)
          this.updateLoading(false)
        })
      }
    },
    async handleQuickAdd(payload) {
      const { target, index } = payload
      const imgRatio = await getImageRatio(payload?.goods_img)

      // 只有主商品显示到手价
      const showEstimatedPrice = !!payload?._cartInfo?.isMainGoods && !this.isHitComplianceMode

      this.$quickAdd.open({
        goods_id: payload.goods_id,
        imgRatio,
        mallCode: payload.mall_code,
        index,
        reducePrice: payload.reducePrice,
        addSource: 'detailPage',
        showBestDealLabel: true,
        showFollowBeltByOrigin: true,
        showEstimatedPrice,
        showAbPrice: true, // 展示ab价
        analysisConfig: {
          sourceTarget: target,
          sa: {
            activity_from: baseExposeData.activity_from,
            location: '-'
          }
        },
        clickCallBack: {
          isActive: status => {
            if (status) {
              this.reportOpenQuickView(target, payload, index)
            }
          },
          // 加车成功后的回调
          complete: () => {
            setTimeout(() => {
              window.vBus &&
                window.vBus.$emit('triggerAddCompletedFloatCart', {
                  animation: false
                })
            }, 2000)
          }
        }
      })
    },
    reportOpenQuickView(target, item, index) {
      daEventCenter.triggerNotice({
        daId: '2-3-5',
        target: target,
        extraData: {
          goodsListParams: {
            sku: item.goods_sn,
            spu: item.productRelationID || item.goods_sn,
            goodsId: item.goods_id,
            originPrice: item.retailPrice.usdAmount,
            price: item.salePrice.amount,
            mallCode: item.mall_code,
            index
          },
          need_tab_list: true
        }
      })
    },
    async onSelectSizeClick(item, index) {
      this.baseSelectSizeClick({
        item,
        index,
        cItemConfig: this.cItemConfig,
        onAfterUpdateFn: uptSkuItem => {
          console.log('onAfterUpdateFn', uptSkuItem, this.products)
          // TODO 后接简易价格组件配置 只有主商品显示到手价
          if(uptSkuItem?.goods_id != this.goodsId) {
            uptSkuItem.estimatedPriceInfo = {}
          }
          this.products.splice(index, 1, uptSkuItem)
          this.$nextTick(() => {
            // 切换更新埋点信息
            this.$refs[`item_${index}`]?.setElAttr()
          })
        }
      })
    },
    /**
     * 批量加车按钮
     */
    onAddCart: debounce({
      func() {
        return this.onAddCartFunc()
      },
      options: {
        leading: true
      },
      wait: 1000
    }),
    async onAddCartFunc() {
      const {
        cItemConfig,
        products,
        cBundlePrice,
        language,
        updateEstimatedCouponList,
        handleShowDrawer
      } = this

      this.showPromotion = false

      const { activity_from } = baseExposeData

      const cSelectItems = this.cSelectItems.concat()

      const _toast = content => {
        Toast({
          content: content,
          duration: 3000
        })
      }

      const _setSoldOutPromise = setComboBuyProductListSoldStatus({
        itemConfig: cItemConfig,
        goods: products
      })

      _setSoldOutPromise.then((res) => {
        // 如果出现库存更新，重新计算SPU售罄状态
        if (res?.diffSoldOutList?.length) {
          setProductListSpuSoldOut({
            goods: products,
          })
        }
      })


      if (!cSelectItems.length) {
        handleShowDrawer(true, 'Please select goods')
        return
      }

      const exposeItems = cSelectItems.map(d => {
        const index = this.products.findIndex(_d => _d.goods_id === d.goods_id)
        const target = this.$refs[`item_${index}`]?.$el
        return {
          ...d,
          target
        }
      })

      const checkRes = checkCanAddToCart({
        list: cSelectItems
      })

      if (!checkRes.ok) {
        const { isNoSku, isSoldOut } = checkRes
        let result_reason = ''
        if (isNoSku) {
          result_reason = 'Please select size'
        } else if (isSoldOut) {
          result_reason = 'Goods sold out'
        }

        handleShowDrawer(true, result_reason)
        return
      }

      this.loading = true

      try {
        /**
         * 判断是否有售罄商品，有的话更新库存
         */
        const res = await _setSoldOutPromise
        if (res?.selectSoldOut?.length) {
          _toast(language.SHEIN_KEY_PWA_29001 || 'sold out')
          this.loading = false
          return
        }

        /**
         * 批量加车
         */
        const data = await batchAddToCart(cSelectItems)
        const { code } = data || {}

        const _isOk = code == 0

        // 领券成功后关闭套装价弹窗
        let hasGetCoupon = false
        if (cBundlePrice?.couponList?.length) {
          hasGetCoupon = await autoGetCouponAtBatchAdd(
            cBundlePrice.couponList,
            language,
            _isOk
          )
          if (hasGetCoupon) {
            // 更新到手价券但不刷新推荐数据
            updateEstimatedCouponList({
              noUpdateRecommend: true
            })
          }
        }
        /**
         * 加车成功
         */
        if (_isOk) {
          // 更新购物车数量
          this.$store.dispatch('fetchCartInfo', null, { root: true })
          setTimeout(() => {
            // 更新右下角购物车数量
            window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: false })
          }, 2000)
          // 存在券需要自动领取（领的是当前套装价计算的那张券） todo
          if (!hasGetCoupon) {
            _toast(language.SHEIN_KEY_PWA_15021 || 'Add to bag successfully')
          }

          // 埋点
          handleExposeAddCart({
            list: exposeItems,
            result: data,
            activity_from
          })
          daEventCenter.triggerNotice({
            daId: '1-6-1-179',
            extraData: {
              quantity: cSelectItems.length,
              button_status: 'addbag',
            }
          })

          // 新样式加车成功清空选择
          if (this.isShowNewComboBuyStyleAbt) {
            setAllProductSelectEmpty(this.products)
          }

          this.baseAfterAddToCart({ ignoreSelectors: ['[data-floor-tab="comboBuy"]'] })
        } else {

          handleShowDrawer(true, code || 'server_failure')

          // 格式化加车错误返回值
          const _formatError = getFormatBatchAddErrorResult(data, language)
          if (_formatError._errorTips) {
            // 延迟展示防止被弹窗遮挡
            setTimeout(() => {
              _toast(_formatError._errorTips)
            }, 1000)
          }
        }
      } catch (e) {
        console.log('onAddCart error', e)
        handleShowDrawer(true, 'server_failure')
      }

      this.loading = false
    },
    async syncMainSkuCodeChange() {
      if (!this.show) return
      if (!this.isShowNewComboBuyStyleAbt) return

      const { goodsId, skuInfo } = this
      // 主商品选择了sku同步
      const mainSkuCode = skuInfo.sku_code
      if (!mainSkuCode) return

      const mainGoods = this.products?.[0]
      if (goodsId != mainGoods?.goods_id) return
      if (mainGoods?._cartInfo?.skuCode == mainSkuCode) return

      // 选中新sku
      setCommonCartProductSkuInfo(
        mainSkuCode,
        mainGoods,
      )


      // 重新获取sku纬度原子数据，防止sku价格不一致
      const goods = await getComboBuyPopupProductListByGoods({
        goods: [mainGoods],
        itemConfig: this.cItemConfig,
        rows: 3
      })
      const _uptItem = getFormatCommonCartProductItem(goods[0])
      setSyncItemCartInfo(_uptItem, mainGoods)
      this.products.splice(0, 1, _uptItem)
    },
    renderTop() {
      const { language, handleShowDrawer, isShowNewComboBuyOrangeScreenStyleAbt } = this

      const topProps = {
        directives: [
          {
            name: 'expose',
            value: {
              id: '1-6-1-178',
              callback: () => {
                this.showDrawer = true
              }
            }
          }
        ]
      }

      return (
        <div
          class={`combobuy-top ${isShowNewComboBuyOrangeScreenStyleAbt ? 'combobuy-top-screenstyle' : ''}`}
          {...topProps}
        >
          <div
            class="combobuy-top_title"
            vOn:click={() => handleShowDrawer(false)}
          >
            <div class="text">{language.SHEIN_KEY_PWA_28995}</div>
            <span class="viewmore">
              {language.SHEIN_KEY_PWA_25025}
              <i class="suiiconfont sui_icon_more_right_16px_2"></i>
            </span>
          </div>
        </div>
      )
    },
    renderTopNewStyle() {
      const { cBundlePrice, language } = this

      const _hasDiscount = !!cBundlePrice.discount
      const discount = GB_cssRight ? `%${cBundlePrice.discount}-` : `-${cBundlePrice.discount}%`
      const _discountRate = _hasDiscount
        ? discount
        : ''

      const topProps = {
        directives: [
          {
            name: 'expose',
            value: {
              id: '1-6-1-178',
              callback: () => {
                this.showDrawer = true
              }
            }
          }
        ],
        class: {
          'newcombobuy-top': true,
          'has-discount': _hasDiscount
        }
      }

      const _isEmpty = isAmountEmpty(cBundlePrice.amountWithSymbol)

      const _tTips = language.SHEIN_KEY_PWA_29878 || `Selected items' total price`


      const renderSalePrice = () => {
        if (_isEmpty) return <div class="sale-price">{cBundlePrice.amountWithSymbol}</div>

        const { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix } = handlePriceHtml(cBundlePrice.amountWithSymbol)

        return (
          <div class="sale-price">
            <span style="font-size:24px;">{currencySymbolPrefix}{numberBeforeDot}</span>
            <span style="font-size:14px;">{numberAfterDot}{currencySymbolSuffix}</span>
          </div>
        )
      }
      return (
        <div {...topProps}>
          <div class="left-bgicon"></div>

          <div class="total-price">
            { renderSalePrice() }
            { _hasDiscount && <span class="origin-price">
              {cBundlePrice.retailAmountWithSymbol}
            </span>}
          </div>
          <div class="total-tips">
            <p>{_tTips}</p>
          </div>

          {_hasDiscount && <div class="discount-badge">
            <div class={{
              'bg-img': true,
              'ar-rotate': GB_cssRight,
            }}></div>
            <div class="text">{_discountRate}</div>
          </div>}
          <div class={{
            'right-bgicon': true,
            'ar-rotate': GB_cssRight,
          }}></div>
        </div>
      )
    },
    renderContent() {
      const {
        products,
        cItemConfig,
        constantData,
        onSelectChange,
        handleQuickAdd,
        onSelectSizeClick,
        language,
        isPaidUser,
        productItemsLanguage,
        cRenderBindData,
        isShowNewComboBuyStyleAbt,
        cBundlePrice,
        getEstimatedInfo = {},
        isShowNewComboBuyScreenStyleAbt,
        isHideNewComboBuyScreenStyleSizeAbt,
        goodsId,
        cSimplePriceConfig,
      } = this
      // 有到手价且满足门槛
      const hasEstimatedPrice = !!(getEstimatedInfo?.value?.amountWithSymbol && getEstimatedInfo?.isSatisfied)
      if (!products.length) return null

      const _tThisItem = language.SHEIN_KEY_PWA_24207 || 'This item'

      const itemProps = {
        directives: [
          {
            name: 'expose',
            value: {
              id: '2-3-2',
              code: baseExposeData.reportCode,
              callback: baseExposeData.exposeCallback
            }
          }
        ],
        props: {
          constantData,
          language,
          column: 3.3,
          showSelect: true,
          selectPosition: 'imgLeft',
          focusSelectRange: true
        }
      }

      const _onSelectChange = (payload, index) => {
        onSelectChange({ ...payload, target: this.$refs['item_' + index]?.$el })
      }

      const _onClickItem = (payload, index) => {
        const target = this.$refs['item_' + index]?.$el
        if (!target) return

        if (isShowNewComboBuyStyleAbt && !isHideNewComboBuyScreenStyleSizeAbt) {
          onSelectSizeClick(payload, index)
        } else {
          handleQuickAdd({ ...payload, target })
        }

        const _isClickFromImg = payload.clickPosition === 'mainImg'
        daEventCenter.triggerNotice({
          daId: '2-3-1',
          target,
          extraData: {
            click_position: _isClickFromImg ? 'image' : 'size'
          }
        })
      }

      const wrapProps = {
        attrs: cRenderBindData
      }

      return (
        <div
          class={`
            ${isShowNewComboBuyStyleAbt ? 'newcombobuy-content' : 'combobuy-content'}
            ${!!cBundlePrice.discount ? 'has-discount' : ''}
            ${isShowNewComboBuyScreenStyleAbt ? 'combobuy-content-screenstyle newcombobuy-content-screenstyle' : ''}`
          }
        >
          <div
            class="combobuy-content-list j-da-event-box"
            {...wrapProps}
          >
            {products.map((item, index) => {
              return (
                <MultipleImageRowCard
                  ref={`item_${index}`}
                  class={`list_item ${item._cartInfo.isSpuSoldOut ? '' : 'not-gray'}`}
                  key={`${item.goods_id}_${index}`}
                  {...itemProps}
                  item={item}
                  index={index}
                  config={getNotEstimatedProductCardConfig(cItemConfig, { showEstimatedPrice: hasEstimatedPrice && item._cartInfo.isMainGoods && item.goods_id === goodsId })}
                  select-status={item._cartInfo.selectStatus}
                  language={productItemsLanguage}
                  select-disable={item._cartInfo.isSoldOut}
                  vOn:select={payload => {
                    _onSelectChange(payload, index)
                  }}
                  vOn:clickItem={payload => {
                    _onClickItem(payload, index)
                  }}
                >
                  {!isShowNewComboBuyScreenStyleAbt && item._cartInfo.isMainGoods && (
                    <template slot="imgBottomInTop">
                      <div class="combobuy-item_thisitem">{_tThisItem}</div>
                    </template>
                  )}

                  {isShowNewComboBuyStyleAbt && !isHideNewComboBuyScreenStyleSizeAbt && (
                    <template slot="cardTitleTop">
                      <div class="combobuy-item_item-size">
                        <CommonCartSize
                          item={item}
                          style="padding-top:0;padding-bottom: 0.16rem;width:100%;"
                          vOn:select-size={() => _onClickItem(item, index)}
                        />
                      </div>
                    </template>
                  )}
                  {isShowNewComboBuyScreenStyleAbt && (
                    <template 
                      slot="imgBottom"
                    >
                      <ProductCardPriceSimple 
                        goodsInfo={item}
                        language={language} 
                        direction={'column'}
                        config={cSimplePriceConfig}
                        isPaid={isPaidUser}
                        camelPriceStyle={{
                          before: {
                            fontSize: '13px',
                          },
                          after: {
                            fontSize: '9px',
                          }
                        }}
                      />
                    </template>
                  )}
                </MultipleImageRowCard>
              )
            })}
          </div>
        </div>
      )
    },
    renderBottom() {
      const {
        handleShowDrawer,
        language,
        cSelectItems,
        cBundlePrice,
        loadingPrice,
        isShowNewComboBuyOrangeScreenStyleAbt
      } = this

      const _tBtnText = template(
        cSelectItems.length || 0,
        isShowNewComboBuyOrangeScreenStyleAbt ? '' : cBundlePrice.amountWithSymbol,
        language.SHEIN_KEY_PWA_28996
      )

      const [prefixEstimatedText, suffixEstimatedText] =
        language.SHEIN_KEY_PWA_24939.split('{0}')
      const _tEstimatedText =
        (prefixEstimatedText && prefixEstimatedText.trim()) || 'Estimated'
      const discount = GB_cssRight ? `-%${cBundlePrice?.discount}` : `-${cBundlePrice?.discount}%`  
      const _discountRate = cBundlePrice.discount
        ? discount
        : ''
      const _renderLoadingBtn = () => (
        <div class="button">
          <s-loading
            show={true}
            mode="dark"
          />
        </div>
      )
      return (
        <div class={`combobuy-bottom ${isShowNewComboBuyOrangeScreenStyleAbt ? 'combobuy-bottom-screenstyle' : ''}`}>
          <button
            class="combobuy-bottom_btn"
            onClick={() => {
              handleShowDrawer(true)
            }}
          >
            {loadingPrice ? (
              _renderLoadingBtn()
            ) : (
              <div class="button">
                <span class="text">{_tBtnText}</span>
                {isShowNewComboBuyOrangeScreenStyleAbt && (<span class="price">{cBundlePrice.amountWithSymbol}</span>)}
                {_discountRate && (
                  <span class="origin-price">
                    {cBundlePrice.retailAmountWithSymbol}
                  </span>
                )}
              </div>
            )}
          </button>
          {_discountRate && (
            <div class="combobuy-bottom_btn-tips">
              <svg
                class={`icon ${GB_cssRight ? 'ar-rotate' : ''}`}
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="5"
                viewBox="0 0 5 5"
                fill="none"
                style={{ marginRight: '0.05rem' }}
              >
                <path
                  d="M0 5H5V0C5 2.76142 2.76142 5 0 5Z"
                  fill="#FFECE9"
                />
              </svg>
              <span class="text">{_tEstimatedText}</span>
              <span class="rate">
                {_discountRate}
                {suffixEstimatedText ? suffixEstimatedText.trim() : null}
              </span>
            </div>
          )}
        </div>
      )
    },
    renderBottomNewStyle() {
      const {
        onAddCart,
        language,
        cSelectItems,
        cBundlePrice,
        loadingPrice
      } = this

      const _tBtnText = template(
        cSelectItems.length || 0,
        language.SHEIN_KEY_PWA_29877
      )

      const _renderLoadingBtn = () => (
        <div class="button">
          <s-loading
            show={true}
            mode="dark"
          />
        </div>
      )

      return (
        <div
          class={{
            'newcombobuy-bottom': true,
            'has-discount': !!cBundlePrice.discount
          }}
        >
          <button
            onClick={() => {
              onAddCart()
            }}
          >
            {loadingPrice ? (
              _renderLoadingBtn()
            ) : (
              <div class="button">
                <span class="text">{_tBtnText}</span>
              </div>
            )}
          </button>
        </div>
      )
    },
    renderPopup() {
      const { showDrawer, cMainGoodsPrice } = this
      if (!showDrawer) return null

      return (
        <div class="combobuy-popup">
          <ComboBuyPopup
            ref="ComboBuyPopupRef"
            show={showDrawer}
            c-main-goods-price={cMainGoodsPrice}
            vOn:mounted={() => {
              this.isDrawerLoaded = true
              this.$emit('ComboBuyPopupRef:mounted')
            }}
          />
        </div>
      )
    }
  },
  render() {
    const {
      renderTop,
      renderTopNewStyle,
      renderContent,
      renderBottom,
      renderBottomNewStyle,
      renderPopup,
      isShowNewComboBuyStyleAbt,
      loading,
      isShowNewComboBuyScreenStyleAbt
    } = this
    if (!this.show) return null

    return (
      <div
        class={{
          combobuy: true,
          new: isShowNewComboBuyStyleAbt
        }}
        data-floor-tab="comboBuy"
      >

        <s-loading
          type="curpage"
          show={loading}
        />
        {isShowNewComboBuyStyleAbt && !isShowNewComboBuyScreenStyleAbt ? renderTopNewStyle() : renderTop()}
        {renderContent()}
        {isShowNewComboBuyStyleAbt && !isShowNewComboBuyScreenStyleAbt ? renderBottomNewStyle() : renderBottom()}
        {renderPopup()}
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
@import url('./utils.less');

@mainColor: #ff5b1d;

.combobuy {
  background-color: #fff;
  margin-top: 0.213333rem;
  &.new {
    background-color: transparent;
  }
}

.combobuy-top {
  padding: 0.32rem;
  color: #222;
  line-height: 1;

  &_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    line-height: 1;
  }

  .text {
    font-size: 14px;
    font-weight: 700;
  }

  .viewmore {
    display: flex;
    align-items: center;
    color: #666;
    // font-size: 0.32rem;
    font-size: 12px;
    font-weight: 400;
  }
}

.combobuy-content {
  padding: 0 0.32rem;
}
.combobuy-content-list {
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .list_item {
    flex: none;
    margin-right: 0.21rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.combobuy-bottom {
  padding: 0 0.32rem 0.27rem 0.32rem;
  position: relative;
  &_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.96rem;
    color: #fff;
    background: #222;
    border: 1px solid transparent;
    line-height: 1;
    font-weight: 400;
    width: 100%;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      align-content: flex-start;
      text-align: center;
      font-size: 0.37333rem;
      padding: 0 0.42667rem;

      .price {
        font-size: 0.42667rem;
        font-weight: 700;
        margin-left: 0.11rem;
      }
      .origin-price {
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.26667rem;
        font-weight: 400;
        text-decoration: line-through;
        margin-left: 0.05rem;
        align-self: flex-end;
      }
    }
  }
}

.combobuy-bottom_btn-tips {
  position: absolute;
  top: -25%;
  right: 0.32rem;
  line-height: 1;
  height: 0.42667rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon {
    position: absolute;
    bottom: 0;
    left: -5px;
  }
  .text,
  .rate {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 0.08rem;
  }
  .text {
    color: #fa6338;
    font-family: SF Pro;
    .font-dpr(20px);
    font-weight: 400;
    // padding: 0 0.08rem;
    background: #ffece9;
    border-radius: 0.05333rem 0rem 0rem 0rem;
  }
  .rate {
    border-radius: 0rem 0.05333rem 0.05333rem 0rem;
    background: #fa6338;
    color: rgba(255, 255, 255, 0.96);
    font-size: 0.26667rem;
    font-weight: 700;
    // padding: 0 0.05rem;
  }
}

/**
* 新样式
*/
@leftIconNotDiscount: 'https://img.ltwebstatic.com/images3_ccc/2024/03/29/30/1711694725d28347468fdef3b5d3b06df4df36d4c9.png';
@leftIconHasDiscount: 'https://img.ltwebstatic.com/images3_ccc/2024/03/29/30/1711694725b102e8a2aaa62e6522475ebf0b559604.png';
@discountBadgeBg: 'https://img.ltwebstatic.com/images3_ccc/2024/03/29/30/1711694725fb2883adb2b05873f59306eb5fa4c5fe.png';
@topRightIcon: 'https://img.ltwebstatic.com/images3_ccc/2024/03/29/30/1711694725e448464c11d74d55ec49ff8347edefc5.png';

@newcomboyTopbg: 'https://img.ltwebstatic.com/images3_ccc/2024/03/29/30/1711694725e67adf959e16efa10676ad74ffdfbbe1.png';
@newcomboyTopbgDiscount: 'https://img.ltwebstatic.com/images3_ccc/2024/03/29/30/1711694725de439d540308089f1f48e62073066711.png';

.newcombobuy-top {
  color: #222;
  line-height: 1;
  height: 51px;
  position: relative;
  background-image: url(@newcomboyTopbg);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;

  .total-price {
    font-style: normal;
    display: flex;
    align-items: baseline;
    .sale-price {
      color: #000;
      font-weight: bold;
      font-size: 24px;
      font-family: "SF Pro";
      font-weight: 1000;
    }
    .origin-price {
      color: #959595;
      font-size: 0.26667rem;
      font-weight: 400;
      text-decoration: line-through;
      margin-left: 0.11rem;

    }
  }

  .total-tips {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.26667rem;
    font-weight: 400;
    margin-top: 5px;

    p {
      // 超出省略
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      max-width: calc(375px - 194px);
    }
  }

  .left-bgicon {
    background-image: url(@leftIconNotDiscount);
    background-size: 100% 100%;

    width: 54px;
    height: 51px;
    position: absolute;
    bottom: 0;
    left: 0.29rem;
  }
  .discount-badge {
    position: absolute;
    top: -3px;
    right: 14px;
    width: 85px;
    height: 63px;

    .bg-img {
      background-image: url(@discountBadgeBg);
      background-size: 100% 100%;
      width: 85px;
      height: 63px;
      position: absolute;
      left: 0;
      top: 0;
    }

    .text {
      position: absolute;
      top: 12px;
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-weight: 860;
      font-family: "SF Pro";
      direction: ltr /* rtl: ltr */;
    }
  }

  .right-bgicon {
    background-size: 100% 100%;
    background-image: url(@topRightIcon);

    &::before {
      content: "";
      background-size: 100% 100%;
      width: 56px;
      height: 56px;
      display: block;
    }
    width: 86px;
    height: 51px;
    position: absolute;
    top: 0;
    right: 0;
  }

  &.has-discount {
    background-image: url(@newcomboyTopbgDiscount);
    .sale-price,
    .total-tips {
      color: @mainColor;
    }

    .left-bgicon {
      background-image: url(@leftIconHasDiscount);
      height: 41px;
    }

    .right-bgicon {
      display: none;
    }
  }
}
.newcombobuy-content {
  // padding: 0.32rem 0 0 0.32rem;
  padding-top: 0.32rem;
  background-color: #fff;
  .combobuy-content-list {

    .list_item {
      flex: none;
      margin-right: 0.21rem;
      width: 2.6933rem;
      /* stylelint-disable-next-line */
      &:first-child {
        margin-left: 0.32rem;
      }
      /* stylelint-disable-next-line */
      &:last-child {
        margin-right: 0.32rem;
      }
    }

    /* stylelint-disable-next-line */
    .list_item.not-gray {
      /* stylelint-disable-next-line */
      .combobuy-item_item-size {
        /* stylelint-disable-next-line */
        opacity: 1 !important;
      }
    }

    /deep/ .product-card__bottom-wrapper {
      /* stylelint-disable-next-line */
      padding-left: 0 !important;
      /* stylelint-disable-next-line */
      padding-right: 0 !important;
    }

    .combobuy-item_item-size {
      /* stylelint-disable-next-line */
      /deep/ .common-cart-size {
        width: 100%;
      }
    }
  }


  &.has-discount {
    .list_item {
      /* stylelint-disable-next-line */
      /deep/ .S-checkbox[aria-checked="true"] {
        /* stylelint-disable-next-line */
        .S-checkbox__input {
          border-color: #FA6338;
          background-color: #FA6338;
        }
      }
    }
  }
}

.newcombobuy-bottom {
  padding: 0 0.32rem 0.27rem 0.32rem;
  position: relative;
  border-bottom: 1px solid rgba(252, 239, 232);
  background-color: #fff;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.96rem;
    color: #fff;
    background: #222;

    border: none;
    line-height: 1;
    font-weight: 700;
    width: 100%;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      align-content: flex-start;
      text-align: center;
      font-size: 0.37333rem;
      padding: 0 0.42667rem;
    }
  }

  &.has-discount {
    button {
      background: #ff4f1c;
      box-shadow: 0px 4px 15px 0px rgba(255, 255, 255, 0.6) inset;
    }
  }
}
.combobuy-content-screenstyle {
  .combobuy-content-list {
    .list_item {
      width: 2.08rem;
    }
    /deep/ .price-content{
      > p{
        display: inline-block;
        background: rgba(255, 255, 255, 0.90);
        margin-bottom: 4px;
        padding: 0px 3px;
        border-radius: 2px;
      }
    }
    /deep/ .price-wrapper__price,.bottom-wrapper__labels,.bottom-wrapper__price-wrapper,.bottom-wrapper__selling-info {
      display: none;
    }
  }
}
.combobuy-top-screenstyle {
  padding: .32rem .32rem 0.0533rem .32rem;
  border-top: 1px solid rgba(255, 255, 255, 0.50);
  background: linear-gradient(359deg, #FFF 14.5%, #FFDFD2 111.22%);
}
.newcombobuy-content.newcombobuy-content-screenstyle {
  padding-top: .2667rem;
}
.combobuy-bottom-screenstyle {
  background-color: #fff;
  button {
    background: #ff4f1c;
    box-shadow: 0px 4px 15px 0px rgba(255, 255, 255, 0.6) inset;
    border: none;
  }
  .button .price {
    .font-dpr(36px)
  }
  .combobuy-bottom_btn-tips .text {
    .font-dpr(22px)
  }
  .combobuy-bottom_btn-tips .rate {
    .font-dpr(24px)
  }
}
.ar-rotate {
  transform: rotateY(180deg);
}
</style>
