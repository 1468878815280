var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"goods-review__guidance-wrapper",style:({
    'max-width': !_vm.haveDividingLine?'5.00366rem':'3.73333rem',
    'margin-left': !_vm.haveDividingLine?'0.106666rem':'0px',
    'margin-top': _vm.haveDividingLine?'0.106666rem':'0px'
  })},[(!_vm.showPopover)?_c('span',{staticClass:"goods-review__guidance",on:{"click":_vm.goToGuidance}},[_c('span',{staticClass:"goods-review__guidance-text"},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_30986 || 'Richtlinie für Kundenbewertungen'))]),_vm._v(" "),_c('i',{staticClass:"suiiconfont sui_icon_doubt_12px_2"})]):_vm._e(),_vm._v(" "),(_vm.showPopover)?_c('span',{staticClass:"goods-review__guidance"},[_c('span',{staticClass:"goods-review__guidance-text",on:{"click":_vm.goToGuidance}},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_30986 || 'Richtlinie für Kundenbewertungen'))]),_vm._v(" "),_c('s-popover',{attrs:{"append-to-body":false,"theme":"light","placemen":"bottom-start","trigger-type":"click","prop-style":{
        width: '201px',
        'overflow-wrap': 'break-word',
        'word-break': 'break-word',
        'white-space': 'pre-wrap',
        'border-radius': '2px',
      },"show-close-icon":true,"content":_vm.language.SHEIN_KEY_PWA_32328 || 'Wszystkie recenzje pochodzą ze zweryfikowanych transakcji.'}},[(!_vm.isDetail && !_vm.showReviewsGuidance)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({ id: '1-6-2-55' }),expression:"{ id: '1-6-2-55' }"}],staticClass:"star-info",attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"suiiconfont sui_icon_doubt_12px_2"})]):_vm._e()])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }