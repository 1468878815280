<script lang="jsx">
export default {
  name: 'NewSizeGuideEnter',
  functional: true,
  props: {
    title: {
      type: String,
      default: ''
    },
    goodsId: {
      type: String,
      default: ''
    },
    classStr: {
      type: String,
      default: ''
    },
    openSizeGuidePop: {
      type: Function,
      default: () => {}
    },
  },
  render(h, { props }) {
    const { title, goodsId, classStr, openSizeGuidePop } = props

    const openSizeGuide = () => {
      openSizeGuidePop?.({ fromScene: 'detail_section' })
    }

    const titleClass = `sizeChart NEW_BFF_COMPONENT ${classStr || ''}`

    return <div class={titleClass} aria-hidden="true">
      <div
        {...{ directives: [{ name: 'tap', value: { id: '1-6-4-14', data: { goodsId, from: 'other' } } }] }}
        {...{ directives: [{ name: 'expose', value: { id: '1-6-4-14-2', data: { goodsId, from: 'other' } } }] }}
        class="size-chart j-push-history-hash"
        state="sizeGuide"
        vOn:click={openSizeGuide}
      >
        {title}
        <i class="suiiconfont sui_icon_more_right2_16px"></i>
      </div>
    </div>
  }
}
</script>

<style lang="less">
.size-chart {
  position: relative;
  padding: 0.32rem 0 0.16rem 0;
  text-transform: capitalize;
  display: block;
  width: 100%;
  .txt-l();
  .font-dpr(28px);
  /* rw:begin */
  font-family: 'Adieu';
  .sui_icon_more_right_16px, .sui_icon_more_right2_16px {
    position: absolute;
    color: #959595;
    .right(0);
  }
}
.sizeChart {
  .c-aside.c-aside-full {
    .left(0);
  }
}
</style>
