import Vue from 'vue'
import { debounce } from '@shein/common-function'

let compInstance = null

export default {
  open(config) {
    return this.instanceOpen(config)
  },
  instanceOpen: debounce({
    func: (config) => {

      if (compInstance) {
        compInstance.open(config)
        return compInstance
      }
      
      return new Promise((resolve) => {
        import(/* webpackChunkName: "CatSelectionTagDrawer" */ './index.vue').then((components) => {
          const quickAdd = components.default
          const vm = new Vue({
            render (h) {
              return h(quickAdd)
            }
          }).$mount()
          document.body.appendChild(vm.$el)
          const comp = vm.$children[0]
          comp.open(config)
          compInstance = comp
          resolve(compInstance)
        })
      })
    },
    wait: 200,
    options: {
      leading: true,
    }
  }),
}
