var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'brand-outer-box': true,
    'brand-box-new_bff': true,
    'brand-type': _vm.STYLE_V1,
    'brand-type_new': _vm.STYLE_V2,
  }},[_c('div',{directives:[{name:"ada",rawName:"v-ada",value:({ level: 1, pos: [0] }),expression:"{ level: 1, pos: [0] }"}],staticClass:"mgds-brand j-sa-brand-view-products",class:{
      'mgds-storebrand': _vm.STYLE_V1,
      'mgds-storebrand_new': _vm.STYLE_V2
    },attrs:{"aria-label":_vm.store_brand_entry_box.name,"data-name":_vm.store_brand_entry_box.name,"data-brand_code":_vm.store_brand_entry_box.id,"data-goods_id":_vm.sign_data.goods_id,"data-brand_type":_vm.store_brand_entry_box.type,"data-designer_id":_vm.store_brand_entry_box.designerId,"data-sc_id":_vm.store_brand_entry_box.scId,"data-is_alone_store_brand":_vm.STYLE_V2 || _vm.STYLE_V1,"role":"text"},on:{"click":function($event){return _vm.viewProducts()}}},[_c('div',{directives:[{name:"ada",rawName:"v-ada",value:({ level: 1, pos: [0] }),expression:"{ level: 1, pos: [0] }"}],class:['mgds-brand__detail', { center: !_vm.descriptionsShow }],attrs:{"aria-label":_vm.store_brand_entry_box.name,"role":"text","aria-hidden":"true"}},[(_vm.showBrandLogo)?_c('div',{staticClass:"mid-size",class:{
          'mgds-brand__detail-logo': true,
        }},[_c('img',{staticClass:"mid-size",attrs:{"src":_vm.store_brand_entry_box.logo}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mgds-brand__detail-info"},[_c('div',{staticClass:"mgds-brand__detail-info-title"},[_c('div',{staticClass:"text"},[_c('span',{staticClass:"text_first"},[_vm._v(_vm._s(_vm.store_brand_entry_box.name))]),_vm._v(" "),(_vm.STYLE_V2)?[_c('div',{staticClass:"store-brand_tip split-left"},[_c('img',{attrs:{"src":`${_vm.PUBLIC_CDN}/pwa_dist/images/store/brand_tips_icon-bd0d0de393.svg`}})])]:_vm._e(),_vm._v(" "),(_vm.isAbtAuthenticbrandShow)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({ id: '1-6-4-74', data: _vm.authenticbrandShowAnalysisData }),expression:"{ id: '1-6-4-74', data: authenticbrandShowAnalysisData }"}],staticClass:"storebrand__authentic"},[_vm._v("\n              "+_vm._s(_vm.sign_board_text.authenticLang)+"\n            ")]):_vm._e()],2)]),_vm._v(" "),(_vm.descriptionsShow && !_vm.STYLE_V2)?[(!_vm.STYLE_V1)?_c('div',{staticClass:"desc-brand"},[_vm._v("\n            "+_vm._s(_vm.store_brand_entry_box.desc)+"\n          ")]):(_vm.STYLE_V1)?_c('div',{staticClass:"storebrand-desc-wrap"},[_c('div',{staticClass:"storebrand-desc-wrap__content"},[_vm._v("\n              "+_vm._s(_vm.store_brand_entry_box.desc)+"\n            ")]),_vm._v(" "),(_vm.store_brand_entry_box.link)?_c('div',{staticClass:"mgds-brand__view-products",attrs:{"aria-hidden":"true"}},[_c('i',{class:_vm.viewMoreIconClass})]):_vm._e()]):_vm._e()]:_vm._e()],2)]),_vm._v(" "),(_vm.store_brand_entry_box.link)?_c('div',{staticClass:"mgds-brand__view-products",class:{
        hidden: _vm.descriptionsShow && _vm.STYLE_V1
      },attrs:{"aria-hidden":"true"}},[_c('i',{class:_vm.viewMoreIconClass})]):_vm._e()]),_vm._v(" "),(_vm.descriptionsShow)?[(_vm.STYLE_V2)?_c('div',{class:[
        'storebrand__descriptions',
        {
          ellipsis: !_vm.storeBrandDescExpend
        }
      ],on:{"click":function($event){_vm.storeBrandDescExpend = true}}},[_vm._v("\n      "+_vm._s(_vm.store_brand_entry_box.desc)+"\n    ")]):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.showBrandsZone)?_c('div',{staticClass:"storebrand__zone",on:{"click":_vm.goToBrandZone}},[_c('span',{staticClass:"storebrand__zone-title"},[_vm._v(_vm._s(_vm.sign_board_text.zoneLang))]),_vm._v(" "),_c('span',{staticClass:"storebrand__zone-desc"},[_vm._v(_vm._s(_vm.sign_board_text.newBrandsLang))]),_vm._v(" "),_c('i',{staticClass:"suiiconfont sui_icon_more_right_12px_2 storebrand__zone-icon"})]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }