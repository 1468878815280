<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
import { daEventCenter } from 'public/src/services/eventCenter'
const { GB_cssRight } = gbCommonInfo
daEventCenter.addSubscriber({ modulecode: '1-6-1' })

export default {
  name: 'UserManualEnter',
  functional: true,
  components: {
    Icon
  },
  props: {
    boxStyle: {
      type: Object,
      default: () => {}
    },
    openUserManualPopFn: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  },
  render(h, { props }) {
    const { title, content, boxStyle, openUserManualPopFn } = props
    const openUserManual = () => {
      openUserManualPopFn()
    }
    return (
      <div style={boxStyle} {...{ directives: [{ name: 'expose', value: { id: '1-6-1-218' } }] }}  >
        <div
          class="manual-enter"
          state="userManual"
          vOn:click={openUserManual}
        >
          <div
            class="manual-enter__title"
            aria-hidden="true"
          >
            <span 
              class="manual-enter__content" 
              style={{ 'text-align': GB_cssRight ? 'right' : 'left' }}
            >
              {title}
            </span>
            <span class="manual-enter__text">
              <Icon
                name="sui_icon_ppt_14px" 
                size="11px"
                color="#BBB"
              />
              {content}
            </span>
          </div>
          <i
            aria-hidden="true"
            class={[
              'suiiconfont',
              'sui_icon_more_right2_16px'
            ]}
          ></i>
        </div>
      </div>
    )
  }
}
</script>

<style lang="less">
.manual-enter {
  .flexbox();
  justify-content: space-between;
  position: relative;
  height: 1.12rem;
  line-height: 1.12rem;
  text-transform: capitalize;
  .font-dpr(28px);
  width: 100%;
  text-align: left /*rtl:ignore*/;
  &__title {
    width: 0;
    .font-dpr(28px);
    .padding-r(0.64rem);
    flex: 1;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    gap: 0.64rem;
    /* rw:begin */
    font-family: 'Adieu';
    
  }
  &__content{
    // 单行文本截断
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }
  &__text {
    color: var(---sui_color_gray_dark3, #767676);
    font-size: 11px;
    display: flex;
    align-items: center;
    gap: 0.107rem;
  }
  .sui_icon_more_right_16px,
  .sui_icon_more_right2_16px {
    position: absolute;
    top: 0;
    color: #959595;
    .right(0);
  }
}
</style>
