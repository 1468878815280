<template>
  <s-popover
    v-model="showTips"
    theme="light"
    trigger-type="user"
    class="comment-report__container"
    :placemen="position"
    :append-to-body="false"
  >
    <!-- Content -->
    <div
      class="tips-content"
      @click="reportClick"
    >
      {{ language.SHEIN_KEY_PWA_20498 }}
    </div>
    <div
      slot="reference"
      class="more-btn"
      @click="updateTips"
    >
      <i class="suiiconfont sui_icon_more_20px"></i>
    </div>
  </s-popover>
</template>

<script>
import Vue from 'vue'
import { Popover } from '@shein/sui-mobile'
import CommentReportPopover from './report/index'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import schttp from 'public/src/services/schttp'
Vue.use(Popover)
const { lang } = gbCommonInfo
daEventCenter.addSubscriber({ modulecode: '2-23' })
export default {
  name: 'CommentReport',
  props: {
    commentId: {
      type: String,
      default: ''
    },
    skc: {
      type: String,
      default: ''
    },
    isFree: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'top-end'
    },
    analysis: {
      type: Object,
      default() {
        return {
          activity_from: '',
          location: ''
        }
      }
    },
    loginedHandle: {
      type: Function,
      default: null,
    },
    isOutStoreReview: {
      type: Boolean,
      default: false
    },
    reportMemberId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      showTips: false,
      timer: null,
      language: {},
      options: []
    }
  },
  created() {
    this.fetchLanguage()
  },
  methods: {
    fetchLanguage() {
      const cacheLanguage = sessionStorage.getItem(`${lang}_COMMENT_REPORT_LANGUAGE`)
      if (cacheLanguage) {
        this.setData(JSON.parse(cacheLanguage))
      } else {
        schttp({
          url: '/api/common/language/get',
          params: { page: 'comment_report' }
        }).then(({ language }) => {
          sessionStorage.setItem(
            `${lang}_COMMENT_REPORT_LANGUAGE`,
            JSON.stringify(language)
          )
          this.setData(language)
        })
      }
    },
    setData(language) {
      this.language = language
      this.language = language
      this.options = [
        language.SHEIN_KEY_PWA_21331,
        language.SHEIN_KEY_PWA_21333,
        language.SHEIN_KEY_PWA_21335,
        language.SHEIN_KEY_PWA_21336,
        language.SHEIN_KEY_PWA_21332,
        language.SHEIN_KEY_PWA_21334
      ]
    },
    reportClick() {
      const _this = this
      const { commentId = '', skc = '', isFree, options, language, isOutStoreReview, loginedHandle, reportMemberId } = _this
      const props = { options, language }
      const scene = typeof location !== 'undefined' ? (location.hash?.indexOf?.('comment-all') === -1 ? '1' : '2') : ''
      CommentReportPopover.open({
        props,
        config: {
          skc,
          isFree,
          scene,
          comment_id: commentId,
          isOutStoreReview,
          callbacks: {
            submit: ({ comment_id, reason }) => {
              _this.$emit('reportSubmit', { comment_id, reason })
              daEventCenter.triggerNotice({
                daId: '2-23-2',
                extraData: {
                  id: comment_id,
                  reason,
                  location: this.analysis.location
                }
              })
            },
            loginedHandle,
          },
          reportMemberId
        }
      })
      this.changeHash('comment-report')
      _this.$emit('reportClick', { comment_id: commentId })
      _this.showTips = false
      const extraData = {
        activity_from: this.analysis.activity_from,
      }
      if (this.analysis.activity_from === 'store_reviews') extraData.review_id = commentId
      daEventCenter.triggerNotice({
        daId: '2-23-1',
        extraData,
      })
    },
    changeHash(value) {
      location.hash = value ? '#' + value : ''
    },
    updateTips() {
      this.showTips = true
      this.setTimer()
    },
    setTimer(holding) {
      this.timer && clearTimeout(this.timer)
      if (holding) return false
      this.timer = setTimeout(() => {
        this.showTips = false
      }, 3000)
    }
  }
}
</script>

<style lang="less">
.comment-report__container {
  .more-btn {
    .sui_icon_more_20px {
      color: #959595;
      .margin-l(0.527rem);
    }
  }
  .tips-content {
    white-space: nowrap;
  }
}
</style>
