<template>
  <ul
    v-show="selectTags.length"
    v-expose="getAnalysisData()"
    class="multi-rate-box"
  >
    <li
      v-for="(tag, index) in selectTags"
      :key="index"
    >
      <span class="tag-name">{{ tag.name }}:&nbsp;</span>
      <span>{{ tag.option_name }}</span>
    </li>
  </ul>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive.js'

export default {
  name: 'MultiRate',
  directives: { expose },
  inject: ['COMMENT_INSTANCE'],
  props: {
    catId: {
      type: String,
      default: '',
    },
    commentPosition: {
      type: [Number, String],
      default: 0,
    },
    position: {
      type: String,
      default: 'outer'
    }
  },
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    // 多维评分标签
    // * 长度为0表示无标签
    selectTags() {
      const { comment = {} } = this
      const { select_tag } = comment
      if (Array.isArray(select_tag) && select_tag.length) {
        const tags = select_tag.filter(tag => !!tag)
        if (tags.length) {
          return tags
        }
      }
      return []
    },
  },
  methods: {
    getAnalysisData() {
      const { selectTags, catId, commentPosition } = this
      const cposition = Number(commentPosition)
      if (!selectTags.length || isNaN(cposition) || cposition === 0) {
        return {}
      }
      const tagIds = selectTags.map(tag => tag.id).join(',')
      return {
        id: this.position === 'outer' ? '1-6-7-54' : '1-6-2-54',
        data: {
          multi_lable: tagIds,
          cat_id: catId,
          review_num: cposition,
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .multi-rate-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 49.5%;
      display: flex;
      color: @sui_color_gray_dark3;
      .font-dpr(24px);
      padding-bottom: .106667rem;

      .tag-name {
        color: #666;
      }
    }
  }
</style>
