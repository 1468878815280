<template>
  <div
    class="goods-attr__list-img"
  >
    <div class="goods-attr__list-img-title">
      {{ group.attr_name }}
    </div>
    <div class="goods-attr__list-img-list">
      <div
        class="goods-attr__list-img-scroll"
        :style="{ width: `${scrollBoxWidthPercentage}%` }"
      >
        <div
          v-for="(icon, index) in group.attrImgList"
          :key="index"
          class="goods-attr__list-img-li"
          :style="{ width: singleItemWidth, height: singleItemHeight }"
        >
          <img :src="icon.attr_image" />
          <p
            v-if="icon.attr_desc"
            class="title"
          >
            {{ icon.attr_desc }}
          </p>
          <p
            v-if="icon.attr_value"
          >
            {{ icon.attr_value }}
          </p>
        </div>

        <div class="clear-float"></div>
      </div>
    </div>
  </div>
</template>

<script>
const IMG_ATTR_MARGIN = 0.213333
const REVEAL_NEXT_SPACE = 2

export default {
  props: {
    group: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    groupLength() {
      const { group } = this
      return group?.attrImgList?.length || 0
    },
    // 外框宽度
    scrollBoxWidthPercentage() {
      const { groupLength } = this
      // 三个以内显示100%
      if (groupLength <= 3) {
        return 100
      }

      // 超过3个向外递推
      const revealSpace = groupLength * REVEAL_NEXT_SPACE
      return groupLength * (100 / 3) - revealSpace
    },
    // 单个图标宽度
    singleItemWidth() {
      const { groupLength } = this
      if (groupLength <= 3) {
        return `calc((100% - ${2 * IMG_ATTR_MARGIN}rem) / 3 - 0.5px)`
      }
      const allMargin = IMG_ATTR_MARGIN * (groupLength - 1)
      const baseWidth = `(100% - ${allMargin}rem) / ${groupLength}`
      return `calc(${baseWidth} - 0.5px)`
    },
    // 单个图标高度
    singleItemHeight() {
      const { group } = this
      if (!group || !group?.attrImgList?.length) {
        return ''
      }

      if (group?.attrImgList?.some(icon => icon?.attr_desc)) {
        return '2.72rem' // 102px
      }
      return '2.346667rem' // 88px
    }
  }
}
</script>

<style lang="less">
  .goods-attr {
    &__list-img {
      margin-bottom: 0.42667rem;
      .font-dpr(24px);
      line-height: 1.2;
      color: #767676;
    }
    &__list-img-title {
      margin: 0 0 .32rem;
      color: #222;
    }
    &__list-img-list {
      width: 100%;
      overflow-x: scroll;
      // 解决真机下划线被遮挡
      padding-bottom: 1px;
    }
    ::-webkit-scrollbar {
      display: none;
    }
    &__list-img-scroll {
      .clear-float {
        clear: both;
      }
    }

    &__list-img-li {
      box-sizing: border-box;
      float: left;
      padding-bottom: .32rem;
      padding: .053333rem .213333rem .213333rem;
      margin-left: .213333rem;
      .border-dpr-style(border, 1px, solid, @sui_color_gray_weak1);
      &:first-child {
        margin-left: 0;
      }

      img {
        display: block;
        margin: 0 auto .106667rem;
        width: 1.173333rem;
        height: 1.173333rem;
        border-radius: 50%;
        opacity: 0.6;
      }
      p {
        text-align: center;
        word-break: break-word;
        .line-camp(1);
      }
      p.title {
        font-size: .32rem;
        margin-bottom: .053333rem;
        color: #000;
        .line-camp(2);
      }
    }
  }
</style>
