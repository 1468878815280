<template>
  <div class="j-expose__detailops-container">
    <div
      v-for="(item, idx) in content"
      :key="idx"
      aria-hidden="true"
      class="product-middle__has-border j-expose__detailops-content"
      :data-expose-id="'detailops' + idx"
    >
      <div
        class="goods-attr__opos j-push-history-hash j-expose__detailops-target"
        da-event-click="1-6-4-36"
        :data-position="idx + 2"
        data-status="close"
        @click="openDrawer(item, idx)"
      >
        {{ item.title }}
        <i class="suiiconfont sui_icon_more_right2_16px"></i>
      </div>
    </div>
    <DetailOPosDrawer
      v-model="status"
      :content="subContent"
      :idx="index"
    />
  </div>
</template>
<script>
import DetailOPosDrawer from 'public/src/pages/goods_detail_v2/innerComponents/drawer/DetailOPosDrawer.vue'
import GBDetailAnalysisEvent from 'public/src/pages/goods_detail/analysis/ga.js'
export default { 
  components: {
    DetailOPosDrawer
  },
  props: {
    content: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data () {
    return {
      subContent: {},
      title: '',
      index: -1,
      status: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.content.length) {
        this.exposeDetailOPos = GBDetailAnalysisEvent.exposeDetailOPos()
      }
    })
  },
  destroyed() {
    this.exposeDetailOPos?.destroyInstance()
  },
  methods: {
    openDrawer (item, idx) {
      this.subContent = item.sub_content
      this.title = item.title
      this.index = idx + 2
      this.status = true
    }
  }
}
</script>
<style lang="less">
.goods-attr{
  &__opos {
    position: relative;
    height: 1.28rem;
    line-height: 1.28rem;
    text-transform: capitalize;
    display: block;
    width: 100%;
    .font-dpr(28px);
    text-align: left;
    .sui_icon_more_right2_16px {
      position: absolute;
      top: 0;
      right: 0;
      color: #959595;
    }
  }
}
</style>
