<template>
  <div
    v-if="storeFollowInfo && storeFollowInfo.show"
    v-show="blockShow"
    :class="[className, 'info-ellipsis','shop-entry__storeFollowInfo', type]"
  >
    <template v-if="type === 'inline'|| type === 'inline-store'">
      <!-- Ratings -->
      <div
        v-if="formatStoreRate"
        class="info-item with-split-line"
      >
        <span class="key">{{ language.SHEIN_KEY_PWA_20750 }}</span>
        <span class="value">:&nbsp;{{ formatStoreRate }}</span>
      </div>
      <!-- Sold in 30d -->
      <div
        v-if="salesVolume && salesVolume !== '0'"
        class="info-item with-split-line"
      >
        <span class="key">{{ language.SHEIN_KEY_PWA_20745 }}</span>
        <span class="value">:&nbsp;{{ salesVolume }}</span>
      </div>
      <!-- Followers -->
      <div
        v-if="followCount && followCount !== '0'"
        class="info-item with-split-line"
      >
        <span class="key">{{ language.SHEIN_KEY_PWA_24069 }}</span>
        <span class="value">:&nbsp;{{ followCount }}</span>
      </div>
    </template>

    <template v-if="type === 'block'">
      <!-- Ratings -->
      <div
        v-if="formatStoreRate"
        class="block-info-item"
      >
        <div>
          <div class="value">
            {{ formatStoreRate }}
          </div>
          <div class="key">
            {{ language.SHEIN_KEY_PWA_20750 }}
          </div>
        </div>
      </div>
      <!-- Sold in 30d -->
      <div
        v-if="salesVolume && salesVolume !== '0'"
        class="block-info-item"
      >
        <div>
          <div class="value">
            {{ salesVolume }}
          </div>
          <div class="key">
            {{ language.SHEIN_KEY_PWA_20745 }}
          </div>
        </div>
      </div>
      <!-- Followers -->
      <div
        v-if="followCount && followCount !== '0'"
        class="block-info-item"
      >
        <div>
          <div class="value">
            {{ followCount }}
          </div>
          <div class="key">
            {{ language.SHEIN_KEY_PWA_24069 }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/**
 * @component FollowInfo 店铺关注信息
 * * 展示店铺关注信息等，无vuex依赖
 */
export default {
  name: 'StoreFollowInfo',
  props: {
    // 盒子类名
    className: {
      type: String,
      default: '',
    },
    // 店铺关注信息
    storeFollowInfo: {
      type: Object,
      default: () => ({}),
    },
    // 店铺评分
    // * 评分作为独立数据传入，有一定不合理性，未来需要优化
    storeRate: {
      type: [Number, String],
      default: 0,
    },
    // 展示类型 inline: 内嵌 | block: 独立模块
    type: {
      type: String,
      default: 'block',
    },
    // 多语言
    language: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    // 店铺分数的展示
    formatStoreRate() {
      const { storeRate } = this
      const storeRateNum = Number(storeRate) || 0
      if (!storeRateNum) {
        return ''
      }
      return storeRateNum.toFixed(2)
    },
    // 店铺关注数
    followCount() {
      const { storeFollowInfo } = this
      return storeFollowInfo?.followCount || '0'
    },
    // 店铺30日销量
    salesVolume() {
      const { storeFollowInfo } = this
      return storeFollowInfo?.salesVolume || '0'
    },
    // 若店铺相关信息全无或全为0，则不展示
    blockShow() {
      const { storeFollowInfo, storeRate, followCount, salesVolume } = this
      if (!storeFollowInfo?.show) return false
      return storeRate
        || (followCount && followCount !== '0')
        || (salesVolume && salesVolume !== '0')
    }
  }
}
</script>

<style lang="less" scoped>
.info-ellipsis {
  width: 100%;
  .info-item {
    display: flex;
    line-height: 14px;
    &:first-child {
      flex-shrink: 0.5;
    }
    &:last-child {
      flex-shrink: 1000;
    }
    .key {
      flex-shrink: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .value {
      flex-shrink: 0;
    }
  }
}

.shop-entry__storeFollowInfo {
  display: block;

  .info-item, .block-info-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: .32rem;
    width: 100%;

    .key, .value {
      text-align: center;
    }
    .value {
      .font-dpr(24px);
      color: #000;
      font-weight: 500;
    }
    .key {
      .font-dpr(22px);
      color: @sui_color_gray_dark3;
      font-weight: 400;
    }
  }

  .block-info-item {
    flex-grow: 1;
    display: flex;
    align-items: center;
    &::before {
      content: "";
      display: block;
      align-items: center;
      width: 1px;
      height: .48rem;
      display: inline-block;
      background-color: @sui_color_gray_weak1;
    }
    &:first-child::before {
      display: none;
    }

    & > div {
      flex-grow: 1;
    }
  }

  &.inline {
    display: flex;
    align-items: center;
    margin-top: .053333rem;
    .font-dpr(20px);

    .key {
      color: @sui_color_gray_dark2;
      font-weight: 400;
    }
    .value {
      color: @sui_color_gray_dark1;
      font-weight: 500;
    }
  }

  &.inline-store {
    font-size: 0.24rem;
    display: flex;
    align-items: center;
    .key {
      color: #222;
      font-weight: 400;
    }
    .value {
      color: #222;
      font-weight: 500;
    }
    .with-split-line::before{
      visibility: hidden;
      margin: 0 6px;
    }
  }

  .with-split-line::before {
    content: "";
    display: block;
    align-items: center;
    margin: 0 .16rem;
    width: 1px;
    height: .213333rem;
    display: inline-block;
    margin: 0 8px;
    background-color: @sui_color_gray_weak1;
  }
  .with-split-line:first-child::before {
    display: none;
  }
}
</style>
