<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import CatSelectionTagDrawer from 'public/src/pages/goods_detail_v2/innerComponents/drawer/CatSelectionTagDrawer/index.js'

export default {
  name: 'CatSelectionTagEnterV2',
  functional: true,
  components: {
    Icon,
  },
  props: {
    isNewStyle: {
      type: Boolean,
      default: false
    },
    categorySelectionFloorBo: {
      type: Object,
      default: () => ({})
    },
  },
  render(h, { props }) {
    const { langPath = '', GB_cssRight = false } = gbCommonInfo || {}
    const { isNewStyle, categorySelectionFloorBo } = props
    const { floorTitle = '', floorTags = [], categorySelectionDetailBO } = categorySelectionFloorBo || {}
    const titleStyle = isNewStyle ? {} : { 
      backgroundImage: `url(${langPath}/pwa_dist/images/proSelect_bg${GB_cssRight ? '_ar-3091a94cd3' : '-aa6a620d31'}.png)`
    }

    if (!floorTitle || !floorTags?.length) return null

    return <div 
      class={['product-selection', 'NEW_BFF_DETAIL_COMPONENT', { 'product-selection_new': isNewStyle }]}
    >
      <div 
        class="product-selection__tag"
        onClick={e => {
          if (!categorySelectionDetailBO) return
          CatSelectionTagDrawer.open({ categorySelectionDetailBO })
          e.stopPropagation()
          daEventCenter.triggerNotice({ daId: '1-6-4-72' })
          daEventCenter.triggerNotice({ daId: '1-6-4-73' })
        }}
      >
        <div 
          class="product-selection__tag-title"
          {...{ directives: [{ name: 'expose', value: { id: '1-6-4-71' } }] }}
          style={titleStyle}
        >
          {isNewStyle ? <Icon name="sui_icon_qualidade_14px_color" size="14px" /> : null}
          <span class="product-selection__tag-text">
            { floorTitle }
          </span>
        </div>
        {floorTags.map((item, index) => {
          const { tagName } = item || {}
          if (!tagName) return null
          return <div 
            class={['product-selection__tag-item', {
              'mar-left-ten': index !== 0
            }]}
          >
            <Icon
              name="sui_icon_success_14px_1"
              size="14px"
              color={ isNewStyle ? '#000' : '#745441'}
            />
            { tagName }
          </div>
        })}
        <div class="product-selection__tag-more">
          <Icon name="sui_icon_more_right_16px_2" size="16px" color={isNewStyle ? '#000' : '#745441'}  />
        </div>
      </div>
    </div>
  }
}
</script>

<style lang="less">
.product-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.2133rem;
  height: 1.12rem;
  padding: 0 0.32rem;
  overflow: hidden;
  background: #fff;
  &__tag {
    flex: 1;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    background: linear-gradient(90deg, rgba(244, 213, 114, 0.34) 24.53%, rgba(244, 213, 114, 0.00) 100%);
  }
  &__tag-title {
    color: #FCDF9D;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0.0533rem 0.2799rem 0.0533rem 0.1067rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    // width: 1.97rem;
    flex-shrink: 0;
    box-sizing: border-box;
    .font-dpr(24px);
    .margin-r(0.1067rem);
  }
  &__tag-item {
    color: #000;
    .font-dpr(22px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    .sui-icon-common__wrap {
      margin-right: 0.0533rem;
      /* stylelint-disable-next-line declaration-no-important */
      line-height: 1!important;
    }
  }
  .mar-left-ten {
    .margin-l(0.16rem);
  }
  &__tag-more {
    .margin-l(0.2133rem);
    [class*='iconfont'] {
     color: #959595;
    }
  }
}
.product-selection_new{
  margin-top: 0.21rem;
  margin-bottom: 0;
  padding: 0 0.08rem;
  height: auto;
  border-radius: 0.05333rem;
  background: linear-gradient(90deg, #FFEED5 0.21%, #FFF6E8 24.17%, #FFFAF2 99.81%);
  height: 0.61333rem;
  .product-selection{
    &__tag{
      background: none;
    }
    &__tag-title {
      display: flex;
      align-items: center;
      padding: 0 0.16rem 0 0.10667rem;
      height: 0.45333rem;
      border-radius: 0.05333rem;
      background: linear-gradient(90deg, #000 0.02%, #505050 99.98%);
      box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.12) inset;
      .margin-r(0.21rem);
    }
    &__tag-text{
      margin-left: 0.05333rem;
      color: #FFFAEE;
      font-size: 0.29333rem;
      font-weight: 600;
    }
    &__tag-item{
      color: #745441;
    }
  }
}
</style>
