<!--曝光埋点  品牌系列：j-sa-brand-view-products 店铺：j-sa-brand-store-view-products-->
<template>
  <div>
    <div
      v-ada="{ level: 1, pos: [0] }"
      class="mgds-store"
      :class="[`j-sa-brand-store-view-products`]"
      :aria-label="data.name"
      :data-name="data.name"
      :data-brand_code="data.id"
      :data-goods_id="detail.goods_id"
      :data-brand_type="data.type"
      :data-designer_id="data.designerId"
      :data-sc_id="data.scId"
      :data-abt_ana_info="extAnalysisInfo.abtAnaInfo"
      :data-brand_info="extAnalysisInfo.brandInfo"
      :data-brand_label="extAnalysisInfo.brandLabel"
      :data-button_label="extAnalysisInfo.buttonLabel"
      :data-store_popularity="extAnalysisInfo.storePopularity"
      :data-flash_sale_stype="flashEntryMap[flashEntryType] || 0"
      role="text"
      @click="viewProducts()"
    >
      <div
        v-ada="{ level: 1, pos: [0] }"
        :class="[
          'mgds-store__detail',
          'center'
        ]"
        :aria-label="data.name"
        role="text"
        aria-hidden="true"
      >
        <div 
          v-if="data.logo"
          :class="[
            'mgds-store__detail-logo',
            'large-size',
            isPictureMidLarge&&'mid-large-size'
          ]"
        >
          <img :src="data.logo" />
        </div>

        <!-- 中部店铺信息块 -->
        <div class="mgds-store__detail-info">
          <!-- 标题 -->
          <div class="detail-flex-box">
            <div
              :class="{
                'mgds-store__detail-info-title': true,
                'mgds-store__detail-info-store-sign': isSingleRowDisplay,
              }"
            >
              <div class="text">
                <span>{{ data.name }}</span>
              </div>
              <div
                v-if="!storeFollowIsOne"
                class="store-label"
              >
                <div
                  v-if="isInFashionStore"
                  v-expose="{id: '1-6-4-75'}"
                  class="store-label__fashion"
                >
                  <img
                    v-if="inFashionStoreImg"
                    :src="inFashionStoreImg"
                  />
                  <span
                    v-else
                    class="store-label__fashion-default"
                  >
                    {{ language.SHEIN_KEY_PWA_30166 || 'Trends' }}
                    <img
                      class="store-label__fashion-default-icon"
                      :src="`${PUBLIC_CDN}/pwa_dist/images/store/infashion_label/default-fd2954326c.png`"
                    />
                  </span>
                </div>
                <div
                  v-if="isQualityStore"
                  class="store-label__choices"
                >
                  <img src="https://shein.ltwebstatic.com/svgicons/2024/07/09/17205135461310578037.svg"
                  />
                </div>
                <div class="store-label__brand">
                  <img
                    :src="`${PUBLIC_CDN}/pwa_dist/images/ccc/branchBannner-icon-52850ee628.svg`"
                    class="branchIcon"
                  />
                  <span class="barnd-text">{{ language.SHEIN_KEY_PWA_24319 }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- 评分等信息 -->
          <!-- 独立一块展示的店铺关注信息，对应新样式 -->
          <div 
            v-if="showStoreSign" 
            class="mgds-store__follow-info"
          > 
            <div
              v-if="storeFollowIsOne"
              class="store-label"
            >
              <div
                v-if="isInFashionStore"
                v-expose="{id: '1-6-4-75'}"
                class="store-label__fashion"
              >
                <img
                  v-if="inFashionStoreImg"
                  :src="inFashionStoreImg"
                />
                <span
                  v-else
                  class="store-label__fashion-default"
                >
                  {{ language.SHEIN_KEY_PWA_30166 || 'Trends' }}
                  <img
                    class="store-label__fashion-default-icon"
                    :src="`${PUBLIC_CDN}/pwa_dist/images/store/infashion_label/default-fd2954326c.png`"
                  />
                </span>
              </div>
              <div
                v-if="isQualityStore"
                class="store-label__choices"
              >
                  <img src="https://shein.ltwebstatic.com/svgicons/2024/07/09/17205135461310578037.svg"
                  />
              </div>
              <div class="store-label__brand">
                <img
                  :src="`${PUBLIC_CDN}/pwa_dist/images/ccc/branchBannner-icon-52850ee628.svg`"
                  class="branchIcon"
                />
                <span class="barnd-text">{{ language.SHEIN_KEY_PWA_24319 }}</span>
              </div>
            </div>
            <StoreFollowInfo
              v-if="storeFollowInfo && storeFollowInfo.show && storehotnewsType !== 'showscroll'"
              :store-follow-info="storeFollowInfo"
              :store-rate="shopRank"
              :language="language"
              type="inline-store"
            />
            <!-- 店铺人气氛围&店铺评分信息滚动显示 -->
            <StoreAtmosphereSwiper
              v-if="storehotnewsType === 'showscroll'"
              :hasFollowInfo="storeFollowInfo && storeFollowInfo.show"
              position="showscroll"
            >
              <template #followInfo>
                <StoreFollowInfo
                  :store-follow-info="storeFollowInfo"
                  :store-rate="shopRank"
                  :language="language"
                  type="inline-store"
                />
              </template>
            </StoreAtmosphereSwiper>
          </div>
        </div>
      </div>

      <div
        v-if="data.link"
        aria-hidden="true"
        class="mgds-store__view-products"
      >
        <template v-if="!data.desc && data.desc_bak && !showStoreSign">
          {{ data.desc_bak }}
        </template>
        <i :class="viewMoreIconClass"></i>
      </div>
    </div>
    <StoreFollowInfo
      v-if="storeFollowInfo&&!showStoreSign"
      :store-follow-info="storeFollowInfo"
      :store-rate="shopRank"
      :language="language"
      type="block"
      style="margin-top:0"
    />
    <div
      v-if="data.link&&showStoreSign&&!data.desc && data.desc_bak"
      aria-hidden="true"
      class="mgds-store__brand-desc"
    >
      {{ data.desc_bak }}
    </div>
    <div
      v-else-if="descriptionsShow"
      aria-hidden="true"
      class="mgds-store__brand-desc"
    >
      {{ data.desc }}
    </div>

    <div
      v-if="storeFollowRelativeShow"
      class="store-btn-container" 
    >
      <!-- 底部关注按钮块 -->
      <StoreFollowBtnBox
        scene="ProductDetail"
        class-name="follow-btn-box-new"
        :store-id="storeId"
        :follow-state="storeFollowState"
        :auto-login="true"
        :language="language"
        :showLoginModal="showLoginModal"
        @state-changed="handleFollowStateChange"
        @state-changing="handleFollowStateChanging"
        @state-change-fail="handleFollowStateChangeFail"
      >
        <template slot="button">
          <div
            v-expose="{ id: '1-6-4-63', data: followBtnAnalysisData }"
            :class="{
              'follow-btn': true,
              'show-symbol': followBtnShowSymbol,
              'followed': storeFollowState
            }"
            @click="followBtnClick"
          >
            <FollowStoreIcon />
            <b class="whitespace"></b>
            <span>{{ storeFollowBtnText || 'Follow' }}</span>
          </div>
        </template>
      </StoreFollowBtnBox>
      <!-- 底部跳转按钮 -->
      <div
        class="follow-btn-box-new follow-btn-allitems"
        aria-hidden="true"
        @click="viewProducts(flashEntryType === 'showflashsale' || flashEntryType === 'showflashbutton' ? 'promotion' : 'items')"
      >
        <div
          v-if="flashEntryType === 'showflashsale'"
          class="store_flash-sale"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.66039 2.5H5.30039L2.90039 6.9H5.78039L5.06039 12.1L10.1004 4.9H7.22039L8.66039 2.5Z" fill="#161616"/>
          </svg>
          <span>{{ language.SHEIN_KEY_PWA_30008 || 'Flash Sale' }}</span>
        </div>
        <span
          v-else-if="showNewArrival"
          class="store_new-arrival"
        >{{ language.SHEIN_KEY_PWA_30036 || 'NEW' }}</span>

        <div 
          v-if="flashEntryType === 'showflashbutton'"
          class="follow-btn with-flash-icon"
        >
          <img
            class="flash-gif-icon"
            :src="`${PUBLIC_CDN}/pwa_dist/images/flash-sale/flash-gif-097c8939e4.gif`"
          />
          <div
            ref="flashScrollBox"
            class="flash-scroll-wrap"
            :class="{
              'has-animation': hasAnimation
            }"
          >
            <span 
              ref="flashScrollContent"
              class="flash-scroll-item"
              :class="{
                'has-animation': hasAnimation
              }"
              :style="{'animation-duration': millisecond }"
            >
              {{ language.SHEIN_KEY_PWA_31988 || 'Store Flash Sale' }}
            </span>
          </div>
        </div>
        <div 
          v-else 
          class="follow-btn"
        >
          {{ language.SHEIN_KEY_PWA_26175 || 'All Items' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import moreMixin from '../mixins/more'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { stringifyQueryString } from '@shein/common-function'
import StoreFollowInfo from 'public/src/pages/goods_detail/ShopEntry/StoreFollowInfo'
import StoreFollowBtnBox from 'public/src/pages/goods_detail/ShopEntry/StoreFollowBtnBox'
import FollowStoreIcon from 'public/src/pages/goods_detail/ShopEntry/FollowStoreIcon'
import StoreAtmosphereSwiper from './StoreAtmosphereSwiper'
import { getLocalStorage, setLocalStorage, setSessionStorage } from '@shein/common-function'
import { getCategoryName } from 'public/src/pages/goods_detail/utils/productDetail.js'
import storePageDataManager from 'public/src/pages/store_pages/js/storePageDataManager.js'
import { markPoint } from 'public/src/services/mark/index.js'

const { IS_RW, PUBLIC_CDN } = gbCommonInfo

export default {
  name: 'StoreEnter',
  components: {
    StoreFollowInfo,
    StoreFollowBtnBox,
    FollowStoreIcon,
    StoreAtmosphereSwiper,
  },
  mixins: [moreMixin],
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    analysis: {
      type: Object,
      default() {
        return {}
      }
    },
    // 跳转商品列表时带上adp参数
    setAdpParams: {
      type: Boolean,
    },
    flashEntryType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      IS_RW,
      PUBLIC_CDN,
    }
  },
  computed: {
    ...mapState('productDetail', [
      // 'recommendAdp',
      // 'detail',
      // 'storeFollowInfo',
      // 'storeAndBrandDataReady',
      // 'abtConfigs',
    ]),
    // ...mapState('newProductDetail/common', [
    //   'storeFollowInfo',
    //   // 'storeAndBrandDataReady',
    // ]),
    ...mapGetters('newProductDetail', ['fsAbt']),
    ...mapGetters('newProductDetail/common', [
      'language',
      'detail',
      'parentCats',
      'storeFollowInfo',
      'storeRecommendInfo',
      'showStoreNewArrival',
      'isInFashionStore',
      'inFashionStoreImg',
      // 是否是品质店铺
      'isQualityStore'
    ]),
    ...mapGetters('newProductDetail/Middle', [
      'fsComments',
      'labelList'
    ]),
    ...mapGetters('newProductDetail/Signboard', ['storehotnewsType', 'storeAtmosphereInfo']),
    recommendAdp() {
      return this.storeRecommendInfo?.recommendAdp || ''
    },
    shopRank() {
      return this.fsComments.shopRank
    },
    storeFollowIsOne () {
      const arr = [this.storeFollowInfo?.followCount, this.storeFollowInfo?.salesVolume, Number(this.shopRank)].filter(item=>item && item !== '0')
      return arr.length === 1
    },
    // 展示店铺新样式，新交互
    showStoreSign(){
      if (this.IS_RW) return this.fsAbt?.storejump?.param?.storesign === 'new' 
      return true
    },
    inlineType(){
      return this.showStoreSign ? 'inline-store' : 'inline'
    },
    formatShopScore() {
      return (+this.shopRank).toFixed(2)
    },
    isPictureMidLarge(){                                                       
      if(this.showStoreSign && (this.storeInfoOnlyOne || !this.isInFashionStore)){
        return true
      }
      return false
    },
    // 店铺id
    storeId() {
      const { detail } = this
      return detail?.store_code || ''
    },
    // 店铺关注信息是否展示
    storeFollowRelativeShow() {
      const { data, storeFollowInfo } = this
      if (!data || !storeFollowInfo) {
        return false
      }
      return storeFollowInfo.show 
    },
    // 用户店铺关注状态
    storeFollowState() {
      const { storeFollowRelativeShow, storeFollowInfo } = this
      if (!storeFollowRelativeShow) {
        // TODO-remove abt全量后删除此逻辑
        return false
      }
      return storeFollowInfo?.userFollowState === 1
    },
    // 店铺关注按钮是否展示+符号
    followBtnShowSymbol() {
      const { storeFollowInfo } = this
      const { userFollowState, followStateChanging } = storeFollowInfo || {}
      return !followStateChanging && userFollowState !== 1
    },
    // 店铺关注按钮文案
    storeFollowBtnText() {
      const { storeFollowInfo, language } = this
      const { followStateChanging, userFollowState } = storeFollowInfo || {}
      if (followStateChanging) return '...'
      if (userFollowState === -1) {
        return language?.SHEIN_KEY_PWA_24068
      }
      return userFollowState === 1
        ? language?.SHEIN_KEY_PWA_24072
        : language?.SHEIN_KEY_PWA_24068
    },
    viewMoreIconClass() {
      const { IS_RW } = this
      return [
        'suiiconfont',
        IS_RW
          ? 'sui_icon_more_right_16px'
          : 'sui_icon_more_right2_16px'
      ]
    },
    // 额外埋点信息
    extAnalysisInfo() {
      const { storeFollowInfo, shopRank, showNewArrival, storeAtmosphereInfo } = this
      const { salesVolume, followCount } = storeFollowInfo || {}
      const brandInfo = [
        `ratings_${shopRank}`,
        (salesVolume && salesVolume !== '0')
          ? `sold_in_30d_${salesVolume}`
          : '-',
        (followCount && followCount !== '0')
          ? `followers_${followCount}`
          : '-'
      ]
      const buttonLabel = showNewArrival ? 'icon_new' : '-'
      // 店铺人气氛围
      let storePopularity = ''
      if(storeAtmosphereInfo.length){
        const popTypes = new Set()
        storeAtmosphereInfo.forEach(e => {
          if (e.popType) {
            popTypes.add(e.popType)
          }
          storePopularity = Array.from(popTypes).join('\'')
        })
      }else {
        storePopularity = '0'
      }
      const info = {
        abtAnaInfo: storeFollowInfo?.abtAnaInfo || '',
        brandInfo: brandInfo.join('`') || '',
        // 此入口不存在本地卖家和优质卖家标签
        brandLabel: '',
        buttonLabel,
        storePopularity,
      }
      this.$emit('handleStoreBrandAnalysis', info)
      return info
    },
    // 店铺关注按钮埋点信息
    followBtnAnalysisData() {
      const { storeFollowInfo, storeId } = this
      const { userFollowState } = storeFollowInfo || {}
      return {
        action_tp: userFollowState === 1 ? 'followed' : 'unfollowed',
        brand_code: storeId,
        brand_type: 'store',
      }
    },
    // allitems按钮是否显示上新标签
    showNewArrival() {
      return this.showStoreNewArrival === 'allitems'
    },
    descriptionsShow() {
      const { data, labelList } = this
      if (!data?.desc || IS_RW) return false
      const hasLabel = labelList?.some(d => d.labelCode === 'Show_Description')
      if (!hasLabel) return false
      return true
    },
    // 单行展示
    isSingleRowDisplay(){
      //  命中新样式，并且没有额外的标签展示时，品牌标签与标题一行展示
      return this.showStoreSign && !this.isInFashionStore && !this.isQualityStore
    }
  },
  methods: {
    ...mapMutations('productDetail', ['assignState']),
    ...mapMutations('newProductDetail/common', ['updateStoreFollowInfo']),
    ...mapActions('newProductDetail', ['showLoginModal']),
    // ...mapActions('productDetail', ['updateStoreFollowInfo']),
    viewProducts(tabId) {
      const { type, id, link, name, designerId } = this.data
      const { shopRank, analysis, showNewArrival } = this
      const { detail } = this

      if (!link) return
      const src_identifier = [
        `on=${type}`,
        `cn=${name}`,
        `hz=0`,
        `ps=1_1`,
        `jc=${
          designerId
            ? `sheinxDesignerInformation_${designerId}`
            : `thirdPartyStoreHome_${id}`
        }`
      ].join('`')
      // 新店招交互
      if(this.showStoreSign) {
        markPoint('toNextPageClick', 'public')
        const params = {
          src_module: analysis?.src_module,
          src_identifier: src_identifier,
          src_tab_page_id: getSaPageInfo.tab_page_id || '',
          main_goods_id: detail.goods_id || '',
          main_cat_id: detail.cat_id || '',
        }

        // 新店招交互点击allitems上报
        this.clickStoreReports({ tabId, type, id, src_identifier })
        if(!tabId){  // 不是allitems 列表的入口点击
          // 往店铺塞数据
          const detailToStoreData = {
            goods_id: detail.goods_id,
            goods_img: detail.goods_thumb || detail.goods_img,
            cat_id: detail.cat_id,
            cat_name: getCategoryName(this.parentCats)
          }
          setSessionStorage({ key: 'detailToStoreData', value: detailToStoreData })
        }
        if(showNewArrival && tabId) {
          params.sort = '9'
          this.setNewArrivalStorage(id)
        }

        const { langPath } = gbCommonInfo
       
        const queryObj = {
          adp: this.detail.goods_id,
          store_code: this.data.storeCode,
          ...params,
        }
        if (tabId) {
          queryObj.tabId = tabId
          queryObj.isStoreTabSticky = '1'
        }
        const url = `${langPath}/store/home?${stringifyQueryString({
          queryObj
        })}`
        storePageDataManager.prerenderRequest({
          fullPath: url,
          query: queryObj,
        })
        this.$router.push(url)
        return 
      }

      // 点击跳转店铺上报
      this.clickStoreReports({ tabId, type, id, src_identifier })
      
      let sort = ''
      if(showNewArrival && tabId) {
        sort = 9
        this.setNewArrivalStorage(id)
      }

      this.boardMoreHandle({
        isStoreTabSticky: tabId ? '1' : '',
        sort,
        tabId,
        type,
        id,
        link,
        shopRank,
        goods_id: detail.goods_id,
        cat_id: detail.cat_id,
        adp: !this.setAdpParams ? '' : this.recommendAdp,
        analysis: { src_identifier, src_module: analysis?.src_module }
      })
    },
    setNewArrivalStorage(id) {
      const storageKey = `storeAllitems_hide_${id}`
      let val = getLocalStorage(storageKey)
      if(!val){
        // 上新标签缓存3天
        setLocalStorage({ key: storageKey, value: true, expire: 1000 * 60 * 60 * 24 * 3 })
      }
    },
    clickStoreReports({ tabId, type, id, src_identifier }) {
      const { brandInfo, brandLabel, buttonLabel } = this.extAnalysisInfo
      const flash_sale_stype = this.flashEntryType == 'showflashsale' ? 2 : (this.flashEntryType == 'showflashbutton' ? 3 : 0)
      daEventCenter.triggerNotice({
        daId: tabId ? '1-6-1-194' : '1-6-4-17',
        extraData: {
          brand_type: type,
          brand_code: id,
          // abtest: abtAnaInfo,
          brand_info: brandInfo,
          brand_label: brandLabel,
          button_label: tabId ? buttonLabel : '',
          page_nm: 'page_goods_detail',
          src_identifier,
          flash_sale_stype: tabId ? flash_sale_stype : undefined
        }
      })
    },
    // 关注按钮点击
    // * 此处只处理埋点事件，关注状态的切换冒泡至 StoreFollowBtnBox 组件进行处理
    followBtnClick() {
      const { followBtnAnalysisData, storeFollowInfo } = this
      const { userFollowState } = storeFollowInfo || {}
      const notLogin = (typeof SHEIN_COMMON !== 'undefined')
        && (SHEIN_COMMON?.isLogin?.() === false)
      daEventCenter.triggerNotice({
        daId: '1-6-4-64',
        extraData: {
          ...followBtnAnalysisData,
          action_tp: notLogin
            ? 'follow'
            : !userFollowState ? 'follow' : 'cancel',
        },
      })
    },
    // 更改关注状态成功
    handleFollowStateChange(targetState) {
      const {
        storeFollowInfo,
        followBtnAnalysisData,
      } = this
      const { followCount } = storeFollowInfo || {}
      const userFollowState = targetState ? 1 : 0
      let updateCount = followCount
      if (+followCount) {
        updateCount = userFollowState == 1 ? +followCount + 1 :  +followCount - 1
      }
      // if (storeFollowInfo?.followStateChanging) return
      this.updateStoreFollowInfo({
        ...storeFollowInfo,
        userFollowState,
        followCount: updateCount + '',
        // followCount,
      })
      // this.assignState({
      //   storeFollowInfo: {
      //     ...(storeFollowInfo || {}),
      //     userFollowState: targetState ? 1 : 0,
      //   }
      // })
      // this.$nextTick(() => {
      //   updateStoreFollowInfo?.()
      // })
      // 埋点
      daEventCenter.triggerNotice({
        daId: '1-6-4-65',
        extraData: {
          ...followBtnAnalysisData,
          result: 1,
          reason_tp: '-',
          action_tp: targetState ? 'follow' : 'cancel',
        },
      })
    },
    // 变更关注状态失败
    handleFollowStateChangeFail(err) {
      const { followBtnAnalysisData, storeFollowInfo } = this
      const { userFollowState } = storeFollowInfo || {}
      // 埋点
      daEventCenter.triggerNotice({
        daId: '1-6-4-65',
        extraData: {
          ...followBtnAnalysisData,
          result: 0,
          reason_tp: err?.code || '-1',
          action_tp: !userFollowState ? 'follow' : 'cancel',
        },
      })
    },
    // 关注状态变更中
    handleFollowStateChanging(state) {
      const { storeFollowInfo } = this
      this.updateStoreFollowInfo({
        ...storeFollowInfo,
        followStateChanging: state,
      })
      // this.assignState({
      //   storeFollowInfo: {
      //     ...(storeFollowInfo || {}),
      //     followStateChanging: state,
      //   }
      // })
    }
  },
}
</script>

<style lang="less">
/* stylelint-disable selector-max-specificity, selector-class-pattern */
.mgds-store {
  padding: 0.267rem 0.32rem;
  .flexbox();
  .space-between();
  .align-center();
  background: #fff;
  &__detail {
    .flexbox();
    width: 100%;
    overflow: hidden;
    &.center {
      .align-center();
    }
  }
  &__brand-desc{
    overflow: hidden;
    color: #666;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding:2px 0.267rem 7px;
    line-height: normal;
  }
  &__detail-logo {
    .margin-r(16/75rem);
    width: 1.067rem;
    height: 1.067rem;
    .border-dpr(border, 1px, #e5e5e5);
    .flexbox();
    .align-center();
    flex-shrink: 0;
    &.large-size {
      width: 1.386667rem;
      height: 1.386667rem;
    }
    &.mid-large-size{
      width: 1.226666rem;
      height: 1.226666rem;
    }
    > img {
      width: 100%;
    }
  }
  &__detail-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    flex-grow: 1;

    .detail-flex-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__detail-info-title {
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
    width: 0;
    &.inline {
      display: inline-flex;
    }

    .text {
      .flexbox();
      .font-dpr(28px);
      color: #000;
      font-weight: 700;
      line-height: 17px;
      overflow: hidden;
      span, i {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  &__detail-info-store-sign{
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    // .store-label{
    //   margin-top: 0 !important;
    // }
  }
  &__follow-info{
    display: flex;
    gap: 4px;
    margin-top: .106667rem;
  }
  .store-label {
    display: inline-flex;
    align-items: center;
    // margin-top: .106667rem;
    &__fashion, &__choices{
      display: flex;
      align-items: center;
      margin-right: 8/75rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__fashion-default{
      height: 14px;
      line-height: 14px;
      position: relative;
      display: inline-block;
      padding: 0 5px 0 2px;
      font-size: 11px;
      font-style: italic;
      font-weight: 858;
      color: #fff;
      z-index: 1;
    }
    &__fashion-default-icon{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    &__brand{
      display: inline-flex;
      align-items: center;
      .border-dpr(border, 0.5px, #BCC8D3);
      .font-dpr(20px);
      height: 32/75rem;
      align-items: center;
      box-sizing: border-box;
      background: linear-gradient(128.64deg, #E2EDF3 7.72%, #F0F8FF 34.23%, #DAE7F6 98.52%);
      border-radius: 4/75rem;
      color: #2D3540;
      padding: 3/75rem 4/75rem;
      align-items: center;
      font-weight: 500;
    }

    &.inline {
      .margin-l(0.16rem);
      margin-top: 0;
    }

    .barnd-text {
      display: inline-block;
      height: 24/75rem;
      line-height: 24/75rem;
    }
    .branchIcon {
      width: 24/75rem;
      margin-right: 4/75rem;
    }
  }

  &__detail-info-footer {
    display: inline-flex;
    margin-top: 0.106667rem; //0.1733rem;
    .item {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #666;
      .margin-r(0.16rem);
      em {
        color: #000
      }
    }
    .split-line {
      .margin-r(0.16rem);
      font-size: 12px;
      line-height: 14px;
      color: #e5e5e5;
    }
  }
  &__view-products {
    padding: 0.3rem 0;
    white-space: nowrap;
    color: #999;
    > i {
      color: #999;
      .margin-l(0.12rem);
      .font-dpr(24px);
    }
  }
}
.store-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.267rem 0.32rem;
  /* stylelint-disable-next-line */
  .follow-btn-box-new {
    .follow-btn {
      position: relative;
      display: flex;
      width: 4.4267rem;
      padding: 0.2133rem;
      justify-content: center;
      align-items: center;
      font-size: 0.3733rem;
      line-height: 0.3733rem;
      color: @sui_color_gray_dark1;
      /* stylelint-disable-next-line */
      &.show-symbol {
        /* stylelint-disable-next-line */
        svg, .whitespace {
          display: inline-block;
        }
      }
      /* stylelint-disable-next-line */
      &.followed {
        /* stylelint-disable-next-line */
        color: @sui_color_gray_light1;
        border-color: @sui_color_gray_weak1;
      }
      &.with-flash-icon{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .flash-scroll-wrap{
          overflow: hidden;
          white-space: nowrap;
          vertical-align: top;
          &.has-animation{
            position: relative;
            flex: 1;
            height: 0.3733rem
          }
        }
        .flash-scroll-item {
          display: inline-block;
          &.has-animation{
            position: absolute;
            left: 0;
            animation: flashScroll linear 4s alternate infinite;
          }
        }
        @keyframes flashScroll {
          0% {
            left: 0;
            transform: translateX(0);
          }
          35% {
              left: 0;
              transform: translateX(0);
          }
        
          65% {
              left: 100%;
              transform: translateX(-100%);
          }
          100% {
            left: 100%;
            transform: translateX(-100%);
          }
        }
      }

      .flash-gif-icon{
        width: 0.48rem;
        height: 0.48rem;
        margin-right: 0.1067rem;
        flex: 0 0 auto;
      }
      span {
        display: inline-block;
      }
      /* stylelint-disable-next-line */
      svg, .whitespace {
        display: none;
      }
      /* stylelint-disable-next-line */
      .whitespace {
        width: .106667rem;
        height: .106667rem;
      }
    }
    .follow-btn:before {
      // 0.5px border 伪类
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 200%;
      border: 1px solid var(---sui_color_gray_dark2, #666);
      border-radius: 0.8267rem;
      transform: scale(0.5);
      transform-origin: left top;
    }
  }
  .follow-btn-allitems{
    position: relative;
    .store_flash-sale{
      position: absolute;
      display: flex;
      align-items: center;
      padding: 0 0.1067rem 0 0.08rem;
      height: 0.347rem;
      position: absolute;
      right: -0.05333rem;
      top: -0.16rem;
      font-size: 0.24rem;
      color: #000;
      z-index: 1;
      background-image: url('/pwa_dist/images/flash-sale/scan_flash-6a3950325b.gif');
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .store_new-arrival{
      height: 0.37333rem;
      line-height: 0.37333rem;
      padding: 0 0.05333rem;
      position: absolute;
      right: -0.05333rem;
      top: -0.16rem;
      border-radius: 0.08rem 0.08rem 0.08rem 0;
      border: 1px solid #FFF;
      background: #3CBD45;
      font-size: 0.26667rem;
      color: #fff;
      z-index: 1;
    }
  }
}
</style>
