<!--曝光埋点  品牌系列：j-sa-brand-view-products 店铺：j-sa-brand-store-view-products-->
<template>
  <div
    :class="{
      'brand-outer-box': true,
      'brand-type': isStoreBrand,
      'brand-type_new': isNewStoreBrand,
      'follow-have-list': isStore && hasRecommendList,
      'local-type': isStore
    }"
  >
    <div
      v-ada="{ level: 1, pos: [0] }"
      class="mgds-brand"
      :class="{
        'j-sa-brand-store local-shop': isStore,
        'j-sa-brand-store-view-products': isStore,
        'j-sa-brand-view-products': !isStore,
        'mgds-storebrand': isStoreBrand,
        'mgds-storebrand_new': isNewStoreBrand
      }"
      :aria-label="data.name"
      :data-name="data.name"
      :data-brand_code="data.id"
      :data-goods_id="detail.goods_id"
      :data-brand_type="data.type"
      :data-designer_id="data.designerId"
      :data-sc_id="data.scId"
      :data-abt_ana_info="extAnalysisInfo.abtAnaInfo"
      :data-brand_info="extAnalysisInfo.brandInfo"
      :data-brand_label="extAnalysisInfo.brandLabel"
      :data-button_label="extAnalysisInfo.buttonLabel"
      :data-store_popularity="extAnalysisInfo.storePopularity"
      :data-flash_sale_stype="flashEntryMap[flashEntryType] || 0"
      :data-is_alone_store_brand="isNewStoreBrand || isStoreBrand"
      role="text"
      @click="viewProducts()"
    >
      <!-- 主内容 -->
      <div
        v-ada="{ level: 1, pos: [0] }"
        :class="['mgds-brand__detail', { center: !descriptionsShow }]"
        :aria-label="data.name"
        role="text"
        aria-hidden="true"
      >
        <div
          v-if="showBrandLogo"
          :class="{
            'mgds-brand__detail-logo': true,
            'mid-size': !storeRelativeInNewStyle && !isPictureMidLarge,
            'large-size': storeRelativeInNewStyle && !isPictureMidLarge,
            'mid-large-size': isPictureMidLarge,
          }"
        >
          <img
            :class="{
              'mid-size': !storeRelativeInNewStyle && !isPictureMidLarge,
              'large-size': storeRelativeInNewStyle && !isPictureMidLarge,
              'mid-large-size': isPictureMidLarge,
            }"
            :src="data.logo"
          />
        </div>
        <div class="mgds-brand__detail-info">
          <div class="mgds-brand__detail-info-title">
            <div class="text">
              <span class="text_first">{{ data.name }}</span>
              <!-- 品牌集成店铺 -->
              <template v-if="isNewStoreBrand">
                <div class="store-brand_tip split-left">
                  <img
                    :src="`${PUBLIC_CDN}/pwa_dist/images/store/brand_tips_icon-bd0d0de393.svg`"
                  />
                </div>
              </template>
              <!-- 品牌或系列的标签 -->
              <BrandSeriesTips
                v-else-if="tipsData.show && tipsData.type !== 'store'"
                class="split-left"
                :tips-data="tipsData"
              />
              <!-- 店铺的标签 小于等于一个标签并且 storeInfoOnlyOne为 false 走这个样式 -->
              <BrandSeriesTips
                v-if="tipsData.show && tipsData.type == 'store'&& tipsDataLessThanOne && !storeInfoOnlyOne"
                class="split-top"
                :tips-data="tipsData"
                :language="language"
                :in-fashion-store-img="inFashionStoreImg"
              />
              <!-- 展示100%正品标签 -->
              <div
                v-if="isAbtAuthenticbrandShow"
                v-expose="{ id: '1-6-4-74', data: authenticbrandShowAnalysisData }"
                class="storebrand__authentic"
              >
                {{ language.SHEIN_KEY_PWA_26778 }}
              </div>
            </div>
            <div class="mgds-brand__tip-container">
              <!-- 店铺的标签 大于1个标签或者不需要展示新样式 走这个样式 -->
              <BrandSeriesTips
                v-if="tipsData.show && tipsData.type == 'store'&& (!tipsDataLessThanOne||!showStoreSign)"
                class="split-top"
                :tips-data="tipsData"
                :language="language"
                :in-fashion-store-img="inFashionStoreImg"
                :isAllLabelsDisplayed="!storeInfoOnlyOne"
              >
                <div
                  v-if="storeInfoOnlyOne"
                  slot="extend-content"
                  class="store-info-inline split-left"
                >
                  <span class="key">{{ storeInfoOnlyOne[2] }}:&nbsp;</span>
                  <span class="value">{{ storeInfoOnlyOne[1] }}</span>
                </div>
              </BrandSeriesTips>
              <!-- TODO 单独一行的标签展示，abt全量后优化 -->
              <div
                v-if="!tipsData.show && tipsData.type == 'store' && storeInfoOnlyOne"
                slot="extend-content"
                class="store-info-inline split-top"
              >
                <span class="key">{{ storeInfoOnlyOne[2] }}:&nbsp;</span>
                <span class="value">{{ storeInfoOnlyOne[1] }}</span>
              </div>
              <StoreFollowInfo
                v-if="storeFollowInfo && storeFollowInfo.show && showStoreSign && !storeInfoOnlyOne && storehotnewsType !== 'showscroll'"
                :store-follow-info="storeFollowInfo"
                :store-rate="shopRank"
                :language="language"
                type="inline-store"
              />
              <!-- 店铺人气氛围&店铺评分信息滚动显示 -->
              <StoreAtmosphereSwiper
                v-if="storehotnewsType === 'showscroll' && showStoreSign"
                :hasFollowInfo="storeFollowInfo && storeFollowInfo.show && showStoreSign&&!storeInfoOnlyOne"
                position="showscroll"
              >
                <template #followInfo>
                  <StoreFollowInfo
                    :store-follow-info="storeFollowInfo"
                    :store-rate="shopRank"
                    :language="language"
                    type="inline-store"
                  />
                </template>
              </StoreAtmosphereSwiper>
            </div>
          </div>

          <!-- 描述信息在logo右侧 -->
          <template v-if="descriptionsShow && !isNewStoreBrand && !showStoreSign">
            <!-- 
            当前模块公用 
            if 品牌、系列模块 
            else if 店铺模块新样式
            else if 店铺独立品牌模块
            -->
            <div 
              v-if="!isStore && !isStoreBrand"
              class="desc-brand"
            >
              {{ data.desc }}
            </div>
            <div
              v-else-if="storeRelativeInNewStyle"
              class="desc-new"
              style="margin-top: 4px;"
            >
              {{ data.desc }}
            </div>
            <div
              v-else-if="isStoreBrand"
              class="storebrand-desc-wrap"
            >
              <div class="storebrand-desc-wrap__content">
                {{ data.desc }}
              </div>
              <!-- 右侧跳转箭头 -->
              <div
                v-if="data.link"
                aria-hidden="true"
                class="mgds-brand__view-products"
              >
                <i :class="viewMoreIconClass"></i>
              </div>
            </div>
          </template>

          <!-- 评分和产品数量块 -->
          <!-- TODO-remove 对应230620新样式，abt全量后移除 -->
          <div
            v-if="
              !(storeFollowRelativeShow && shouldStoreRelativeShow && !isStoreBrand) &&
                shouldStoreRelativeShow
            "
            class="mgds-brand__detail-info-footer"
          >
            <div
              v-if="data.totals"
              class="item split"
            >
              {{ language.SHEIN_KEY_PWA_20745 }}: {{ data.totals }}
            </div>
            <div
              v-if="shopRank"
              class="item"
            >
              {{ language.SHEIN_KEY_PWA_20750 }}: {{ formatShopScore }}
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧跳转箭头 -->
      <div
        v-if="data.link"
        aria-hidden="true"
        class="mgds-brand__view-products"
        :class="{
          hidden: descriptionsShow && isStoreBrand
        }"
      >
        <template v-if="!hideJumpText && !data.desc && data.desc_bak">
          {{ data.desc_bak }}
        </template>
        <i :class="viewMoreIconClass"></i>
      </div>
    </div>

    <!-- 独立一块展示的店铺关注信息 -->
    <!-- HACK 信息块中仅有一项时不展示 -->
    <StoreFollowInfo
      v-if="
        storeFollowRelativeShow && shouldStoreRelativeShow && !isStoreBrand && !storeInfoOnlyOne && !showStoreSign
      "
      :store-follow-info="storeFollowInfo"
      :store-rate="shopRank"
      :language="language"
    />
    <!-- 描述信息在logo底部-->
    <template v-if="descriptionsShow">
      <div 
        v-if="showStoreSign"
        class="desc-new"
        :style="{'margin-top': showReviewMargin? '13px': '15px'}"
      >
        {{ data.desc }}
      </div>
      <div
        v-else-if="isNewStoreBrand"
        :class="[
          'storebrand__descriptions',
          {
            ellipsis: !storeBrandDescExpend
          }
        ]"
        @click="storeBrandDescExpend = true"
      >
        {{ data.desc }}
      </div>
      <div
        v-else-if="!storeRelativeInNewStyle && isStore"
        class="mgds-brand__detail-info-descriptions"
      >
        {{ data.desc }}
      </div>
    </template>
    <div
      v-if="showBrandsZone"
      class="storebrand__zone"
      @click="goToBrandZone"
    >
      <span class="storebrand__zone-title">{{ language.SHEIN_KEY_PWA_27388 }}</span>
      <span class="storebrand__zone-desc">{{ language.SHEIN_KEY_PWA_27389 }}</span>
      <i class="suiiconfont sui_icon_more_right_12px_2 storebrand__zone-icon"></i>
    </div>
    <div
      v-if="storeRelativeInNewStyle"
      class="brand-btn-container"
    >
      <!-- 底部关注按钮块 -->
      <StoreFollowBtnBox
        scene="ProductDetail"
        class-name="follow-btn-box_new"
        :store-id="storeId"
        :follow-state="storeFollowState"
        :auto-login="true"
        :language="language"
        :showLoginModal="showLoginModal"
        @state-changed="handleFollowStateChange"
        @state-changing="handleFollowStateChanging"
        @state-change-fail="handleFollowStateChangeFail"
      >
        <template slot="button">
          <div
            v-expose="{ id: '1-6-4-63', data: followBtnAnalysisData }"
            :class="{
              'follow-btn': true,
              'show-symbol': followBtnShowSymbol,
              followed: storeFollowState
            }"
            @click="followBtnClick"
          >
            <FollowStoreIcon />
            <b class="whitespace"></b>
            <span>{{ storeFollowBtnText || 'Follow' }}</span>
          </div>
        </template>
      </StoreFollowBtnBox>
      <!-- 底部跳转按钮 -->
      <div
        class="follow-btn-box_new follow-btn-allitems"
        aria-hidden="true"
        @click="viewProducts(flashEntryType === 'showflashsale' || flashEntryType === 'showflashbutton' ? 'promotion' : 'items')"
      >
        <div
          v-if="flashEntryType === 'showflashsale'"
          class="store_flash-sale"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.66039 2.5H5.30039L2.90039 6.9H5.78039L5.06039 12.1L10.1004 4.9H7.22039L8.66039 2.5Z" fill="#161616"/>
          </svg>
          <span>{{ language.SHEIN_KEY_PWA_30008 || 'Flash Sale' }}</span>
        </div>
        <span
          v-else-if="showNewArrival"
          class="store_new-arrival"
        >{{ language.SHEIN_KEY_PWA_30036 || 'NEW' }}</span>

        <div 
          v-if="flashEntryType === 'showflashbutton'"
          class="follow-btn with-flash-icon"
        >
          <img
            class="flash-gif-icon"
            :src="`${PUBLIC_CDN}/pwa_dist/images/flash-sale/flash-gif-097c8939e4.gif`"
          />
          <div
            ref="flashScrollBox"
            class="flash-scroll-wrap"
            :class="{
              'has-animation': hasAnimation
            }"
          >
            <span 
              ref="flashScrollContent"
              class="flash-scroll-item"
              :class="{
                'has-animation': hasAnimation
              }"
              :style="{'animation-duration': millisecond }"
            >
              {{ language.SHEIN_KEY_PWA_31988 || 'Store Flash Sale' }}
            </span>
          </div>
        </div>
        <div
          v-else 
          class="follow-btn"
        >
          {{ language.SHEIN_KEY_PWA_26175 || 'All Items' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import moreMixin from '../mixins/more'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { stringifyQueryString } from '@shein/common-function'
import BrandSeriesTips from './BrandSeriesTips'
import StoreFollowInfo from 'public/src/pages/goods_detail/ShopEntry/StoreFollowInfo'
import StoreFollowBtnBox from 'public/src/pages/goods_detail/ShopEntry/StoreFollowBtnBox'
import FollowStoreIcon from 'public/src/pages/goods_detail/ShopEntry/FollowStoreIcon'
import StoreAtmosphereSwiper from './StoreAtmosphereSwiper'
import { CccInit } from 'public/src/pages/common/ccc_public_v2'
import { getLocalStorage, setLocalStorage, setSessionStorage } from '@shein/common-function'
import { getCategoryName } from 'public/src/pages/goods_detail/utils/productDetail.js'
import storePageDataManager from 'public/src/pages/store_pages/js/storePageDataManager.js'
import { markPoint } from 'public/src/services/mark/index.js'
const { IS_RW, SiteUID, PUBLIC_CDN } = gbCommonInfo

export default {
  name: 'BrandSeriesEnter',
  components: {
    BrandSeriesTips,
    StoreFollowInfo,
    StoreFollowBtnBox,
    FollowStoreIcon,
    StoreAtmosphereSwiper,
  },
  mixins: [moreMixin],
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    analysis: {
      type: Object,
      default() {
        return {}
      }
    },
    hideJumpText: {
      type: Boolean,
      default: false
    },
    // 是否展示独立品牌组件
    isStoreBrand: {
      type: Boolean,
      default: false
    },
    // 是否有推荐列表
    hasRecommendList: {
      type: Boolean,
      default: false
    },
    // 品牌集成店品牌新样式
    // 是否展示新版独立品牌组件（命中abt的 标签商品或独立品牌商品）
    isNewStoreBrand: {
      type: Boolean,
      default: false
    },
    // 跳转商品列表时带上adp参数
    setAdpParams: {
      type: Boolean,
    },
    flashEntryType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      IS_RW,
      SiteUID,
      storeBrandDescExpend: false,
      cccPublic: null,
      PUBLIC_CDN
    }
  },
  computed: {
    ...mapGetters('newProductDetail', ['fsAbt', 'cccBrandZoneLink']),
    ...mapGetters('newProductDetail/Middle', ['fsComments', 'labelList']),
    ...mapGetters('newProductDetail/common', [
      'language',
      'detail',
      'parentCats',
      'isBrandHasTargetTag',
      'storeFollowInfo',
      'showAuthenticbrand',
      'isInFashionStore',
      'inFashionStoreImg',
      'storeRecommendInfo',
      'showStoreNewArrival',
      // 是否是品质店铺
      'isQualityStore'
    ]),
    ...mapGetters('newProductDetail/Signboard', ['storehotnewsType', 'storeAtmosphereInfo']),
    ...mapGetters('newProductDetail/CustomerGallery', [
      'reviewAbt',
    ]),
    showReviewMargin(){
      return this.reviewAbt?.reviewText !== 'None'
    },
    recommendAdp() {
      return this.storeRecommendInfo?.recommendAdp || ''
    },
    isPictureMidLarge(){                                                           
      if(this.showStoreSign && (this.storeInfoOnlyOne || this.tipsDataLessThanOne)){
        return true
      }
      return false
    },
    // 店铺id
    storeId() {
      const { detail } = this
      return detail?.store_code || ''
    },
    labelList() {
      return this.data?.labelList || []
    },
    shopRank() {
      return this.fsComments.shopRank
    },
    formatShopScore() {
      return (+this.shopRank).toFixed(2)
    },
    tipsDataLessThanOne() {
      // 这里只针对店铺相关，对于品牌系列不做处理（在模版中使用的前置条件是店铺，主要是用于命中店铺是时不同的样式展示）
      const {isBestSeller, isInFashionStore, isQualityStore} = this.tipsData
      if(!this.showStoreSign) {
        return false
      }
      // 第三方店铺判断标签数量
      if (this.showLocalTips) {
        return  [isBestSeller, this.isInFashionStore, isQualityStore, this.saleTagText, this.sellerText].filter(item => !!item).length <= 1
      }
      // 其他场景标签长度判断
      return [isInFashionStore, isQualityStore].filter(v => !!v).length <= 1
    },
    tipsData() {
      // return {"show":true,"isBestSeller":true,"isInFashionStore":true,"saleTagText":"3P Seller","sellerText":"Preferred Seller","type":"store","isNewStoreBrand":false}
      // 标签数据
      const { showLocalTips, labelList, saleTagText, data, language, isNewStoreBrand, shouldStoreRelativeShow, isInFashionStore, isQualityStore } = this
      const { type } = data
      if (showLocalTips) {
        // TODO: showLocalTips === true 为第三方店铺
        return {
          show: true,
          isBestSeller: !!labelList.find(item => item.labelCode == 'preferredSeller'),
          isInFashionStore: isInFashionStore,
          saleTagText,
          sellerText: language.SHEIN_KEY_PWA_20758,
          // TODO: 品质店铺
          isQualityStore,
          type, // 可能将来还会其他店铺...
          isNewStoreBrand
        }
      } else if (['brand', 'series'].includes(data.type)) {
        return {
          show: !!data.logoTips,
          labelText: type == 'brand' ? language.SHEIN_KEY_PWA_24180 : language.SHEIN_KEY_PWA_24318,
          type,
          isNewStoreBrand,
          isInFashionStore:
            shouldStoreRelativeShow && isInFashionStore
        }
      } else if(shouldStoreRelativeShow){
        return { 
          show: true,
          isInFashionStore,
          // TODO: 品质店铺
          isQualityStore,
          type
        }
      }
      return { show: false }
    },
    // 展示本地买家标签
    showLocalTips() {
      const { shouldStoreRelativeShow, isThirdPartyStore } = this
      if (!shouldStoreRelativeShow) return false
      return isThirdPartyStore
    },
    saleTagText() {
      let saleText = ''
      if (this.data?.salesArea == 1) {
        saleText =
          this.SiteUID == 'pwus'
            ? this.language?.SHEIN_KEY_PWA_23428 || ''
            : this.language?.SHEIN_KEY_PWA_23139 || ''
      } else if (this.data?.salesArea == 2) {
        saleText =
          this.SiteUID == 'pwus'
            ? this.language?.SHEIN_KEY_PWA_23428 || ''
            : this.language?.SHEIN_KEY_PWA_23140 || ''
      }
      return saleText
    },
    // 第三方本地平台店铺
    isThirdPartyStore() {
      return this.data?.storeType == 1
    },
    // 展示店铺描述需满足4个条件，1.有描述 2.命中实验 3.shein 4.店铺还需命中标签
    descriptionsShow() {
      const { data, isStore } = this
      if (!data?.desc || IS_RW) return false
      if (isStore) {
        const hasLabel = data?.labelList?.some(d => d.labelCode === 'Show_Description')
        if (!hasLabel) return false
      }
      return true
    },
    // 当是店铺时，展示店铺新样式，新交互
    showStoreSign(){
      if (this.isStore) {
        if (this.IS_RW) return this.fsAbt?.storejump?.param?.storesign === 'new'
        return true
      }
      return false
    },
    // 店铺相关标签、评分、商品数是否展示
    shouldStoreRelativeShow() {
      const { data } = this
      return data && this.isStore
    },
    // 店铺关注信息是否展示
    // TODO-remove 对应店铺230620新样式，abt全量后永远返回true，相关逻辑需要移除
    storeFollowRelativeShow() {
      const { storeFollowInfo } = this
      return storeFollowInfo?.show
    },
    storeRelativeInNewStyle() {
      const {
        storeFollowRelativeShow,
        shouldStoreRelativeShow,
        isStoreBrand,
        isNewStoreBrand
      } = this
      if (!storeFollowRelativeShow) {
        // TODO-remove abt全量后storeFollowRelativeShow永远返回true
        return false
      }
      return shouldStoreRelativeShow && !isStoreBrand && !isNewStoreBrand 
    },
    salesArea() {
      return this.data?.salesArea || null
    },
    // 自营店铺 / 1P店铺
    isOBMStore() {
      return [2, 6].includes(+this.data?.storeType)
    },
    // 是否是店铺
    isStore() {
      return this.data?.type === 'store'
    },
    // 是否隐藏店铺相关信息
    // * 22-10-27【美国平台化-店铺展示为品牌】需求，将店铺相关信息隐藏展示为品牌
    // hideStoreRelative() {
    //   const { isStore, fsAbt, isThirdPartyStore, isOBMStore } = this
    //   if (!isStore) return false
    //   const { brandstore, brandstoreobm } = fsAbt || {}
    //   if (isThirdPartyStore && brandstore?.param === 'brand') {
    //     return true
    //   }
    //   if (isOBMStore && brandstoreobm?.param === 'brand') {
    //     return true
    //   }
    //   return false
    // },
    // 品牌logo展示规则
    showBrandLogo() {
      return this.data.logo
    },
    // 店铺关注信息仅有的一条，非店铺新样式，或关注信息0条或超过1条均返回空
    // HACK-2021-10-27【美国平台化-店铺展示为品牌】需求，店铺关注信息仅展示1条时，不单独一行展示
    storeInfoOnlyOne() {
      const { storeRelativeInNewStyle, storeFollowInfo, shopRank, language } = this
      if (!storeRelativeInNewStyle) return null
      const { followCount, salesVolume } = storeFollowInfo || {}
      const { SHEIN_KEY_PWA_20750, SHEIN_KEY_PWA_24069, SHEIN_KEY_PWA_20745 } = language
      const shows = [
        [!!shopRank, shopRank, SHEIN_KEY_PWA_20750],
        [followCount && followCount !== '0', followCount, SHEIN_KEY_PWA_24069],
        [salesVolume && salesVolume !== '0', salesVolume, SHEIN_KEY_PWA_20745]
      ].filter(i => i[0])
      return shows.length === 1 ? shows[0] : null
    },
    // 用户店铺关注状态
    storeFollowState() {
      const { storeFollowRelativeShow, storeFollowInfo } = this
      if (!storeFollowRelativeShow) {
        // TODO-remove abt全量后删除此逻辑
        return false
      }
      return storeFollowInfo?.userFollowState === 1
    },
    // 店铺关注按钮是否展示+符号
    followBtnShowSymbol() {
      const { storeFollowInfo } = this
      const { userFollowState, followStateChanging } = storeFollowInfo || {}
      return !followStateChanging && userFollowState !== 1
    },
    // 店铺关注按钮文案
    storeFollowBtnText() {
      const { storeFollowInfo, language } = this
      const { followStateChanging, userFollowState } = storeFollowInfo || {}
      if (followStateChanging) return '...'
      if (userFollowState === -1) {
        return language?.SHEIN_KEY_PWA_24068
      }
      return userFollowState === 1 ? language?.SHEIN_KEY_PWA_24072 : language?.SHEIN_KEY_PWA_24068
    },
    viewMoreIconClass() {
      const { IS_RW } = this
      return ['suiiconfont', IS_RW ? 'sui_icon_more_right_16px' : 'sui_icon_more_right2_16px']
    },
    // 额外埋点信息
    extAnalysisInfo() {
      const { isStore, storeFollowInfo, formatShopScore, tipsData, storeAtmosphereInfo } = this
      const { followCount, salesVolume } = storeFollowInfo || {}
      const brandInfo = isStore
        ? [
            `ratings_${formatShopScore}`,
            salesVolume && salesVolume !== '0' ? `sold_in_30d_${salesVolume}` : '-',
            followCount && followCount !== '0' ? `followers_${followCount}` : '-'
        ]
        : []
      const { isBestSeller, saleTagText, isQualityStore } = tipsData
      const brandLabelArr = isStore
        ? [
          isBestSeller ? 'label_id_-\'label_content_Star Seller' : '',
          saleTagText ? 'label_id_-\'label_content_Local Seller' : '',
          isQualityStore ? 'label_id_-\`label_content_choice' : ''
        ]
        : []
      const brandLabel = brandLabelArr.filter(i => !!i).join(',') || ''
      this.$emit('handleStoreBrandLabel', brandLabel)
      const buttonLabel = this.showNewArrival ? 'icon_new' : '-'
      // 店铺人气氛围
      let storePopularity = ''
      if(storeAtmosphereInfo.length){
        const popTypes = new Set()
        storeAtmosphereInfo.forEach(e => {
          if (e.popType) {
            popTypes.add(e.popType)
          }
          storePopularity = Array.from(popTypes).join('\'')
        })
      }else {
        storePopularity = '0'
      }
      const info = {
        abtAnaInfo: storeFollowInfo?.abtAnaInfo || '',
        brandInfo: brandInfo.join('`') || '',
        brandLabel,
        buttonLabel,
        storePopularity,
      }
      this.$emit('handleStoreBrandAnalysis', info)
      return info
    },
    // 店铺关注按钮埋点信息
    followBtnAnalysisData() {
      const { storeFollowInfo, storeId, data } = this
      const { userFollowState } = storeFollowInfo || {}
      const { type = '' } = data || {}
      return {
        action_tp: userFollowState === 1 ? 'followed' : 'unfollowed',
        brand_code: storeId,
        // 实际上肯定是店铺
        brand_type: type
      }
    },
    authenticbrandShowAnalysisData() {
      const { detail } = this
      return {
        // 商品id 提交的goods_id
        goods_id: detail.goods_id,
        // 品牌code 当前的品牌模块的品牌brand_code
        brand_code: detail?.brandInfo?.brand_code
      }
    },
    // 符合标签的商品且满足abt 展示 100%正品标签
    isAbtAuthenticbrandShow() {
      return this.isBrandHasTargetTag && this.showAuthenticbrand && this.isNewStoreBrand
    },
    // 展示品牌馆入口
    showBrandsZone() {
      const { brandzone = {} } = this.fsAbt || {}
      return brandzone?.p?.brandzone === 'show' && this.isAbtAuthenticbrandShow && this.cccBrandZoneLink?.content?.hrefType
    },
    // allitems按钮是否显示上新标签
    showNewArrival() {
      return this.showStoreNewArrival === 'allitems'
    },
  },
  methods: {
    ...mapMutations('productDetail', ['assignState']),
    ...mapMutations('newProductDetail/common', ['updateStoreFollowInfo']),
    ...mapActions('newProductDetail', ['showLoginModal']),
    // ...mapActions('productDetail', ['updateStoreFollowInfo']),
    viewProducts(tabId) {
      const { type, id, link, name, designerId, scId } = this.data
      const { shopRank, analysis, showNewArrival } = this
      const { detail } = this
      if (!link) return
      const src_identifier = [
        `on=${type}`,
        `cn=${name}`,
        `hz=0`,
        `ps=1_1`,
        `jc=${
          designerId
            ? `sheinxDesignerInformation_${designerId}`
            : this.isStore
              ? `thirdPartyStoreHome_${id}`
              : `itemPicking_${scId}`
        }`
      ].join('`')
      // 新店招交互
      if(this.showStoreSign) {
        markPoint('toNextPageClick', 'public')
        const params = {
          src_module: analysis?.src_module,
          src_identifier: src_identifier,
          src_tab_page_id: getSaPageInfo.tab_page_id || '',
          main_goods_id: this.detail.goods_id || '',
          main_cat_id: this.detail.cat_id || '',
        }
        // 新店招交互点击allitems上报
        this.clickStoreReports({ tabId, type, id, src_identifier })
        if(!tabId){  // 不是allitems 列表的入口点击
          // 往店铺塞数据
          const detailToStoreData = {
            goods_id: detail.goods_id,
            goods_img: detail.goods_thumb || detail.goods_img,
            cat_id: detail.cat_id,
            cat_name: getCategoryName(this.parentCats)
          }
          setSessionStorage({ key: 'detailToStoreData', value: detailToStoreData })
        }
        if(showNewArrival && tabId) {
          params.sort = '9'
          this.setNewArrivalStorage(id)
        }

        const { langPath } = gbCommonInfo
        const queryObj = {
          adp: this.detail.goods_id,
          store_code: this.data.storeCode,
          ...params,
        }
        if (tabId) {
          queryObj.tabId = tabId
          queryObj.isStoreTabSticky = '1'
        }
        const url = `${langPath}/store/home?${stringifyQueryString({
          queryObj
        })}`
        storePageDataManager.prerenderRequest({
          fullPath: url,
          query: queryObj,
        })
        this.$router.push(url)
        return 
      }
      // 点击跳转店铺上报
      this.clickStoreReports({ tabId, type, id, src_identifier })    

      let sort = ''
      if(showNewArrival && tabId) {
        sort = 9
        this.setNewArrivalStorage(id)
      }
      
      this.boardMoreHandle({
        sort,
        isStoreTabSticky: tabId ? '1' : '',
        tabId,
        type,
        id,
        link,
        shopRank,
        goods_id: detail.goods_id,
        cat_id: detail.cat_id,
        adp: !this.setAdpParams ? '' : this.recommendAdp,
        analysis: { src_identifier, src_module: analysis?.src_module }
      })
    },
    setNewArrivalStorage(id) {
      const storageKey = `storeAllitems_hide_${id}`
      let val = getLocalStorage(storageKey)
      if(!val){
        // 上新标签缓存3天
        setLocalStorage({ key: storageKey, value: true, expire: 1000 * 60 * 60 * 24 * 3 })
      }
    },
    clickStoreReports({ tabId, type, id, src_identifier }) {
      const { brandInfo, brandLabel, buttonLabel } = this.extAnalysisInfo
      const flash_sale_stype = this.flashEntryType === 'showflashsale' ? 2 : (this.flashEntryType === 'showflashbutton' ? 3 : 0)
      daEventCenter.triggerNotice({
        daId: tabId ? '1-6-1-194' : '1-6-4-17',
        extraData: {
          brand_type: type,
          brand_code: id,
          // abtest: abtAnaInfo,
          brand_info: brandInfo,
          brand_label: brandLabel,
          button_label: tabId ? buttonLabel : '',
          page_nm: 'page_goods_detail',
          src_identifier,
          flash_sale_stype: tabId ? flash_sale_stype : undefined
        }
      })
    },
    // 关注按钮点击
    // * 此处只处理埋点事件，关注状态的切换冒泡至 StoreFollowBtnBox 组件进行处理
    followBtnClick() {
      const { followBtnAnalysisData, storeFollowInfo } = this
      const { userFollowState } = storeFollowInfo || {}
      const notLogin = typeof SHEIN_COMMON !== 'undefined' && SHEIN_COMMON?.isLogin?.() === false
      daEventCenter.triggerNotice({
        daId: '1-6-4-64',
        extraData: {
          ...followBtnAnalysisData,
          action_tp: notLogin ? 'follow' : !userFollowState ? 'follow' : 'cancel'
        }
      })
    },
    // 更改关注状态成功
    handleFollowStateChange(targetState) {
      const { storeFollowInfo, followBtnAnalysisData } = this
      const { followCount } = storeFollowInfo || {}
      const userFollowState = targetState ? 1 : 0
      let updateCount = followCount
      if (+followCount) {
        updateCount = userFollowState == 1 ? +followCount + 1 : +followCount - 1
      }
      // if (storeFollowInfo?.followStateChanging) return
      this.updateStoreFollowInfo({
        ...storeFollowInfo,
        userFollowState,
        followCount: updateCount + ''
        // followCount,
      })
      // this.assignState({
      //   storeFollowInfo: {
      //     ...(storeFollowInfo || {}),
      //     userFollowState: targetState ? 1 : 0,
      //   }
      // })
      // this.$nextTick(() => {
      //   updateStoreFollowInfo?.()
      // })
      // 埋点
      daEventCenter.triggerNotice({
        daId: '1-6-4-65',
        extraData: {
          ...followBtnAnalysisData,
          result: 1,
          reason_tp: '-',
          action_tp: targetState ? 'follow' : 'cancel'
        }
      })
    },
    // 变更关注状态失败
    handleFollowStateChangeFail(err) {
      const { followBtnAnalysisData, storeFollowInfo } = this
      const { userFollowState } = storeFollowInfo || {}
      // 埋点
      daEventCenter.triggerNotice({
        daId: '1-6-4-65',
        extraData: {
          ...followBtnAnalysisData,
          result: 0,
          reason_tp: err?.code || '-1',
          action_tp: !userFollowState ? 'follow' : 'cancel'
        }
      })
    },
    goToBrandZone() {
      daEventCenter.triggerNotice({
        daId: '1-6-4-82',
        extraData: {
          goods_id: this.detail.goods_id,
          brand_code: this.storeId,
        }
      })
      if(!this.cccPublic) {
        this.cccPublic = new CccInit({
          context: this.cccBrandZoneLink,
          propData: this.cccBrandZoneLink.content,
          index: 1,
        })
      }
      this.cccPublic.clickLink({
        item: this.cccBrandZoneLink.content,
        cccCateInfo: {},
        gaIndex: 0,
        scIndex: 0,
      })
    },
    // 关注状态变更中
    handleFollowStateChanging(state) {
      const { storeFollowInfo } = this
      this.updateStoreFollowInfo({
        ...(storeFollowInfo || {}),
        followStateChanging: state
      })
      // this.assignState({
      //   storeFollowInfo: {
      //     ...(storeFollowInfo || {}),
      //     followStateChanging: state,
      //   }
      // })
    }
  }
}
</script>

<style lang="less">
.base-text-ellipsis {
  // 一行超出省略号
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.brand-outer-box {
  padding: 0.2667rem 0.346666rem;
  background: #fff;
  &.brand-type {
    padding: 0.2667rem 0;
  }
  // 新样式
  &.brand-type_new {
    padding: 0.32rem;
    // 为了渐变色覆盖父元素设置的padding-left right间距
    margin: 0 -0.32rem;
    background: linear-gradient(180deg, rgba(218, 231, 246, 0.6) 0%, rgba(218, 231, 246, 0) 21.79%),
      #fff;
    // 防止渐变色切换展开时闪烁
    background-repeat: no-repeat !important; /* stylelint-disable-line declaration-no-important */
    background-size: 100% 2.08rem !important; /* stylelint-disable-line declaration-no-important */
  }
  &.local-type {
    padding: 0.2667rem 0.32rem;
  }
  /* 调整底部有列表时的店铺间距, 在外层定义class的值 */
  &.have-list {
    padding-bottom: 16/75rem;
    .mgds-brand__detail-info-descriptions {
      margin-bottom: 0.1067rem;
    }
  }
  
  &.follow-have-list {
    padding-bottom: 0.32rem;
  }
}

.mgds-brand {
  .flexbox();
  .space-between();
  .align-center();
  &__detail {
    .flexbox();
    overflow: hidden;
    &.center {
      .align-center();
    }
  }
  &__detail-logo {
    .margin-r(8/75rem);
    width: 88/75rem;
    height: 88/75rem;
    .border-dpr(border, 1px, #e5e5e5);
    .flexbox();
    .align-center();
    flex-shrink: 0;
    &.large-size {
      width: 1.4133rem;
      height: 1.4133rem;
    }
    &.mid-large-size{
      width: 1.226666rem;
      height: 1.226666rem;
    }
    &.mid-size {
      width: 1.067rem;
      height: 1.067rem;
    }
    > img {
      width: 100%;
    }
  }
  &__detail-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 6.76rem;
    overflow: hidden;

    .storebrand-desc-wrap {
      display: flex;
      align-items: center;
      &__content {
        .base-text-ellipsis();
        color: #818181;
        font-size: 0.32rem;
      }
    }
  }
  &__detail-info-title {
    .text {
      .font-dpr(28px);
      color: #333;
      font-weight: bold;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      gap: 0.1067rem;

      > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      i {
        color: @sui_color_gray_light1;
        margin: 0 0.106667rem;
      }
    }

    .store-brand_tip {
      img {
        width: 0.37333rem;
        height: 0.37333rem;
      }
    }
  }
  &__detail-info-descriptions {
    font-size: 12px;
    margin-top: 0.32rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
  }
  &__view-products {
    padding: 0.3rem 0;
    white-space: nowrap;
    color: #999;
    > i {
      color: #999;
      .margin-l(0.12rem);
      .font-dpr(24px);
    }

    &.hidden {
      display: none;
    }
  }
  &__tip-container{
    display: flex;
    margin-top: 0.1067rem;
    gap: 0.1067rem;
    flex-wrap: wrap;
    align-items: center;
  }
  // .split-top {
  //   margin-top: 0.1067rem;
  // }
  .split-left {
    margin-left: 0.1067rem;
  }

  .store-info-inline {
    .key {
      color: @sui_color_gray_dark2;
      font-weight: 400;
    }
    .value {
      color: @sui_color_gray_dark1;
      font-weight: 500;
    }
  }

  .storebrand__authentic {
    color: #000;
    font-size: 0.29333rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: inline;
    margin-right: 0.15rem;
    &::before {
      content: '';
      display: inline-block;
      width: 0.01333rem;
      height: 0.21333rem;
      opacity: 0.4;
      background: #000;
      margin: 0 0.16rem;
    }
  }
}

// 本地店铺
.local-shop {
  .mgds-brand {
    &__detail-logo {
      .margin-r(0.2133rem);
      // width: 1.6rem;
      // height: 1.6rem;
    }
    &__detail-info-footer {
      display: inline-flex;
      margin-top: 0.2133rem;
      .item {
        .font-dpr(20px);
        line-height: 0.32rem;
        color: #666;
      }
      .split {
        margin-right: 0.4267rem;
      }
    }
    &__detail-info-title {
      .text {
        line-height: 0.4533rem;
      }
    }
  }
}

.mgds-storebrand,
.mgds-storebrand_new {
  .mgds-brand__detail {
    align-items: center;
  }
  .mgds-brand__detail-logo {
    width: 0.8533rem;
    height: 0.8533rem;
    overflow: hidden;
  }
  .mgds-brand__detail-info {
    max-width: 100%;
  }
  .mgds-brand__view-products {
    padding: 0;
  }
  .mgds-brand__detail-info-title {
    display: inline-flex;
    align-items: center;
    .text {
      color: #000;
      font-weight: 400;
      line-height: 1;
    }
  }
}

.mgds-storebrand {
  .mgds-brand__detail-logo {
    border-radius: 50%;
    margin-right: 0.32rem;
  }
}

.mgds-storebrand_new {
  .mgds-brand__detail-logo {
    margin-right: 0.21rem;
  }
}

/* 多行文本超出显示省略号*/
.n-ellipsis(@n){
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @n;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-all;
}
.desc-brand {
  // 二行超出省略号
  .n-ellipsis(2);
  color: #818181;
  font-size: 0.2933rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.desc-new {
  // 一行超出省略号
  .base-text-ellipsis();
  color: #818181;
  font-size: 0.2933rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.brand-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.4533rem;
  .follow-btn-box_new {
    .follow-btn {
      position: relative;
      display: flex;
      width: 4.4267rem;
      padding: 0.2133rem;
      justify-content: center;
      align-items: center;
      font-size: 0.3733rem;
      line-height: 0.3733rem;
      color: @sui_color_gray_dark1;
      /* stylelint-disable-next-line */
      &.show-symbol {
        /* stylelint-disable-next-line */
        svg,
        .whitespace {
          display: inline-block;
        }
      }
      /* stylelint-disable-next-line */
      &.followed {
        color: @sui_color_gray_light1;
        border-color: @sui_color_gray_weak1;
      }
      &.with-flash-icon{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .flash-scroll-wrap{
          overflow: hidden;
          white-space: nowrap;
          vertical-align: top;
          &.has-animation{
            position: relative;
            flex: 1;
            height: 0.3733rem
          }
        }
        .flash-scroll-item {
          display: inline-block;
          &.has-animation{
            position: absolute;
            left: 0;
            animation: flashScroll linear 4s alternate infinite;
          }
        }
        @keyframes flashScroll {
          0% {
            left: 0;
            transform: translateX(0);
          }
          35% {
              left: 0;
              transform: translateX(0);
          }
        
          65% {
              left: 100%;
              transform: translateX(-100%);
          }
          100% {
            left: 100%;
            transform: translateX(-100%);
          }
        }
      }
      .flash-gif-icon{
        width: 0.48rem;
        height: 0.48rem;
        margin-right: 0.1067rem;
        flex: 0 0 auto;
      }
      span {
        display: inline-block;
      }
      /* stylelint-disable-next-line */
      svg,
      .whitespace {
        display: none;
      }
      /* stylelint-disable-next-line */
      .whitespace {
        width: 0.106667rem;
        height: 0.106667rem;
      }
    }
    .follow-btn:before {
      // 0.5px border 伪类
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 200%;
      border: 1px solid var(---sui_color_gray_dark2, #666);
      border-radius: 0.8267rem;
      transform: scale(0.5);
      transform-origin: left top;
    }
  }
  .follow-btn-allitems{
    position: relative;
    .store_flash-sale{
      position: absolute;
      display: flex;
      align-items: center;
      padding: 0 0.1067rem 0 0.08rem;
      height: 0.347rem;
      position: absolute;
      right: -0.05333rem;
      top: -0.16rem;
      font-size: 0.24rem;
      color: #000;
      z-index: 1;
      background-image: url('/pwa_dist/images/flash-sale/scan_flash-6a3950325b.gif');
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .store_new-arrival{
      position: absolute;
      display: inline-block;
      height: 0.37333rem;
      line-height: 0.37333rem;
      padding: 0 0.05333rem;
      position: absolute;
      right: -0.05333rem;
      top: -0.16rem;
      border-radius: 0.08rem 0.08rem 0.08rem 0;
      border: 1px solid #FFF;
      background: #3CBD45;
      font-size: 0.26667rem;
      color: #fff;
      z-index: 1;
    }
  }
}

.brand-type,
.brand-type_new {
  .mgds-brand__detail-info-title {
    .text_first {
      color: #000;
      font-size: 0.37333rem;
      font-weight: 700;
    }
  }
}

.storebrand {
  &__descriptions {
    color: #666;
    font-size: 0.32rem;
    font-weight: 400;
    margin-top: 0.21rem;
    &.ellipsis {
      .base-text-ellipsis();
    }
  }
  &__zone {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0.0533rem;
    margin-top: 0.2133rem;
    padding: 0.08rem;
    background: linear-gradient(90deg, #E5EEF9 0.01%, rgba(229, 238, 249, 0.29) 48.74%, rgba(229, 238, 249, 0.00) 82.65%);
  }
  &__zone-title {
    border-radius: 0.0533rem;
    background: linear-gradient(90deg, #739BD8 0%, #9CBBEB 100%);
    color: #FFF;
    font-family: SF UI Text;
    .font-dpr(22px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    flex: 0 0 auto;
    padding: 0.0533rem 0.08rem 0.0533rem 0.1067rem;
  }
  &__zone-desc {
    color: #243F6A;
    font-family: SF UI Text;
    .font-dpr(22px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0.1067rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__zone-icon {
    margin-left: 0.1067rem;
    color: #243F6A;
  }
}
</style>
