<template>
  <div
    v-ada="{ level: 1, pos: 0 }"
    v-expose="getReviewCardAnalysisData"
    class="goods-review__rate"
    :class="{ 'bottom-space': localRatingShow, 'goods-review__rate-new': showNewReviewcard }"
    role="text"
    :aria-label="language.SHEIN_KEY_PWA_17960 + ' ' + commentRankAverage"
  >
    <div 
      class="goods-review__rate-content" 
      @click="clickRating"
    >
      <!-- 总评分 -->
      <div
        v-if="commentRankAverage || currentMultiDimeRate.length"
        aria-hidden="true"
        class="rate-box"
        :class="{ 'is-pop-win': !isDetail }"
      >
        <div>
          <!-- 左侧星级块 -->   
          <div v-if="commentRankAverage">
            <div 
              class="star-box"
              :style="{'flex-wrap': showReviewsGuidance ? 'wrap' : 'nowrap', 'width': !isDetail && showReviewsGuidance ? 'min-content' : ''}"
            >
              <div class="star-num">
                {{ commentRankAverage }}
              </div>
              <div
                class="star-html"
                v-html="ratingStarHTML"
              ></div>
              <div
                v-if="!isDetail && !showReviewsGuidance"
                v-expose="{ id: '1-6-2-55' }"
                class="star-info"
                @click.stop.prevent="jumpToRateExplanation"
              >
                <i class="suiiconfont sui_icon_doubt_16px_2"></i>
              </div>
              <CommentDoubt 
                v-if="!isDetail && showReviewsGuidance"
                :haveDividingLine="haveDividingLine"
                :language="language"
              />
            </div>
          </div>
          <!-- 排名 下方 -->
          <div
            v-if="isShowRank && currentMultiDimeRate.length && !showNewReviewcard"
            class="rank-box"
          >
            <div
              class="rank-text" 
              v-html="rankText"
            ></div>
            <span class="rank-progress">
              <span 
                class="rank-progress-rate" 
                :style="{ width: progress }"
              >
                <span class="icon">
                  <i class="suiiconfont sui_icon_like_completed_16px"></i>
                </span>
              </span>
            </span>
          </div>
          <div
            v-if="isShowRank && currentMultiDimeRate.length && showNewReviewcard"
            class="goods-review__rankBox-new"
          >
            <span class="goods-review__like-icon">
              <Icon
                name="sui_icon_like_completed_16px"
                size="12"
                color="#fff"
              />
            </span>
            <span
              class="goods-review__rank-text" 
              v-html="rankText"
            ></span>
          </div>
        </div>
        <!-- 中间分割线 -->
        <div
          v-if="haveDividingLine"
          class="vert-split-box"
        >
          <div class="vert-split-line"></div>
        </div>
  
        <!-- 右侧多维评分块 -->
        <ul
          v-if="currentMultiDimeRate.length"
          v-expose="getRateTagAnalysisData()"
          class="multi-rate-box"
          :class="{ 'full-width': !commentRankAverage }"
        >
          <li
            v-for="(tag, index) in currentMultiDimeRate"
            :key="index"
            class="multi-rate-item"
          >
            <span>{{ tag.nameLocal }}</span>
            <span class="num">{{ tag.score }}</span>
          </li>
        </ul>
        <!-- 排名 右侧 -->
        <div
          v-if="isShowRank && !currentMultiDimeRate.length && !showNewReviewcard"
          class="rank-box rank-box-right"
        >
          <div
            class="rank-text" 
            v-html="rankText"
          ></div>
          <span class="rank-progress">
            <span 
              class="rank-progress-rate" 
              :style="{ width: progress }"
            >
              <span class="icon">
                <i class="suiiconfont sui_icon_like_completed_16px"></i>
              </span>
            </span>
          </span>
        </div>
        <span
          v-if="isShowRank && !currentMultiDimeRate.length && showNewReviewcard"
          class="goods-review__rankBox-new"
        >
          <span class="goods-review__like-icon">
            <Icon
              name="sui_icon_like_completed_16px"
              size="12"
              color="#fff"
            />
          </span>
          <span
            class="goods-review__rank-text" 
            v-html="rankText"
          ></span>
        </span>
      </div>
  
      <!-- 中间分割线 右 下 模块都有的时候才出现-->
      <div 
        v-if="(currentMultiDimeRate.length || (isShowRank && !currentMultiDimeRate.length) || showNewReviewcard) && (hasFit && hasTrueToSize)" 
        class="middle-line"
      ></div>
      <!-- 尺码匹配评分 -->
      <div
        v-if="showFixBox[0]"
        aria-hidden="true"
        class="fit-box"
      >
        <p class="title">
          {{ language.SHEIN_KEY_PWA_15004 }}
        </p>
  
        <ul class="fit-rate-box">
          <li
            v-for="(fitItem, index) in fitRates"
            :key="index"
            class="fit-rate-item"
          >
            <em>{{ fitItem.label }}</em>
            <div class="fit-rate-proress">
              <span>
                <i
                  :style="{ width: fitItem.percentage }"
                  class="fit-small"
                ></i>
              </span>
              <b v-if="rtl">%{{ fitItem.roundPercentage }}</b>
              <b v-else>{{ fitItem.roundPercentage }}%</b>
            </div>
          </li>
        </ul>
      </div>
      <!-- 尺码匹配评分-新样式 仅展示最大比例 -->
      <div
        v-if="showFixBox[1]"
        class="goods-review__fitBox-max"
      >
        <Icon
          v-if="fitMaxScaleObj.showIcom"
          name="sui_icon_like_completed_16px"
          size="14"
        />
        <span
          class="goods-review__fit-max-text"
          v-html="fitMaxScaleObj.text"
        ></span>
      </div>
      <!-- 尺码匹配评分-新样式 三种比例展示在一行 -->
      <div 
        v-if="showFixBox[2]"
        class="goods-review__fitBox-both"
      >
        <div class="goods-review__fit-label">
          {{ language.SHEIN_KEY_PWA_14999 }}:
        </div>
        <ul class="goods-review__fitBox-rate">
          <li
            v-for="(fitItem, index) in fitRates"
            :key="index"
            class="goods-review__rateItem"
          >
            <span class="goods-review__rate-label">{{ fitItem.label }}</span>
            <div class="goods-review__rateProgress">
              <span class="goods-review__percentage">
                <i
                  :style="{ width: fitItem.percentage }"
                  class="goods-review__bar"
                ></i>
              </span>
              <b v-if="rtl">%{{ fitItem.roundPercentage }}</b>
              <b v-else>{{ fitItem.roundPercentage }}%</b>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 底部本地评分栏 -->
    <div
      v-if="localRatingShow"
      class="local-rating-box"
      :class="{localReviewBarClassName, 'goods-review__local-rating': showNewReviewcard}"
      @click="clickLocalHandle"
    >
      <!-- 分割线 -->
      <div class="split-line"></div>

      <div class="star-box">
        <p>{{ language.SHEIN_KEY_PWA_22254 }}</p>
        <em>{{ localRating }}</em>
        <div v-html="localRatingStarHTML"></div>
      </div>
      <div class="view-button">
        <span>{{ language.SHEIN_KEY_PWA_22255 }}</span>
        <i class="suiiconfont sui_icon_more_right_12px_2"></i>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { markPoint } from 'public/src/services/mark/index.js'
import schttp from 'public/src/services/schttp'
import CommentDoubt from '../CommentDoubt'
import { template } from '@shein/common-function'

/* globals GB_SHEIN_counting_star_suiicon */

const { GB_cssRight, SiteUID } = gbCommonInfo

export default {
  name: 'RatePanel',
  components: {
    CommentDoubt
  },
  props: {
    hasTrueToSize: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    localReviewBarClassName: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    SiteUID,
    rtl: !!GB_cssRight,
    commentRankBaseline: 0, // 切线
  }),
  computed: {
    ...mapGetters('newProductDetail', ['commentOverView', 'fsAbt']),
    ...mapGetters('newProductDetail/common', ['detail', 'language', 'hasFit', 'tsp', 'currentCat']),
    ...mapGetters('newProductDetail/Reviews', [
      'reviewsBaseInfo',
      'commentOverViewLocal',
      'contentLabel',
      'rateExplanationLink',
      'showLocalReviews'
    ]),
    ...mapGetters('newProductDetail/RatePanel', [
      'showLocalRating',
      'localRating',
      'hasLocalComments'
    ]),
    ...mapState('newProductDetail/CommentPopup', ['onlyLocalStatus']),
    localRatingShow() {
      return this.showLocalRating && this.isDetail
    },
    haveDividingLine(){
      return (this.commentRankAverage && !!this.currentMultiDimeRate.length) || (this.isShowRank && !this.currentMultiDimeRate.length)
    },
    globalRating() {
      return this.commentOverView?.comment_rank_average || null
    },
    commentRankAverage() {
      const { commentOverView, localRating, isDetail } = this
      if (!isDetail && this.onlyLocalStatus) {
        return localRating || null
      }
      return commentOverView?.comment_rank_average || null
    },
    // 星级html
    ratingStarHTML() {
      const IS_RW = this.reviewsBaseInfo?.isRW
      const { commentRankAverage } = this
      return GB_SHEIN_counting_star_suiicon(commentRankAverage, IS_RW ? 20 : 15, IS_RW ? '' : 2)
    },
    // 本地评分星级html
    localRatingStarHTML() {
      const IS_RW = this.reviewsBaseInfo?.isRW
      const { localRating } = this
      if (!localRating) {
        return ''
      }
      return GB_SHEIN_counting_star_suiicon(localRating, IS_RW ? 20 : 15, IS_RW ? '' : 2)
    },
    currentComment() {
      const { commentOverView, isDetail } = this
      if (!isDetail && this.onlyLocalStatus) {
        return this.commentOverViewLocal
      }
      return commentOverView
    },
    // 尺码匹配分数项
    fitRates() {
      const { hasFit, hasTrueToSize, currentComment, getFitPercentageRoundNum, language } = this
      const percentOverallFit = currentComment?.percent_overall_fit
      if (!hasFit || !hasTrueToSize || !percentOverallFit) {
        return []
      }

      return [
        {
          label: language.SHEIN_KEY_PWA_15002,
          percentage: percentOverallFit.small,
          roundPercentage: getFitPercentageRoundNum(percentOverallFit.small)
        },
        {
          label: language.SHEIN_KEY_PWA_15001,
          percentage: percentOverallFit.true_size,
          roundPercentage: getFitPercentageRoundNum(percentOverallFit.true_size)
        },
        {
          label: language.SHEIN_KEY_PWA_15003,
          percentage: percentOverallFit.large,
          roundPercentage: getFitPercentageRoundNum(percentOverallFit.large)
        }
      ]
    },
    // 当前多维评论分数（本地or全域）
    currentMultiDimeRate() {
      const { currentComment = {}, contentLabel } = this
      const { sel_tag_score } = currentComment
      if (!contentLabel || !Array.isArray(sel_tag_score)) {
        return []
      }

      const rates = []
      sel_tag_score.forEach(tag => {
        const { id = '', tag_score = '' } = tag || {}
        const label = contentLabel[id] || {}
        const { language_name = '', name_en = '' } = label
        if (id && tag_score && name_en) {
          rates.push({
            id,
            score: tag_score,
            name: name_en,
            nameLocal: language_name
          })
        }
      })

      return rates.slice(0, 3)
    },
    
    rankRate() {
      return this.tsp?.['4448'] ?? ''
    },
    rankText() {
      // const category = this.tsp?.['4447'] ?? ''
      const category = this.currentCat?.cat_name ?? ''
      let text = ''
      if(+this.commentRankAverage === 5 || this.rankRate === '0') {
        text = `<span class="r-color">${this.language.SHEIN_KEY_PWA_29381}</span> in ${category}`
      } else if(4 <= +this.commentRankAverage < 5) {
        text = `<span class="r-color">${template(this.rankRate, this.language.SHEIN_KEY_PWA_29382)}%</span> ${template(category, this.language.SHEIN_KEY_PWA_29384)}`
      }
      return text
    },

    progress() {
      return (+this.commentRankAverage === 5 ? 100 : 100 - this.rankRate ) + '%'
    },

    rankAbt() {
      return this.fsAbt?.Reviewchange?.param?.Raterank === 'Show'
    },
    showReviewsGuidance() {
      return ['pwde', 'mpl'].includes(this.SiteUID)
    },
    // 是否展示排名文案
    isShowRank() {
      if(
        this.rankAbt && 
        (this.rankRate && this.rankRate <= this.commentRankBaseline) &&
        (this.commentRankAverage >= 4)
      ) {
        return true
      }
      
      return false
    },
    showNewReviewcard() {
      return ['True_to_size_only', 'True_to_size_both'].includes(this.fsAbt?.Reviewchange?.param?.Reviewcard_display)
    },
    showFixBox() {
      // 显示最大比例的合身尺码
      let showMaxScaleFix = this.fsAbt?.Reviewchange?.param?.Reviewcard_display === 'True_to_size_only' && this.isDetail
      let oldFixBox = this.hasFit && this.hasTrueToSize && !this.showNewReviewcard
      let newFixBoxMaxScale = this.hasFit && this.hasTrueToSize && this.showNewReviewcard && showMaxScaleFix
      let newFixBoxBothScale = this.hasFit && this.hasTrueToSize && this.showNewReviewcard && !showMaxScaleFix
      return [
        oldFixBox,
        newFixBoxMaxScale,
        newFixBoxBothScale,
      ]
    },
    fitMaxScaleObj() {
      const percentOverallFit = this.currentComment?.percent_overall_fit
      
      const scales = [
        {
          key: 'small',
          value: this.getFitPercentageRoundNum(percentOverallFit.small)
        },
        {
          key: 'trueSize',
          value: this.getFitPercentageRoundNum(percentOverallFit.true_size)
        },
        {
          key: 'large',
          value: this.getFitPercentageRoundNum(percentOverallFit.large)
        }
      ]
      const maxObj = scales.reduce((max, item) => (item.value > max.value ? item : max), scales[0]) || {}
      
      let score = `<span class="goods-review__fit-strong">${maxObj.value || 0}%</span>`
      let keyword = ''
      let targetContent = ''
      const result = {
        showIcom: false,
        text: ''
      }
      if(maxObj.key === 'trueSize'){
        if(maxObj.value >= 50){
          result.showIcom = true
        }
        keyword = this.language.SHEIN_KEY_PWA_15001
        targetContent = template(score, this.language.SHEIN_KEY_PWA_32065)
      }else if(maxObj.key === 'small'){
        keyword = this.language.SHEIN_KEY_PWA_15002
        targetContent = template(score, this.language.SHEIN_KEY_PWA_32157)
      }else if(maxObj.key === 'large'){
        keyword = this.language.SHEIN_KEY_PWA_15003
        targetContent = template(score, this.language.SHEIN_KEY_PWA_32156)
      }
      const regex = new RegExp(keyword, 'gi')
      // 匹配keyword，并将其加粗
      const replacedContent = targetContent.replace(regex, (match) => `<span class="goods-review__fit-strong">${match}</span>`)
      result.text = replacedContent
      return result
    },
    getReviewCardAnalysisData() {
      if(!this.isDetail){
        return {}
      }
      return { 
        id: '1-6-7-61', 
        data: {
          is_rating: this.commentRankAverage ? '1' : '0',
          is_local_review: this.localRatingShow ? '1' : '0',
          is_true_to_size: this.hasTrueToSize && this.hasFit ? '1' : '0',
          is_top_ranking: this.isShowRank ? '1' : '0',
          is_sub_rating: this.currentMultiDimeRate.length ? '1' : '0',
        }
      }
    },
  },
  mounted() {
    // this.exposeLocalReviews()
    this.getApolloConfig()
  },
  methods: {
    getFitPercentageRoundNum(text) {
      if (typeof text !== 'string' || !text) {
        return 0
      }
      const num = text.replace('%', '')
      if (isNaN(num)) {
        return 0
      }
      return Math.round(num)
    },
    clickRating() {
      daEventCenter.triggerNotice({
        daId: '1-6-7-60',
        extraData: {
          location: 'Rating'
        }
      })
    },
    clickLocalHandle() {
      daEventCenter.triggerNotice({
        daId: '1-6-7-60',
        extraData: {
          location: 'Local review'
        }
      })
      // daEventCenter.triggerNotice({
      //   daId: '1-6-7-43',
      //   extraData: {
      //     status: this.hasLocalComments ? 'on' : 'unable'
      //   }
      // })
    },
    // exposeLocalReviews() {
    //   if (!this.showLocalReviews || !this.isDetail) return
    //   const { globalRating, localRating } = this
    //   daEventCenter.triggerNotice({
    //     daId: '1-6-7-42',
    //     extraData: {
    //       global_rating: globalRating,
    //       local_rating: localRating
    //     }
    //   })
    // },
    // 跳转至评分说明页
    jumpToRateExplanation() {
      const { rateExplanationLink } = this
      if (!rateExplanationLink) {
        return
      }
      // 埋点上报
      daEventCenter.triggerNotice({ daId: '1-6-2-56' })
      markPoint('toNextPageClick', 'public')
      // 跳转
      window.location.href = rateExplanationLink
    },
    // 多维评分标签曝光事件
    getRateTagAnalysisData() {
      const catId = this.detail?.cat_id || ''
      if (!catId) {
        return {}
      }
      return { id: this.isDetail ? '1-6-7-53' : '1-6-2-53', data: { cat_id: catId } }
    },
    async getApolloConfig() {
      let res = await schttp({
        method: 'POST',
        url: '/api/config/apolloConfig/query',
        data: { apolloKeys: 'SIZE_TIPS_CONFIG' }
      })
      const { SIZE_TIPS_CONFIG } = res?.info ?? {}
      this.commentRankBaseline = SIZE_TIPS_CONFIG?.commentRankValue || 20

      this.$emit('getIsShowRank', this.isShowRank)
    },
  }
}
</script>

<style lang="less">
/* stylelint-disable  selector-max-specificity, selector-max-type */
.goods-review__rate {
  // margin: 0.16rem 0.32rem 0.375rem 0.32rem;
  // position: relative;
  .goods-review__rate-content{
    padding: 0.32rem 0.426667rem;
    .flexbox();
    flex-flow: row wrap;
    justify-content: space-between;
    background-color: #f6f6f6;
  }
  &.bottom-space {
    // padding-bottom: 1.386667rem;
    box-shadow: 0 0.053333rem 0.106667rem 0 rgba(144, 144, 144, 0.3);
    /* rw:begin */
    box-shadow: none;
  }

  [class*='icon_star'] {
    display: inline-block;
    color: @sui_color_honor;
  }

  [class*='sui_icon_star'] {
    /* rw:begin */
    font-size: 0.4rem !important; /* stylelint-disable-line declaration-no-important */
  }

  // 总评分
  .rate-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // 左侧星级块
    .star-box {
      display: flex;
      align-items: center;
    }
    .star-num {
      .font-dpr(44px);
      font-weight: 700;
      line-height: 1.1;
      color: #222;
      margin-right: 0.16rem;
      /* rw:begin */
      color: @sui_color_honor;
      font-family: 'Adieu';
    }
    .star-html {
      white-space: nowrap;
    }
    .star-info {
      margin-left: 0.16rem;
      .font-dpr(28px);
      color: @sui_color_gray_light2;
    }
    // 排名百分比
    .rank-box {
      color: #000;
      font-family: "SF Pro";
      font-size: 0.26667rem;
      line-height: normal;
      padding-top: 0.11rem;
      .rank-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .r-color {
        color: #a86104;
        font-weight: 700;
      }
      .rank-progress {
        display: block;
        width: 3.52rem;
        height: 0.10667rem;
        flex-shrink: 0;
        border-radius: 0.05333rem;
        background: #E5E5E5;
        margin: 0.21rem 0 ;
        .rank-progress-rate {
          height: 100%;
          display: block;
          position: relative;
          background: linear-gradient(90deg, #EAC99C 0%, #CC8C39 100%);
          border-radius: 0.05333rem;
          .icon {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateY(calc(-50% + 0.10667rem/2));
            width: 0.42667rem;
            height: 0.42667rem;
            background: #A86104;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #fff;
            .suiiconfont {
              font-size: 10px;
            }
          }
        }
      }
    }
    .rank-box-right {
      width: 3.70667rem;
    }
    // 中间分割线
    .vert-split-box {
      height: 100%;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.64rem;
      .vert-split-line {
        height: 100%;
        width: 0;
        border-left: 0.013333rem solid @sui_color_gray_weak1;
      }
    }

    // 右侧多维评分块
    .multi-rate-box {
      overflow: hidden;
      &.full-width {
        width: 100%;
        padding-left: 0;
      }
    }
    .multi-rate-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .font-dpr(24px);
      color: @sui_color_gray_dark2;
      margin-top: 0.16rem;
      line-height: 0.37333rem;
      &:first-child {
        margin-top: 0;
      }

      span {
        display: inline-block;
        width: 2.5333rem;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .num {
        text-align: right;
        padding-left: 0.426667rem;
        font-weight: 590;
        color: #000;
      }
    }
  }

  .rate-box.is-pop-win {
    .vert-split-box {
      /* rw:begin */
      display: none;
    }

    .star-html {
      transform: perspective(400px);
    }
  }

  // 中间分割线
  .middle-line {
    width: 100%;
    border-bottom: .013333rem solid #e5e5e5;
    margin-top: 0.32rem;
  }
  // 尺码匹配评分
  .fit-box {
    flex: 1;
    color: @sui_color_gray_dark2;
    margin-top: 0.32rem;
    padding-bottom: 0.106667rem;
    .font-dpr(20px);

    .fit-rate-box {
      display: flex;
      align-items: flex-end;
    }

    li {
      flex: 1;
      margin-left: 0.64rem;
      &:first-child {
        margin-left: 0;
      }
    }

    .fit-rate-proress {
      display: flex;
      align-items: center;
      font-family: "SF Pro";
    b {
      display: block;
      width: 24px;
      font-weight: normal;
      .font-dpr(20px);
      color:#000;
      font-family: "SF Pro";
      font-size: 0.26667rem;
      font-style: normal;
      font-weight: 590;
      line-height: normal;
      padding-left: 0.11rem;
    }

    span {
      display: block;
      width: 100%;
      height: 0.106667rem;
      margin-top: 0.05rem;
      background: #e5e5e5;
      border-radius: 0.053333rem;
      overflow: hidden;

      i {
        height: 0.106667rem;
        display: block;
        background: #222;
        /*rw:begin*/
        background: @sui_color_honor;
      }
    }
  }

    em {
      display: block;
      width: 2rem;
      margin-top: 0.106667rem;
      .font-dpr(20px);
      white-space: normal;
      color: #000;
      font-family: "SF Pro";
    }

  }
  // TODO-新样式全量后删除
  // 尺码匹配评分-旧样式
  .fit-box-vertical {
    margin-top: 0.32rem;
    padding-bottom: 0.106667rem;
    width: 100%;

    .title {
      color: #767676;
      padding-bottom: 0.11rem;
    }

    .fit-rate-box {
      width: 100%;
    }
    .fit-rate-proress {
      display: flex;
      align-items: center;
      font-family: "SF Pro";
    li {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      color: #333;
      padding-bottom: 0.106667rem;
    }
    em {
      display: inline-block;
      width: 2rem;
      color: #333 /*rw:#222*/;
      .font-dpr(22px);
      white-space: normal;
      vertical-align: middle;
      color:#000;
    }
    span {
      // width: calc(100% - 2rem - 24px);
      width: 100%;
      height: 0.21rem;
      background: #e5e5e5;

      i {
        height: 0.21rem;
        display: block;
        background: #222;
        /*rw:begin*/
        background: @sui_color_honor;
      }
    }
    b {
      width: 24px;
      font-weight: normal;
      color: #333;
      margin-left: 0.21333rem;
    }
  }
  }

  // 底部本地评分栏
  .local-rating-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 0.32rem;
    height: 1.066667rem;
    width: 100%;
    // position: absolute;
    // left: 0;
    // bottom: 0;
    .font-dpr(24px);
    background-color: #fff;

    .star-box {
      display: flex;
      align-items: center;

      em {
        display: block;
        margin: 0 0.106667rem;
        font-weight: 700;
      }
    }

    .view-button {
      font-weight: 600;
      color: @sui_color_gray_dark2;
    }

    .split-line {
      display: none;
      /* rw:begin */
      display: block;
      position: absolute;
      top: 0;
      left: 0.32rem;
      width: calc(100% - 0.64rem);
      height: 0.013333rem;
      background-color: @sui_color_gray_weak1;
    }
  }

  /* rw:begin */
  background-color: #fff;
  border-radius: 0.266667rem;
  border: 0.013333rem solid rgba(0, 0, 0, 0.08);
}
.goods-review__rate-new{
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  
  margin: 0.27rem 0.32rem 0.32rem!important; /* stylelint-disable-line declaration-no-important */
  &.bottom-space{
    box-shadow: none;
  }
  .goods-review{
    &__rate-content{
      padding: 0.27rem 0.32rem;
      background: #fff!important; /* stylelint-disable-line declaration-no-important */
    }
    &__rankBox-new{
      display: flex;
      margin-top: 0.05rem;
    }
    &__like-icon{
      width: 0.42667rem;
      height: 0.42667rem;
      background: #EAAB00;
      border-radius: 50%;
      .flexbox();
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
    }
    &__rank-text{
      font-size: 12px;
      margin-left: 0.10667rem;
      .line-camp(2);
      max-width: 3.25333rem;
      .r-color{
        color: #EAAB00;
        font-weight: 700;
      }
    }

    &__fitBox-max{
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 0.27rem;
      .line-camp(1);
    }
    &__fit-max-text{
      margin-left: 0.05rem;
      color: #666;
    }
    &__fit-strong{
      font-weight: 590;
      color: #000;
    }
    &__fix-type{
      font-weight: 590;
    }
    &__fitBox-both{
      display: flex;
      align-items: flex-end;
      width: 100%;
      color: #000;
      margin-top: 0.27rem;
    }
    &__fit-label{
      font-size: 11px;
      font-weight: 590;
      max-width: 1.5733rem;
      .line-camp(2);
    }
    &__fitBox-rate{
      display: flex;
      align-items: flex-end;
    }
    &__rateItem{
      margin-left: 0.75rem;
      flex: 1;
      &:first-child {
        margin-left: 0.21rem;
      }
    }
    &__rate-label{
      max-width: 1.8133rem;
      .line-camp(2);
      font-size: 9px;
      font-weight: 510;
    }
    &__rateProgress{
      display: flex;
      align-items: center;
      font-size: 11px;
      b {
        .font-dpr(22px);
        color:#000;
        font-weight: 590;
        padding-left: 0.11rem;
      }
    }
    &__percentage{
      display: block;
      width: 1.06667rem;
      height: 0.106667rem;
      margin-top: 0.05rem;
      background: #e5e5e5;
      border-radius: 0.10667rem;
      overflow: hidden;
    }
    &__bar{
      height: 0.106667rem;
      display: block;
      background: #000;
      /*rw:begin*/
      background: @sui_color_honor;
    }
    &__local-rating{
      height: 0.82667rem;
      background: #F6F6F6;
      &.star-box{
        font-weight: 510;
        color: #000;
      }
      &.view-button{
        font-weight: 590;
        color: @sui_color_gray_dark2;
      }
    }
  }
}
</style>
