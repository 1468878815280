<template>
  <span
    class="sign-board-flash__discount"
  >
    <i
      class="flash-item-icon suiiconfont sui_icon_flashsale_15px"
    ></i>
    <span class="sign-board-flash__discount-text">{{ flashDiscount }}</span>
  </span>
</template>

<script>
const { GB_cssRight } = gbCommonInfo || {}

export default {
  name: 'FlashProductDiscount',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    flashDiscount() {
      let discountText = this.item?.pretreatInfo?.discountInfo?.unitDiscountString || ''
      if(GB_cssRight) {
        discountText = discountText.replace(/(-)(\d+(\.\d+)?)(%)/, '$4$2$1')
      }
      return discountText
    },
    
  },
}
</script>

<style lang="less">
.sign-board-flash {
  &__discount {
    position: absolute;
    left: 0;
    min-width: .6933rem /* 26/37.5 */;
    padding: .0533rem .04rem;
    top: 0;
    z-index: 1;
    background: @sui_color_flash;
    color: #000;
    text-align: center;
    line-height: 1;
  }
  
  &__discount-text {
    display: block;
    line-height: 1.25;
    font-weight: 700;
    font-size: 8px;
  }
}
[mir=rtl] {
  .sign-board-flash__discount {
    direction: ltr;
  }
}
</style>
