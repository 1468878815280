<template>
  <div
    class="details-pic j-details-pic"
    data-floor-tab="details"
  >
    <div class="details-pic__wrapper">
      <div>
        {{ content.text.title }}
        <span>({{ content.imgs.length }})</span>
      </div>
    </div>
    <div
      class="details-pic__imgs"
      :class="{ fold: value }"
    >
      <img
        v-for="(item, index) in content.imgs"
        :key="item.image_url"
        :src="LAZY_IMG"
        :data-src="item.image_url || LAZY_IMG"
        data-design-width="0"
        class="lazyload"
        alt="Details Pictures"
        da-event-click="1-6-1-35"
        @click="openBigPic(item, index)"
      />
      <div
        v-if="value"
        class="cover"
      ></div>
    </div>
    <div
      ref="view"
      class="details-pic__view"
      @click="handleView"
    >
      {{ value ? content.text.more : content.text.less }}
      <span :class="['iconfont', value ? 'icon-down' : 'icon-shang']"></span>
    </div>

    <BigPicSlidePage
      v-if="lazyLoaded"
      v-model="bigPicShow"
      :images="content.imgs"
      :index="bigPicIndex"
      :total="content.imgs.length"
    />
  </div>
</template>
<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-6-6' })
const { LAZY_IMG } = gbCommonInfo
export default {
  name: 'DetailsPictures',
  components: {
    BigPicSlidePage: () =>
      import(
        /* webpackChunkName: "BigPicSlidePage" */ 'public/src/pages/goods_detail_v2/innerComponents/drawer/BigPicSlidePage/index.vue'
      ),
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      LAZY_IMG,
      bigPicShow: false,
      bigPicIndex: 0,
      lazyLoaded: false
    }
  },
  methods: {
    handleView() {
      if (this.value) {
        daEventCenter.triggerNotice({
          daId: '1-6-6-5',
        })
        this.$emit('input', false)
      } else {
        const top = this.$refs.view.getBoundingClientRect().top
        this.$emit('input', true)
        this.$nextTick(() => {
          window.scrollTo(0, this.offset(this.$refs.view) - top)
        })
      }
    },
    offset(element) {
      let top = 0
      let parents = element.offsetParent

      top += element.offsetTop

      while (parents && !/html|body/i.test(parents.tagName)) {
        top += parents.offsetTop
        parents = parents.offsetParent
      }
      return top
    },
    async openBigPic(item, index, isNew) {

      if (!this.lazyLoaded) {
        this.lazyLoaded = true
        await this.$nextTick()
      }

      if (this.value && !isNew) {
        this.handleView()
      } else {
        this.bigPicIndex = index
        this.bigPicShow = true
      }
    },
  },
}
</script>

<style lang="less">
.details-pic {
  background: #fff;
  margin-top: 0.213333rem;
  padding-bottom: 0.2667rem;
  &__wrapper {
    padding-top: 0.267rem;
    margin: 0 0.32rem;
    .flexbox;
    justify-content: space-between;
    line-height: 0.51rem;
    .font-dpr(28px);
    font-weight: bold;
    /* rw:begin */
    font-family: 'Adieu';
  }
  &__imgs {
    position: relative;
    padding: 0.16rem 0.32rem 0;
    &.fold {
      // max-height: 6.24rem;
      max-height: calc(9.12rem - 0.988rem);
      overflow: hidden;
    }
    img {
      display: block;
      width: 100%;
      margin: 0 0 0.106667rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .cover {
      position: absolute;
      bottom: 0;
      left: 0 /*rtl:ignore*/;
      right: 0 /*rtl:ignore*/;
      height: 100px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.73) 58%,
        #ffffff 100%
      );
    }
  }
  &__view {
    height: 0.987rem;
    .font-dpr(24px);
    text-align: center;
    // line-height: 0.987rem;
    line-height: calc(0.988rem + 18px);
    color: var(--sui-color-gray-dark-3, #767676);
    span {
      .margin-l(0.106667rem);
      color: inherit;
    }
  }
}
</style>
