import { getImageRatio } from 'public/src/pages/goods_detail/utils/common.js'
import { mapActions } from 'vuex'
import { getExposeBoxData } from './utils'
/**
 * buybox mixin
 */
export const getBuyBoxMixins = (options = {}) => {
  const { isPopup } = options
  return {
    computed: {
      cRenderBindData() {
        return getExposeBoxData({
          isPopup,
          feed_type: '2',
          poskey: 'BuyBoxSwitch'
        })
      },
    },
    methods: {
      ...mapActions('newProductDetail/common', ['setGoodsDetailScroll']),
      async handleQuickAdd(payload) {
        const { target, index } = payload
        const imgRatio = await getImageRatio(payload?.goods_img)
        this.$quickAdd.open({
          goods_id: payload.goods_id,
          imgRatio,
          mallCode: payload.mall_code,
          index,
          addSource: 'detailPage',
          showBestDealLabel: true,
          showFollowBeltByOrigin: true,
          showEstimatedPrice: !this.isHitComplianceMode, // 外部开启到手价 @Key Wang 数据源混乱
          isClickToDetail: !payload._formatInfo.isMainGoods, // 主商品隐藏跳转
          analysisConfig: {
            sourceTarget: target,
            sa: {
              activity_from: 'buy_box',
              location: isPopup ? 'popup' : 'page',
              feed_type: '2',
            },
            style: 'detail'
          },
          clickCallBack: {
            // 加车成功后的回调
            complete: () => {
              if(isPopup) this.onDrawerClose()
              setTimeout(() => {
                // this.setGoodsDetailScroll()
                window.vBus &&
                  window.vBus.$emit('triggerAddCompletedFloatCart', {
                    animation: false
                  })
              }, 2000)
            }
          }
        })
      },
      reportOpenQuickView(target) {
        daEventCenter.triggerNotice({
          daId: '2-3-5',
          target: target,
          extraData: {
            activity_from: 'buy_box',
            style: 'popup',
            feed_type: '2',
          }
        })
      },
    }
  }
}
