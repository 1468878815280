<template>
  <div
    class="review-item"
    :class="{ 'j-expose__comment-item__con': analysis }"
    v-bind="analysis"
  >
    <div
      class="review-item__list"
      :class="[`review-item__${position}`]"
    >
      <!-- Base Message -->
      <CommentBase :mark-single-local-comment="markSingleLocalComment" />
      <!-- Inner -->
      <CommentInner 
        :position="position" 
        :reviewPhotoType="reviewPhotoType"
        :reviewText="reviewText"
      />
      <!-- 多维评分 -->
      <MultiRate
        v-if="!isOutStoreReview && !isSlideStyle"
        :comment-position="commentPosition || 0"
        :cat-id="catId"
        :position="position"
      />
      <!-- Member Size -->
      <template v-if="showCommentSize">
        <CommentSizeNew />
      </template>
      <!-- Like -->
      <CommentAction v-if="showLike || showReport || showSameFlag" />
    </div>
    <!-- Reply -->
    <CommentReply v-if="showReply && !isOutStoreReview" />
  </div>
</template>

<script>
import CommentBase from './components/CommentBase'
import CommentInner from './components/CommentInner'
import CommentSizeNew from './components/CommentSizeNew'
import CommentAction from './components/CommentAction'
import CommentReply from './components/CommentReply'
import MultiRate from './components/MultiRate'
import Props from './utils/props'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-23' })
daEventCenter.addSubscriber({ modulecode: '1-6-7' })
daEventCenter.addSubscriber({ modulecode: '1-6-2' })

export default {
  name: 'CommonComment',
  components: {
    CommentBase,
    CommentSizeNew,
    CommentAction,
    CommentInner,
    CommentReply,
    MultiRate
  },
  props: Props,
  data() {
    return {
      comment: {},
      showMore: false,
      reportPrams: {
        showReport: false,
        commentId: ''
      }
    }
  },
  computed: {
    isOutStoreReview() {
      const { commentData } = this
      return commentData.isOutStoreReview
    },
    isNewSizeComp() {
      // 新BFF出参下的接口
      return !!this.comment?.isNewSizeComp
    },
    showCommentSize () {
      const outerNotShow = this.position === 'outer' && ['SimpleVersion1', 'SimpleVersion2'].includes(this.reviewText)
      return this.showMemberSize && !this.isOutStoreReview &&  !outerNotShow
    },
  },
  watch: {
    commentData: {
      immediate: true,
      handler(newVal) {
        this.comment = newVal
        // 评论详情页通过innerSizeIsFold控制是否折叠
        if(this.position === 'inner') {
          this.showMore = !this.innerSizeIsFold
        }
      }
    }
  },
  provide() {
    return {
      COMMENT_INSTANCE: this
    }
  },
  methods: {
    // 事件派发
    EVENT_CENTER(key, data) {
      this.$emit(key, data)
    },
    UPDATE_STATE(key, val) {
      this[key] = val
    },
  }
}
</script>

<style lang="less" src="./style/index.less"></style>
