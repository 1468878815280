/* eslint-disable require-atomic-updates */
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import { stringifyQueryString } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import schttp from 'public/src/services/schttp'
import { isLogin } from 'public/src/pages/common/utils/index.js'
daEventCenter.addSubscriber({ modulecode: '1-6-7' })
daEventCenter.addSubscriber({ modulecode: '1-6-2' })

export default {
  computed: {
    ...mapGetters('productDetail/common', [
      'goods_id',
      'mall_code',
    ]),
    ...mapGetters('productDetail/Reviews', [
      'translate_language',
    ]),

    ...mapState('productDetail/CommentPopup', ['curr_translate_lang', 'default_lang',]),
    ...mapGetters('productDetail/CommentPopup', ['currentFilterGoodsSn']),
  },
  data() {
    return {
      likeLoading: false,
      showReport: false,
      reportCommentId: null, // 举报
      reportSkc: null
    }
  },
  methods: {
    ...mapState('newProductDetail/common', ['goodsReady']),
    ...mapMutations('newProductDetail/common', ['updateIsLoginChange']),
    ...mapMutations('productDetail/CommentPopup', [
      'change_view_comment_pop_ready',
      'update_curr_translate_lang',
      'update_translate_model',
      'update_comment_type_for_img',
      'update_curr_comment_id_info',
      'update_show_review_imgbox',
      'change_comment_data_for_like'
    ]),
    ...mapActions('productDetail/CommentPopup', ['fetch_comment_translate']),
    ...mapActions('productDetail/common', ['show_login_modal']),

    /**
     * 图片点击
     */
    imageClickHandle({ report_id, comment_id, index }, position) {
      this.updateCommentInfoHandle({ comment_id, index }, position)
      // 需要更新弹CommentPop Ready状态
      this.change_view_comment_pop_ready()
      this.$nextTick(() => {
        this.update_show_review_imgbox(true)
      })

      if (report_id) {
        daEventCenter.triggerNotice({
          daId: position === 'inner' ? '1-6-2-28' : '1-6-7-28',
          extraData: {
            freetrial_id: report_id
          }
        })
      } else {
        // 评论详情页不再上报1-6-2-3
        if(position === 'inner') return 
        daEventCenter.triggerNotice({
          daId: position === 'inner' ? '1-6-2-3' : '1-6-7-3',
          extraData: {
            review_location: position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail'
          }
        })
      }
    },
    /**
     * 更新当前评论信息
     * @param {Object} commentInfo
     * @param {*} type
     */
    updateCommentInfoHandle({ comment_id, index }, type) {
      // this.update_comment_type_for_img(type === 'outer' ? 'outer' : 'inner')
      this.update_comment_type_for_img(type || 'inner')
      this.update_curr_comment_id_info({
        currClickCommentId: comment_id,
        currClickCommentIndex: index
      })
    },
    /**
     * type: 1: 商详 / 2: 试用
     */
    getLikeReportUrl(type, comment_id, thumbs_up) {
      let url
      switch (type) {
        case 1:
          url = `/product/like_goods_review?${stringifyQueryString({
            queryObj: { comment_id, thumbs_up, }
          })}`
          break
        case 2:
          url = `/api/productInfo/likeReport/update?${stringifyQueryString({
            queryObj: { report_id: comment_id, action: thumbs_up }
          })}`
          break
      }
      return url
    },
    showLogin(params = {}) {
      const { cb } = params
      if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein') {
        this.show_login_modal({
          params: {
            from: 'other',
            reload: true,
          },
          cb: async () => {
            if (cb) {
              await cb()
            }
          },
        })
        return
      }
      SHEIN_LOGIN.show({
        show: true,
        from: 'other',
        reload: true,
        cb: () => {
          this.updateIsLoginChange(true)
          if (isLogin()) {
            this.$store.dispatch('newProductDetail/asyncHotModles', {
              goods_id: this.goods_id,
              mallCode: this.mall_code
            })
          }
          cb && cb()
        }
      })
    },
    /**
     * 点赞
     * @param {*} cb
     */
    async likeClickHandle({}, commentData, position) {
      if (this.likeLoading) return
      const currCommentId = commentData.comment_id
      const currStatus = commentData.current_member_zan == 1 ? 1 : 0
      let currThumbsUp = -1
      let url = ''
      let type = !~currCommentId.toString().indexOf('rp') ? 1 : 2
      if (!isLogin()) {
        // 未登录
        if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein') {
          this.showLogin({ 
            cb: async () => {
              await this.likeClickHandle({}, commentData, position)
            } 
          })
          return
        }
        this.showLogin()
        return
      } else {
        currThumbsUp = currStatus == 1 ? 0 : 1
      }

      this.likeLoading = true

      const useBffApi = true
      if (type === 1) {
        url = this.getLikeReportUrl(type, currCommentId, currThumbsUp)
      } else if (type === 2) {
        url = this.getLikeReportUrl(type, currCommentId.replace('rp', ''), currThumbsUp)
      }
      let data = {}
      let options = {
        url,
        useBffApi
      }
      if (type === 2) {
        options = {
          url: '/user/trial/like_report',
          params: {
            action: currThumbsUp,
            report_id: currCommentId.replace('rp', ''),
            sku: this.currentFilterGoodsSn
          },
          useBffApi,
          method: 'POST'
        }
      }
      data = await schttp(options)
      this.likeLoading = false
      if (data.code == 0) {
        this.change_comment_data_for_like({
          comment_id: currCommentId,
          params: {
            current_member_zan: +currStatus === 1 ? 0 : 1,
            memberIsLike: +currStatus === 1 ? 0 : 1,
            like_num:
              +currStatus === 1
                ? Number(commentData.like_num < 1 ? 1 : commentData.like_num) - 1
                : Number(commentData.like_num) + 1
          }
        })

        if (currStatus == 0) {
          daEventCenter.triggerNotice({
            daId: `1-6-${position === 'inner' ? '2' : '7'}-${commentData.reportId ? '27' : '4'}`,
            extraData: {
              is_cancel: 0,
              review_location: position === 'inner' ? 'page_review' : 'page_goods_detail'
            }
          })
        } else {
          daEventCenter.triggerNotice({
            daId: `1-6-${position === 'inner' ? '2' : '7'}-${commentData.reportId ? '27' : '4'}`,
            extraData: {
              is_cancel: 1,
              review_location: position === 'inner' ? 'page_review' : 'page_goods_detail'
            }
          })
        }
      } else if (data.code == 200728 || data.code == 300206 || (useBffApi && data.code === '00101110')) {
        if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein') {
          return this.showLogin({ 
            cb: async () => {
              await this.likeClickHandle({}, commentData, position)
            } 
          })
        }
        return this.showLogin()
      }
    },
    /**
     * 更新当前翻译语种
     * @param {*} translate_lang
     */
    updateTranslateLang(translate_lang) {
      this.update_curr_translate_lang(translate_lang)
    },
    /**
     * 更新翻译 Model
     * @param {*} params
     * @param {*} position
     */
    updateTranslateModelHandle(params, position) {
      const { comment_id, comment_lang, content, reportId, isOutStoreReview } = params
      this.update_translate_model({
        show: true,
        lang: '',
        comment_lang,
        cb: () => {
          this.fetch_comment_translate({
            content,
            comment_id,
            comment_lang,
            position,
            isOutStoreReview
          })
          daEventCenter.triggerNotice({
            daId: `1-6-${position === 'inner' ? '2' : '7'}-${reportId ? '25' : '15'}`,
            extraData: {
              review_id: comment_id,
              translate_language: this.curr_translate_lang || this.default_lang,
              type: 'single',
              activity_from: position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail'
            }
          })
        }
      })

      const daId = reportId ? `1-6-${position === 'inner' ? '2' : '7'}-23` : `1-6-${position === 'inner' ? '2' : '7'}-13`
      const extraData = {
        review_id: comment_id,
        translate_language: this.curr_translate_lang || this.default_lang,
        activity_from: position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail'
      }
      if (!reportId) {
        extraData.OutReview = isOutStoreReview ? 1 : 0
        extraData.type = 'single'
      }
      daEventCenter.triggerNotice({ daId, extraData })
    },
    /**
     *
     * @param {Object} params
     * @param {String} position
     */
    fetchCommentTranslateEvent(params, position) {
      const { content, comment_id, comment_lang, reportId, curr_lang, isOutStoreReview } = params
      this.fetch_comment_translate({
        content,
        comment_id,
        comment_lang,
        position,
        curr_lang,
        isOutStoreReview
      })
      const positionIndex = position === 'inner' ? '2' : '7'
      const daId = reportId ? `1-6-${positionIndex}-23` : `1-6-${positionIndex}-13`
      const extraData = {
        review_id: comment_id,
        translate_language: this.curr_translate_lang || this.default_lang,
        activity_from: this.position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail'
      }
      if (!reportId) {
        extraData.OutReview = isOutStoreReview ? 1 : 0
        extraData.type = 'single'
      }
      daEventCenter.triggerNotice({ daId, extraData })
    },
    /**
     * 点击翻译
     * @param {Object} params
     * @param {String} position
     */
    clickTranslateHandle(params, position) {
      console.log(this.$store)
      // 需要更新弹CommentPop Ready状态
      this.change_view_comment_pop_ready()
      let curr_translate_lang
      const { report_id, comment_id, comment_lang, content, comment_el, isOutStoreReview } = params
      window.$commentel = comment_el

      if (!this.curr_translate_lang) {
        const translate_lang = localStorage.getItem('translate_lang') || ''
        if (translate_lang) {
          this.updateTranslateLang(translate_lang)
        }
      }

      if (!this.translate_language.map(item => item.translate_language)?.includes(this.curr_translate_lang)) {
        if (this.translate_language.length > 1) {
          this.updateTranslateModelHandle(
            {
              comment_id,
              comment_lang,
              content,
              reportId: report_id,
              isOutStoreReview
            },
            position
          )
          return
        } else {
          curr_translate_lang = this.translate_language[0]?.translate_language
        }
      }

      this.fetchCommentTranslateEvent(
        {
          content,
          comment_id,
          comment_lang,
          reportId: report_id,
          curr_lang: curr_translate_lang,
          isOutStoreReview
        },
        position
      )
    },
    closeTranslateHandle(params, position) {
      const reportId  = params || ''
      daEventCenter.triggerNotice({
        daId: `1-6-${position === 'inner' ? '2' : '7'}-${reportId ? '26' : '12'}`
      })
    },
    changeTranslateLanguage(params, position) {
      const {
        translate_lang,
        content,
        comment_lang,
        report_id,
        comment_id,
        cb,
        isOutStoreReview
      } = params
      if (!report_id) {
        daEventCenter.triggerNotice({
          daId: `1-6-${position === 'inner' ? '2' : '7'}-11`,
          extraData: {
            review_location: position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail',
            OutReview: isOutStoreReview ? 1 : 0
          }
        })
      }
      report_id &&
        daEventCenter.triggerNotice({
          daId: `1-6-${position === 'inner' ? '2' : '7'}-24`
        })
      this.update_translate_model({
        show: true,
        lang: translate_lang,
        comment_lang,
        cb: () => {
          this.fetch_comment_translate({
            content,
            comment_id,
            comment_lang,
            position,
            isOutStoreReview,
            cb
          })
          daEventCenter.triggerNotice({
            daId: `1-6-${position === 'inner' ? '2' : '7'}-${report_id ? '25' : '15'}`,
            extraData: {
              review_id: comment_id,
              translate_language: this.curr_translate_lang || this.default_lang,
              type: 'single',
              activity_from: position === 'inner' ? 'page_goods_reviews' : 'page_goods_detail'
            }
          })
        }
      })
    },
    /**
     * revert
     * @param {*} param
     */
    showOriginComment({ showOrigin, review_id }, position) {
      daEventCenter.triggerNotice({
        daId: `1-6-${position === 'inner' ? '2' : '7'}-40`,
        extraData: {
          showOrigin,
          review_id
        }
      })
    },
    /**
     * 内容展开
     * @param {*} status
     */
    contentShowAllHandle({ status }, position) {
      status &&
        daEventCenter.triggerNotice({
          daId: `1-6-${position === 'inner' ? '2' : '7'}-34`
        })
    },
    /**
     * 会员尺码
     * @param {String} status
     */
    sizeShowAllHandle({ review_id }, position) {
      if(position === 'inner') {
        daEventCenter.triggerNotice({
          daId: '1-6-2-92',
          extraData: {
            type: review_id
          }
        })
        return
      }
      daEventCenter.triggerNotice({
        daId: '1-6-2-33'
      })
    }
  }
}
