<template>
  <div
    v-if="showSHEINer"
    ref="SHEINerZone"
    v-expose="{ id: '1-6-2-65',data: { Imgnum: allCustomerGallerySum}}"
    class="sheiner-zone"
    @click.stop
    @touchend="touchChange()"
  >
    <div
      v-if="!hiddenTitle"
      class="sheiner-zone__title"
      tabindex="1"
      @click="openSHEINer(-1)"
    >
      <div
        ref="SHEINerTitle"
        class="sheiner-zone__sub-title"
      >
        <template v-if="!isEllipsis">
          {{ language.SHEIN_KEY_PWA_31776 }}
        </template>
        <template v-else>
          {{ language.SHEIN_KEY_PWA_31777 }}
        </template>
      </div>
      <div>({{ allCustomerGallerySum }})</div>
    </div>
    <div
      :class="{
        'products-box-scroll': true,
        'products-box-scrollSingleLine': true
      }"
    >
      <div 
        ref="SHEINerScrollBox"
        class="scroll-box"
      >
        <!-- 商品项 -->
        <div
          v-for="(item, index) in customerShowList.slice(0, 10)"
          :key="item.comment_id"
          v-expose="getExpoedData(item, index)"
          class="sheiner-zone__item_wrapper"
          :class="{
            'sheiner-zone__item_wrapper_mid': [
              'MiddlePic',
              'MiddlePic_MoveBack',
              'MiddlePic_WithReview'
            ].includes(reviewAbt.galleyLayout),
            'sheiner-zone__item_wrapper_small': [
              'SmallPic',
              'SmallPic_MoveBack',
              'SmallPic_WithReview'
            ].includes(reviewAbt.galleyLayout)
          }"
        >
          <img
            :data-src="transformImg({ img: item.firstImg })"
            :src="LAZY_IMG"
            data-design-width="200"
            class="lazy j-push-history-hash lazyload"
            :data-image-url="item.firstImg"
            :data-index="index + 1"
            @click="showReviewImg(index, item)"
          />
        </div>
        <div
          v-if="allCustomerGallerySum > 10 || showViewAll"
          class="sheiner-zone__mask"
          :class="{
            'sheiner-zone__item_wrapper_mid': [
              'MiddlePic',
              'MiddlePic_MoveBack',
              'MiddlePic_WithReview'
            ].includes(reviewAbt.galleyLayout),
            'sheiner-zone__item_wrapper_small': [
              'SmallPic',
              'SmallPic_MoveBack',
              'SmallPic_WithReview'
            ].includes(reviewAbt.galleyLayout)
          }"
          @click="openSHEINer(-1, 3)"
        >
          <i class="suiiconfont sui_icon_viewmore_right_40px"></i>
          <p class="sheiner-zone__view-more">
            {{ language.SHEIN_KEY_PWA_22253 }}
          </p>
        </div>
      </div>
    </div>
    <GalleryPopup
      v-if="galleryPopShowed"
      v-model="isShow"
      :language="language"
      :curId="curId"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import GalleryPopup from './GalleryPopup'
import commentMixin from 'public/src/pages/goods_detail/components/middle/innerComponents/Reviews/mixin/comment.js'
const { LAZY_IMG } = gbCommonInfo
import { daEventCenter } from 'public/src/services/eventCenter/index'
const POSITION = 'out-gallery'
export default {
  name: 'SHEINerZone',
  components: {
    GalleryPopup
  },
  mixins: [commentMixin],
  props: {
    mustShowTitle: {
      type: Boolean,
      default: false
    },
    hasPadding: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      LAZY_IMG,
      viewAllBtnClassName: 'view-all-handler',
      isShow: false,
      curId: '',
      isTouch: false,
      isEllipsis: false,
      showViewAll: false
    }
  },
  computed: {
    ...mapState('newProductDetail/common', ['customerShow']),
    ...mapState('newProductDetail/CommentPopup', ['galleryPopShowed']),
    ...mapGetters('newProductDetail/CustomerGallery', [
      'reviewAbt',
      'customerShowList',
      'allCustomerGallerySum',
      'showSHEINer'
    ]),
    ...mapGetters('newProductDetail', ['fsAbt']),
    ...mapGetters('newProductDetail/common', ['language']),
    hiddenTitle() {
      return !this.mustShowTitle && ['MiddlePic_WithReview', 'SmallPic_WithReview'].includes(
        this.reviewAbt.galleyLayout
      )
    },
    title() {
      return this.language.SHEIN_KEY_PWA_31776 + ` (${this.allCustomerGallerySum})`
    }
  },
  watch: {
    customerShow(newVal) {
      this.isShow = newVal
    }
  },
  mounted() {
    this.isEllipsis =
      this.$refs['SHEINerTitle']?.scrollWidth >
      this.$refs['SHEINerTitle']?.offsetWidth
    this.showViewAll = this.$refs['SHEINerScrollBox']?.scrollWidth > document.documentElement.clientWidth + 10
  },
  methods: {
    ...mapMutations('newProductDetail/common', ['updateCustomerShow']),
    ...mapMutations('newProductDetail/CommentPopup', [
      'updateCommentInfoDataForImg',
      'updateGalleryPopShowed'
    ]),
    showReviewImg(index, comment) {
      this.imageClickHandle(
        {
          report_id: comment.reportId,
          comment_id: comment.comment_id,
          index: 0 // 因为列表展示的都是第一张
        },
        POSITION
      )
      daEventCenter.triggerNotice({
        daId: '1-6-2-67',
        extraData: {
          click_type: 2,
        }
      })
      daEventCenter.triggerNotice({
        daId: '1-6-2-69',
        extraData: {
          review_location: 'page_goods_cusgallery',
          img_list: this.getExposeDataImgList(comment, index)
        }
      })
    },
    changeHash(value) {
      location.hash = value ? '#' + value : ''
    },
    // 弹出弹窗
    openSHEINer(id, type) {
      this.curId = id
      this.changeHash('customer-show')
      if (!this.galleryPopShowed) {
        // init
        this.updateCommentInfoDataForImg([])
      }
      this.updateGalleryPopShowed(true)
      this.updateCustomerShow(true)
      daEventCenter.triggerNotice({
        daId: '1-6-2-67',
        extraData: {
          click_type: type ? 3 : id === -1 ? 1 : 2
        }
      })
    },
    touchChange() {
      if (this.isTouch) return
      this.isTouch = true
      daEventCenter.triggerNotice({
        daId: '1-6-2-68'
      })
    },
    // 获取上报参数img_list
    getExposeDataImgList(item, index) {
      const { type = '', comment_id, reportId = '' } = item
      let eId = ''
      let typeNum = -1
      if (type === 'gallery') {
        item.imageType === 'review' ? typeNum = 4 : typeNum = 1
      } else if (reportId) {
        typeNum = 2
        eId = reportId
      } else {
        typeNum = 3
        eId = comment_id
      }
      return `${eId}\`${typeNum}\`${index + 1}`
    },
    getExpoedData(item, index) {
      const { type = '', comment_id, reportId = '' } = item
      let eId = ''
      let typeNum = -1
      if (type === 'gallery') {
        item.imageType === 'review' ? typeNum = 4 : typeNum = 1
      } else if (reportId) {
        typeNum = 2
        eId = reportId
      } else {
        typeNum = 3
        eId = comment_id
      }
      const img_list = `${eId}\`${typeNum}\`${index + 1}`
      const data = {
        id: '1-6-2-66',
        code: 'gallery',
        data: {
          img_list
        }
      }
      return data
    }
  }
}
</script>

<style lang="less">
.sheiner-zone {
  background: #fff;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 0.32rem;
  &__title {
    padding-top: 0.2667rem;
    margin: 0 0.32rem;
    .flexbox;
    justify-content: flex-start;
    line-height: 0.51rem;
    .font-dpr(28px);
    font-weight: bold;
    gap: 0.1067rem;
  }
  &__sub-title {
    // flex:1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__item_wrapper {
    width: 2.9733rem;
    height: 3.96rem;
    border-radius: none;
    &:not(:first-child) {
      margin-left: 0.1067rem;
    }
    &:first-child {
      margin-left: 0.32rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__mask {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 2.9733rem;
    height: 3.96rem;
    background: var(---sui_color_gray_weak2a, #fafafa);
    border-radius: 0.106666rem;
    margin-left: 0.21rem;
    margin-right: 0.32rem;
  }
  &__item_wrapper_mid {
    width: 2.0267rem;
    height: 2.0267rem;
    border-radius: 0.0533rem;
    overflow: hidden;
    > .sui_icon_viewmore_right_40px{
      line-height: 1.0667rem;
    }
    &:not(:first-child) {
      margin-left: 0.1067rem;
    }
  }
  &__item_wrapper_small {
    width: 1.3867rem;
    height: 1.3867rem;
    border-radius: none;
    > .sui_icon_viewmore_right_40px{
      font-size: 0.8533rem !important;
      line-height: 0.8533rem;
    }
    .sheiner-zone__view-more{
      font-size: 0.2677rem;
    }
    &:not(:first-child) {
      margin-left: 0.1067rem;
    }
  }
}
// 横滑推荐商品栏
.products-box-scroll {
  width: calc(100% + 0.64rem);
  overflow-x: scroll;
  margin-top: 0.16rem;
  display: flex;
  position: relative;
  left: -0.32rem;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .scroll-box {
    display: flex;
    position: relative;
    padding: 0 0.32rem;
  }
}
.products-box-scrollSingleLine {
  .scroll-box {
    flex-wrap: nowrap;
  }
  .product-item-ctn {
    margin-right: 0.213333rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
