<script lang="jsx">
import { daEventCenter } from 'public/src/services/eventCenter'
import TrendsLabel from 'public/src/pages/goods_detail_v2/innerComponents/common/TrendsLabel.vue'

export default {
  name: 'DescriptionEnter',
  functional: true,
  props: {
    boxStyle: {
      type: Object,
      default: () => {}
    },
    openDescriptionPopFn: {
      type: Function,
      default: () => {}
    },
    trendsInfo: {
      type: Object,
      default: () => ({})
    },
    goodsId: {
      type: [Number, String],
      default: ''
    },
    pointsData: {
      type: Object,
      default: () => ({})
    },
    descriptionName: {
      type: String,
      default: ''
    }
  },
  render(h, { props }) {
    const {
      trendsInfo,
      openDescriptionPopFn,
      boxStyle,
      goodsId,
      descriptionName,
      pointsData
    } = props
    const { points, has_sell_point } = pointsData
    const genTrendsInfoPoint = (domAttr = false) => {
      return trendsInfo
        ? {
          [`${domAttr ? 'data-' : ''}is_trend`]: trendsInfo?.display_title
            ? 1
            : 0,
          [`${domAttr ? 'data-' : ''}trend_word_id`]:
              trendsInfo?.trend_word_id,
          [`${domAttr ? 'data-' : ''}goods_id`]: goodsId
        }
        : {}
    }
    const analysisGoodsDetailDesc = {
      from: 1,
      Sellpoint: has_sell_point ? 1 : 0,
      ...genTrendsInfoPoint()
    }

    const symbol = '•'

    const clickSellingPoint = curSellingPoint => {
      if (!curSellingPoint.is_sell_point) return
      const { tag_id, tag_val_id, tag_val_name_en } = curSellingPoint
      daEventCenter.triggerNotice({
        daId: '1-6-1-38',
        extraData: {
          tag_id,
          tag_val_id,
          tag_val_name_en
        }
      })
    }
    const openDescriotion = () => {
      daEventCenter.triggerNotice({
        daId: '1-6-4-15',
        extraData: analysisGoodsDetailDesc
      })
      // 加载并展开 description 弹窗
      openDescriptionPopFn()
    }
    // 曝光埋点 desc-title-description
    return (
      <div style={boxStyle}>
        <div
          {...{ directives: [{ name: 'ada', value: { level: 0, pos: 2 } }] }}
          class="description-enter desc-title-description j-push-history-hash j-ga-description"
          data-from={analysisGoodsDetailDesc.from}
          data-sell-point={analysisGoodsDetailDesc.Sellpoint}
          state="description"
          role="text"
          aria-label={descriptionName}
          vOn:click={openDescriotion}
          {...{ attrs: genTrendsInfoPoint(true) }}
        >
          <div
            class="description-enter__title"
            aria-hidden="true"
          >
            {descriptionName}
          </div>
          <div class="description-enter__desc-wrap">
            {points?.length ? (
              <div
                class="description-enter__desc"
                aria-hidden="true"
              >
                {points.map(item => {
                  return (
                    <span
                      key={item.id}
                      class={[
                        { 'description-enter__desc-brown': item.is_sell_point }
                      ]}
                      vOn:click={() => clickSellingPoint(item)}
                    >
                      {symbol}&nbsp;{item.name}&nbsp;
                    </span>
                  )
                })}
              </div>
            ) : null}

            { trendsInfo.show ? <TrendsLabel trendsInfo={trendsInfo} /> : null}

            <i
              aria-hidden="true"
              class={[
                'suiiconfont',
                'sui_icon_more_right2_16px'
              ]}
            ></i>
          </div>
        </div>
      </div>
    )
  }
}
</script>

<style lang="less">
.description-enter {
  .flexbox();
  justify-content: space-between;
  position: relative;
  height: 1.12rem;
  line-height: 1.12rem;
  text-transform: capitalize;
  .font-dpr(28px);
  width: 100%;
  text-align: left /*rtl:ignore*/;
  &__title {
    .font-dpr(28px);
    .padding-r(0.64rem);
    white-space: nowrap;
    /* rw:begin */
    font-family: 'Adieu';
  }
  &__desc-wrap {
    overflow: hidden;
    padding-right: 0.54rem;
    display: flex;
    align-items: center;
    .trends-label-wrap {
      margin-left: 4px;
      &__trends-desc {
        max-width: 78px;
      }
    }
  }
  &__desc {
    .text-overflow();
    color: #999;
    font-size: 0.2933rem;
  }
  &__desc-brown {
    color: #a86104;
  }
  .sui_icon_more_right_16px,
  .sui_icon_more_right2_16px {
    position: absolute;
    top: 0;
    color: #959595;
    .right(0);
  }
}
</style>
