<template>
  <div
    class="goods-attr__evolu-tip"
    :style="boxStyle"
    @click="onClick"
  >
    <p class="title">
      <img
        class="goods-attr__evolu-icon"
        src="https://img.ltwebstatic.com/images3_ccc/2024/06/05/5c/evolu_pwa.png"
      />
      <span>{{ content.title }}</span>
    </p>
    <div class="split-line"></div>
    <p class="description">
      {{ content.description }}
    </p>
    <div class="more-icon">
      <i class="suiiconfont sui_icon_more_right_12px"></i>
    </div>
  </div>
</template>

<script name="EvoluSheinBar" setup>
const emits = defineEmits(['bar-click'])
defineProps({
  content: {
    type: Object,
    default: () => ({})
  },
  boxStyle: {
    type: Object,
    default: () => {},
  }
})

const onClick = (e) => {
  emits('bar-click', e)
}
</script>

<style lang="less" scoped>
  .goods-attr__evolu-tip {
    display: flex;
    height: .746667rem;
    padding: 0 .213333rem;
    margin: .213333rem 0 .32rem;
    align-items: center;
    overflow: hidden;
    background: linear-gradient(90deg, #FFF5E9 10.83%, #FFF 102.99%);
    flex-wrap: nowrap;
    font-size: .32rem;
    .title {
      color: #A86104;
      font-weight: 600;
      display: flex;
      align-items: center;

      svg, span {
        display: block;
      }

      span {
        margin-left: .053333rem;
      }
    }
    .goods-attr__evolu-icon{
      width: 0.37333rem;
      height: 0.37333rem;
      margin-right: 0.053333rem;
    }
    .split-line {
      height: 18px;
      width: 0;
      margin: 0 .213333rem;
      .border-dpr(border-left, 1px, rgba(0, 0, 0, 0.05))
    }
    .description {
      color: #888;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .more-icon {
      margin-left: .106667rem;
      color: #959595;
    }
  }
</style>

