<template>
  <div @click.stop>
    <ul
      v-show="showMore"
      class="review-item__member-size review-item_member-sizeNew"
      aria-hidden="true"
    >
      <li v-if="overFitValue">
        {{ language.SHEIN_KEY_PWA_14999 }} :
        <span>{{ overFitMap[overFitValue] }}</span>
      </li>
      <template v-if="memberSizeNew.length">
        <li 
          v-for="(item, key) in memberSizeNew" 
          :key="key"
        >
          {{ item.language_name }} :
          <span>{{ item.size_value }}</span>
        </li>
      </template>
      <template v-else>
        <template v-for="(sizeValue, sizeKey) in comment.member_size">
          <li
            v-if="
              (sizeKey != 'member_overall_fit' &&
                sizeKey != 'member_brasize' &&
                sizeKey != 'member_bra_size' &&
                sizeValue) ||
                ((sizeKey == 'member_brasize' || sizeKey == 'member_bra_size') &&
                  showBraSize &&
                  sizeValue)
            "
            :key="sizeKey"
          >
            <template v-if="sizeKey == 'member_brasize' || sizeKey == 'member_bra_size'">
              {{ language.SHEIN_KEY_PWA_15017 }} :
              <span>{{ sizeValue }}</span>
            </template>
            <template v-if="sizeKey == 'member_bust'">
              {{ language.SHEIN_KEY_PWA_15000 }} :
              <span>{{ sizeValue }}</span>
            </template>
            <template v-if="sizeKey == 'member_height'">
              {{ language.SHEIN_KEY_PWA_14995 }} :
              <span>{{ sizeValue }}</span>
            </template>
            <template v-if="sizeKey == 'member_hips'">
              {{ language.SHEIN_KEY_PWA_15013 }} :
              <span>{{ sizeValue }}</span>
            </template>
            <template v-if="sizeKey == 'member_waist'">
              {{ language.SHEIN_KEY_PWA_15011 }} :
              <span>{{ sizeValue }}</span>
            </template>
            <template v-if="sizeKey == 'member_weight'">
              {{ language.SHEIN_KEY_PWA_14994 }} :
              <span>{{ sizeValue }}</span>
            </template>
          </li>
        </template>
      </template>
    </ul>
    <!-- 评论详情身份信息折叠 -->
    <div
      v-if="position === 'inner' && !showMore"
      class="review-item__member-size review-item__inner-size-fold"
    >
      <li v-if="overFitValue">
        {{ language.SHEIN_KEY_PWA_14999 }} :
        <span>{{ overFitMap[overFitValue] }}</span>
      </li>
      <template v-if="miniSizeInfo.length">
        <li 
          v-for="(item, key) in miniSizeInfo" 
          :key="key"
        >
          {{ item.language_name }} :
          <span>{{ item.size_value }}</span>
          <i
            v-show="showMoreBtn('miniSizeInfo', key)"
            v-expose="showMoreData"
            class="iconfont icon-down_new"
            @click="checkShowMore"
          ></i>
        </li>
      </template>
      <template v-else>
        <li
          v-for="(item, sizeIndex) in miniMemberSize"
          :key="sizeIndex"
        >
          <template v-if="item.sizeKey == 'member_brasize' || item.sizeKey == 'member_bra_size'">
            {{ language.SHEIN_KEY_PWA_15017 }} :
            <span>{{ item.sizeValue }}</span>
          </template>
          <template v-if="item.sizeKey == 'member_bust'">
            {{ language.SHEIN_KEY_PWA_15000 }} :
            <span>{{ item.sizeValue }}</span>
          </template>
          <template v-if="item.sizeKey == 'member_height'">
            {{ language.SHEIN_KEY_PWA_14995 }} :
            <span>{{ item.sizeValue }}</span>
          </template>
          <template v-if="item.sizeKey == 'member_hips'">
            {{ language.SHEIN_KEY_PWA_15013 }} :
            <span>{{ item.sizeValue }}</span>
          </template>
          <template v-if="item.sizeKey == 'member_waist'">
            {{ language.SHEIN_KEY_PWA_15011 }} :
            <span>{{ item.sizeValue }}</span>
          </template>
          <template v-if="item.sizeKey == 'member_weight'">
            {{ language.SHEIN_KEY_PWA_14994 }} :
            <span>{{ item.sizeValue }}</span>
          </template>
          <i
            v-show="showMoreBtn('miniMemberSize', sizeIndex)"
            v-expose="showMoreData"
            class="iconfont icon-down_new"
            @click="checkShowMore"
          ></i>
        </li>
      </template>
    </div>
    <!-- 商详评论楼层身份信息折叠（考虑屏效，有缩进） -->
    <div
      v-if="position === 'outer' && !showMore"
      class="review-item__member-mini"
      aria-hidden="true"
      @click="checkShowMore"
    >
      <div class="review-item__member-text">
        <span v-if="overFitValue">
          {{ language.SHEIN_KEY_PWA_14999 }}:
          <span>{{ overFitMap[overFitValue] }}</span>
        </span>
        <template v-if="miniSizeInfo.length">
          <span 
            v-for="(item, key) in miniSizeInfo" 
            :key="key"
          >
            {{ item.language_name }} :
            <span>{{ item.size_value }}</span>
          </span>
        </template>
        <template v-else>
          <span
            v-for="(item, sizeIndex) in miniMemberSize"
            :key="sizeIndex"
          >
            <template v-if="item.sizeKey == 'member_brasize' || item.sizeKey == 'member_bra_size'">
              {{ language.SHEIN_KEY_PWA_15017 }} :
              <span>{{ item.sizeValue }}</span>
            </template>
            <template v-if="item.sizeKey == 'member_bust'">
              {{ language.SHEIN_KEY_PWA_15000 }} :
              <span>{{ item.sizeValue }}</span>
            </template>
            <template v-if="item.sizeKey == 'member_height'">
              {{ language.SHEIN_KEY_PWA_14995 }} :
              <span>{{ item.sizeValue }}</span>
            </template>
            <template v-if="item.sizeKey == 'member_hips'">
              {{ language.SHEIN_KEY_PWA_15013 }} :
              <span>{{ item.sizeValue }}</span>
            </template>
            <template v-if="item.sizeKey == 'member_waist'">
              {{ language.SHEIN_KEY_PWA_15011 }} :
              <span>{{ item.sizeValue }}</span>
            </template>
            <template v-if="item.sizeKey == 'member_weight'">
              {{ language.SHEIN_KEY_PWA_14994 }} :
              <span>{{ item.sizeValue }}</span>
            </template>
          </span>
        </template>
      </div>
      <i
        v-show="miniSizeInfo.length > 1 || miniMemberSize.length > 1"
        class="iconfont icon-down_new"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentSizeNew',
  inject: ['COMMENT_INSTANCE'],
  computed: {
    comment() {
      return this.COMMENT_INSTANCE.comment
    },
    memberSizeNew() {
      return this.comment?.member_size_new || []
    },
    position() {
      return this.COMMENT_INSTANCE.position
    },
    showMore() {
      return this.COMMENT_INSTANCE.showMore
    },
    language() {
      return this.COMMENT_INSTANCE.language
    },
    hasFit() {
      return this.COMMENT_INSTANCE.hasFit
    },
    showBraSize() {
      return this.COMMENT_INSTANCE.showBraSize
    },
    showMoreBtn() {
      return (type, sizeIndex) => {
        let sizeLength = type === 'miniSizeInfo' ? this.memberSizeNew.length : Object.keys(this.comment.member_size).length
        // overFitValue不存在时，sizeLength>2，并且在第二个尺码后显示展开按钮
        return this[type].length && (!this.overFitValue && sizeIndex === 1 && sizeLength > 2 || this.overFitValue && sizeLength > 1)
      }
    },
    showMoreData() {
      return {
        id: '1-6-2-91', 
        data: {
          type: this.comment.comment_id
        },
        code: 'reset-comment-events'
      }
    },
    overFitValue() {
      // bff把这块拼在memberSizeNew里了
      if (this.memberSizeNew?.length) return false
      return this.hasFit && +this.comment?.member_size?.member_overall_fit
    },
    overFitMap() {
      return {
        1: this.language.SHEIN_KEY_PWA_15001,
        2: this.language.SHEIN_KEY_PWA_15003,
        3: this.language.SHEIN_KEY_PWA_15002
      }
    },
    miniSizeInfo() {
      return this.memberSizeNew.slice(0, 2)
    },
    miniMemberSize() {
      let miniMemberSize = []
      let arrLength = this.overFitValue ? 1 : 2
      Object.keys(this.comment.member_size).forEach(sizeKey => {
        if (
          (sizeKey != 'member_overall_fit' &&
            sizeKey != 'member_brasize' &&
            sizeKey != 'member_bra_size' &&
            this.comment.member_size[sizeKey]) ||
          ((sizeKey == 'member_brasize' || sizeKey == 'member_bra_size') &&
            this.showBraSize &&
            this.comment.member_size[sizeKey])
        ) {
          if (miniMemberSize.length < arrLength) {
            miniMemberSize.push({
              sizeKey: sizeKey,
              sizeValue: this.comment.member_size[sizeKey]
            })
          }
        }
      })
      
      return miniMemberSize
    }
  },
  methods: {
    checkShowMore() {
      if (this.miniSizeInfo.length || this.miniMemberSize.length) {
        const { COMMENT_INSTANCE } = this
        COMMENT_INSTANCE.UPDATE_STATE('showMore', true)
        COMMENT_INSTANCE.EVENT_CENTER('sizeShowAll', { review_id: this.comment.comment_id })
      }
    },
  }
}
</script>
