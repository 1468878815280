var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show_SHEINer)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({ id: '1-6-2-65',data: { Imgnum: _vm.all_customer_gallery_sum}}),expression:"{ id: '1-6-2-65',data: { Imgnum: all_customer_gallery_sum}}"}],ref:"SHEINerZone",staticClass:"sheiner-zone",on:{"click":function($event){$event.stopPropagation();},"touchend":function($event){return _vm.touchChange()}}},[(!_vm.hiddenTitle)?_c('div',{staticClass:"sheiner-zone__title",attrs:{"tabindex":"1"},on:{"click":function($event){return _vm.openSHEINer(-1)}}},[_c('div',{ref:"SHEINerTitle",staticClass:"sheiner-zone__sub-title"},[(!_vm.isEllipsis)?[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_31776)+"\n      ")]:[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_31777)+"\n      ")]],2),_vm._v(" "),_c('div',[_vm._v("("+_vm._s(_vm.all_customer_gallery_sum)+")")])]):_vm._e(),_vm._v(" "),_c('div',{class:{
      'products-box-scroll': true,
      'products-box-scrollSingleLine': true
    }},[_c('div',{ref:"SHEINerScrollBox",staticClass:"scroll-box"},[_vm._l((_vm.customer_show_list.slice(0, 10)),function(item,index){return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:(_vm.getExpoedData(item, index)),expression:"getExpoedData(item, index)"}],key:item.comment_id,staticClass:"sheiner-zone__item_wrapper",class:{
          'sheiner-zone__item_wrapper_mid': [
            'MiddlePic',
            'MiddlePic_MoveBack',
            'MiddlePic_WithReview'
          ].includes(_vm.review_abt.galleyLayout),
          'sheiner-zone__item_wrapper_small': [
            'SmallPic',
            'SmallPic_MoveBack',
            'SmallPic_WithReview'
          ].includes(_vm.review_abt.galleyLayout)
        }},[_c('img',{staticClass:"lazy j-push-history-hash lazyload",attrs:{"data-src":_vm.transformImg({ img: item.firstImg }),"src":_vm.LAZY_IMG,"data-design-width":"200","data-image-url":item.firstImg,"data-index":index + 1},on:{"click":function($event){return _vm.showReviewImg(index, item)}}})])}),_vm._v(" "),(_vm.all_customer_gallery_sum > 10 || _vm.showViewAll)?_c('div',{staticClass:"sheiner-zone__mask",class:{
          'sheiner-zone__item_wrapper_mid': [
            'MiddlePic',
            'MiddlePic_MoveBack',
            'MiddlePic_WithReview'
          ].includes(_vm.review_abt.galleyLayout),
          'sheiner-zone__item_wrapper_small': [
            'SmallPic',
            'SmallPic_MoveBack',
            'SmallPic_WithReview'
          ].includes(_vm.review_abt.galleyLayout)
        },on:{"click":function($event){return _vm.openSHEINer(-1, 3)}}},[_c('i',{staticClass:"suiiconfont sui_icon_viewmore_right_40px"}),_vm._v(" "),_c('p',{staticClass:"sheiner-zone__view-more"},[_vm._v("\n          "+_vm._s(_vm.language.SHEIN_KEY_PWA_22253)+"\n        ")])]):_vm._e()],2)]),_vm._v(" "),(_vm.gallery_pop_showed)?_c('GalleryPopup',{attrs:{"language":_vm.language,"curId":_vm.curId},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }