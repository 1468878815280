<template>
  <div
    class="evolu-inner-box"
    @click="jumpToEvoluSHEIN"
  >
    <div>
      <p class="title">
        <img
          class="evolu-inner-icon"
          src="https://img.ltwebstatic.com/images3_ccc/2024/06/05/5c/evolu_pwa.png"
        />
        <span>{{ language.SHEIN_KEY_PWA_23114 }} </span>
      </p>
      <p class="text">
        {{ language.SHEIN_KEY_PWA_23115 }}
      </p>
    </div>
    <div class="icon-box">
      <i class="suiiconfont sui_icon_more_right_16px"></i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { markPoint } from 'public/src/services/mark/index.js'
const { langPath, IS_RW } = gbCommonInfo

export default {
  computed: {
    ...mapGetters('newProductDetail/common', ['language'])
  },
  methods: {
    jumpToEvoluSHEIN() {
      if (IS_RW) {
        markPoint('toNextPageClick', 'public')
        location.href = `${langPath}/campaign/evolushein`
      } else {
        markPoint('toNextPageClick', 'public')
        location.href = `${langPath}/campaigns/evolushein`
      }
    }
  },
}
</script>

<style lang="less" scoped>
  .evolu-inner-box {
    padding: .32rem .426667rem;
    display: flex;
    align-items: center;
    margin-bottom: .32rem;
    border-radius: .026667rem;
    border: .026667rem solid #FFF5E9;
    background: linear-gradient(180deg, #FFF5E9 0%, rgba(255, 245, 233, 0.00) 44%,  #fff 100%,);
    .evolu-inner-icon{
      width: 0.37333rem;
      height: 0.37333rem;
      margin-right: 0.053333rem;
    }
    .icon-box {
      margin-left: .213333rem;
    }

    .title {
      font-size: 14px;
      font-weight: 600;
      color: #A86104;
      display: flex;
      align-items: center;
    }
    span {
      margin-left: .053333rem;
    }

    .text {
      margin-top: .106667rem;
      color: #333;
    }
  }
</style>
